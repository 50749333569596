import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { AuthContext } from '../components/AuthContext/AuthContext'; // <- AuthContext'i import et

const ProtectedRoute = ({ children }) => {
  const { isAuthenticated: isAuthenticatedFromContext } = useContext(AuthContext); // <- AuthContext'i kullan
  const [isAuthenticated, setIsAuthenticated] = useState(isAuthenticatedFromContext); // <- Başlangıç değerini AuthContext'ten al
  const [isAuthChecked, setIsAuthChecked] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((currentUser) => {
        setIsAuthenticated(true);
        setIsAuthChecked(true);
      })
      .catch(() => {
        setIsAuthenticated(false);
        setIsAuthChecked(true);
        navigate('/login');
      });
  }, [navigate]);

  // AuthProvider'dan gelen isAuthenticated durumu değiştiğinde, bu durumu kendi durumuyla senkronize et
  useEffect(() => {
    setIsAuthenticated(isAuthenticatedFromContext);
  }, [isAuthenticatedFromContext]);

  if (!isAuthChecked) {
    return null;
  }

  return isAuthenticated ? children : null;
};

export default ProtectedRoute;