import React from "react";
import ReactApexChart from "react-apexcharts";
import withLast24HourData from "../../Container/withLast24HourData";
import withLoadingData from "../../Container/withLoadingData";
import isEqual from "react-fast-compare";
import Loading from "../Global/Loading";
import { useSelector } from "react-redux";

const CurrencyChart = ({ data, isProduct24hDataLoading, product_id, chart_class }) => {
  const productsData = useSelector((state) => state.kprice.products.data);

  // Veri var mı kontrol et
  if (!data || !productsData || Object.keys(productsData).length == 0) return null;
  
  // Eğer veri varsa veriyi ApexChart formatına dönüştür
  const series = [
    {
      name: "Price",
      data: data.map(item => ({
        x: new Date(item.timestamp).toString(), // x ekseni için tarih
        y: item.close // y ekseni için close değeri
      }))
    }
  ];

  const options = {
    chart: {
      height: 125,
      type: "area",
      zoom: {
        enabled: false // Yakınlaştırmayı kapatır
      },
      toolbar: {
        show: false, // Tüm toolbar'ı gizler
        tools: {
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false
        }
      }
    },
    colors: [chart_class == "success" ? "#03a66e" : "#cf303f"],
    dataLabels: {
      enabled: false
    },
    grid: {
      yaxis: {
        lines: {
          show: false
        }
      }
    },
    stroke: {
      curve: "smooth"
    },
    xaxis: {
      type: "datetime",
      categories: data.map(item => new Date(item.timestamp).toString()), 
      show: false, // X ekseni göstergelerini kapatır
      labels: {
        show: false
      }
    },
    yaxis: {
      show: false // Y ekseni göstergelerini kapatır
    },
    tooltip: {
      x: {
        show: false // Tarih bilgisini ayrı olarak göstermemesi için bu kısmı false yapıyoruz
      },
      y: {
        formatter: (value, { series, seriesIndex, dataPointIndex, w }) => {
          const closeValue = w.config.series[seriesIndex].data[dataPointIndex].y; // item.close değerini doğrudan kullan
          const date = new Date(w.globals.seriesX[seriesIndex][dataPointIndex]);
          const formattedTime = date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }); // Sadece saat bilgisi
          return `<span style="font-weight:500;">Price:</span> ${productsData && product_id && productsData[product_id] && productsData[product_id].show_places ? closeValue.toFixed(productsData[product_id].show_places) : closeValue}<br/><span style="font-weight:500;">Time:</span> ${formattedTime}`;
        },
        title: {
          formatter: () => '' // Price başlığı altında tekrar Price yazısını kaldırmak için boş bırakıyoruz
        }
      }
    }
  };

  return (
    <div>
      {isProduct24hDataLoading != false ? (//undefined ya da true geldiyse loading gösteriliyor, false ise grafik
        <div className="currencyChartLoading"><Loading /></div>
      ) : (
      <div id="chart">
        <ReactApexChart options={options} series={series} type="area" height={125} className="ReactApexChart-main" />
      </div>)}
    </div>
  );
};

export default withLast24HourData(withLoadingData()(React.memo(CurrencyChart, isEqual)));
