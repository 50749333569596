import React from "react";
import ReactLoading from "react-loading";
import styled, { css } from "styled-components";

const St = {
  Container: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    ${({ iscenter }) =>
      !iscenter &&
      css`
        align-items: stretch;
        margin-top: 200px;
      `}
  `,
};

const Loading = ({ center = true }) => {
  return (
    <St.Container iscenter={center.toString()}>
      <ReactLoading
        type={"bubbles"}
        color={"#666666bf"}
        height={"100px"}
        width={"100px"}
      />
    </St.Container>
  );
};

export default Loading;
