import store from '../../store.js';
import {
	generateSymbol,
} from './helpers.js';
import { kpriceApi } from '../../Api/api.js';
import {
	subscribeOnStream,
	unsubscribeFromStream,
} from './streaming.js';

const lastBarsCache = new Map();


// DatafeedConfiguration implementation
const configurationData = {
	// Represents the resolutions for bars supported by your datafeed
	supported_resolutions: ['1', '5', '15', '30', '60', '120', '240', '360', '720', '1D', '1W', '1M'],
	
	// The `exchanges` arguments are used for the `searchSymbols` method if a user selects the exchange
	exchanges: [{
		value: 'KPRICE',
		name: 'KPRICE',
		desc: 'KPRICE',
	},
	],
	// The `symbols_types` arguments are used for the `searchSymbols` method if a user selects this symbol type
	symbols_types: [{
		name: 'Spot',
		value: 'Spot',
	},
    {
		name: 'Future',
		value: 'Future',
	},
	],
};

function getProductsData() {
	const state = store.getState();
	const productsData = state.kprice.products.data;
	return productsData;
  }

  function processProductsData() {

	const productsData = getProductsData();
	const spotList = [];
	const futureList = [];
  
	Object.entries(productsData).forEach(([productName, productInfo]) => {
	  const productDetails = {
		name: productName,
		show_places: productInfo.show_places
	  };
	  
	  if (productInfo.status_spot) {
		if(productInfo.status_spot.toLowerCase() === "active") {
			spotList.push(productDetails);
		}
	  }
	  else{
		spotList.push(productDetails);
	  }

	  if (productInfo.status_future) {
		if(productInfo.status_future.toLowerCase() === "active") {
			futureList.push(productDetails);
		}
	  }
	  else{
		futureList.push(productDetails);
	  }
	});
  
	return { spotList, futureList };
  }


async function getAllSymbols() {
	const { spotList, futureList } = processProductsData();
	let allSymbols = [];
	for (const exchange of configurationData.exchanges) {
	  // Spot listesini işle
	  spotList.forEach(item => {
		const [leftPairPart, rightPairPart] = item.name.split('-');
		const symbol = generateSymbol(exchange.value, leftPairPart, rightPairPart);
		allSymbols.push({
		  symbol: symbol.short,
		  full_name: symbol.full,
		  description: symbol.short,
		  exchange: exchange.value,
		  product_id: item.name,
		  show_places: item.show_places,
		  type: 'Spot'
		});
	  });
  
	  // Future listesini işle
	  futureList.forEach(item => {
		const [leftPairPart, rightPairPart] = item.name.split('-');
		const symbol = generateSymbol(exchange.value, leftPairPart, rightPairPart);
		allSymbols.push({
		  symbol: symbol.short,
		  full_name: symbol.full,
		  description: symbol.short,
		  exchange: exchange.value,
		  product_id: item.name,
		  show_places: item.show_places,
		  type: 'Future'
		});
	  });
	}
	return allSymbols;
  }


export default {
	onReady: async (callback) => {
		try {
			const result = await kpriceApi.getProductIds({});
		} catch (error) {}
		setTimeout(() => callback(configurationData));
	},

	searchSymbols: async (
		userInput,
		exchange,
		symbolType,
		onResultReadyCallback,
	  ) => {
	  
		const symbols = await getAllSymbols();
		const newSymbols = symbols.filter(symbol => {
		  const isExchangeValid = exchange === '' || symbol.exchange === exchange;
		  const isSymbolTypeValid = symbolType === '' || symbol.type === symbolType;
		  const isFullSymbolContainsInput = symbol.full_name
			.toLowerCase()
			.includes(userInput.toLowerCase());
	  
		  return isExchangeValid && isSymbolTypeValid && isFullSymbolContainsInput;
		});
	  
		onResultReadyCallback(newSymbols);
	},

	resolveSymbol: async (
		symbolName,
		onSymbolResolvedCallback,
		onResolveErrorCallback,
		extension
	) => {
		const symbols = await getAllSymbols();
		const symbolItem = symbols.find(({
			full_name,
		}) => full_name === symbolName);
		if (!symbolItem) {
			onResolveErrorCallback('cannot resolve symbol');
			return;
		}
		// Symbol information object
		const symbolInfo = {
			ticker: symbolItem.full_name,
			name: symbolItem.symbol,
			description: symbolItem.description,
			type: symbolItem.type,
			product_id: symbolItem.product_id,
			show_places: symbolItem.show_places,
			session: '24x7',
			timezone: 'Etc/UTC',
			exchange: symbolItem.exchange,
			minmov: 1,
			pricescale: Math.pow(10, symbolItem.show_places),
			has_intraday: true,
			has_daily: true,
			has_no_volume: false,
			has_weekly_and_monthly: true,
			supported_resolutions: configurationData.supported_resolutions,
			volume_precision: 2,
			data_status: 'streaming',
		};

		onSymbolResolvedCallback(symbolInfo);
	},


	getBars: async (symbolInfo, resolution, periodParams, onHistoryCallback, onErrorCallback) => {
		const { from, to, firstDataRequest } = periodParams;
		const product_id = symbolInfo.product_id;
		const toTs = to * 1000;
		const fromTs = from * 1000;
		try {
			const result = await kpriceApi.getTVCandles({ product_id, resolution, fromTs, toTs, firstDataRequest });
			const data = result.data;
			if (result.status && result.status !== 200 || data.length === 0) {
				// "noData" should be set if there is no data in the requested period
				onHistoryCallback([], {
					noData: true,
				});
				return;
			}
			let bars = [];
			data.forEach(bar => {
				if (bar.timestamp >= fromTs && bar.timestamp < toTs) {
					bars = [...bars, {
						time: bar.timestamp,
						low: bar.low,
						high: bar.high,
						open: bar.open,
						close: bar.close,
						volume: bar.base_volume,
						lastMT: bar.timestamp,
						firstMT: bar.timestamp
					}];
				}
			});
			if (firstDataRequest) {
				lastBarsCache.set(symbolInfo.full_name, {
					...bars[bars.length - 1],
				});
			}
			onHistoryCallback(bars, {
				noData: false,
			});
		} catch (error) {
			onErrorCallback(error);
		}
	},

	subscribeBars: (
		symbolInfo,
		resolution,
		onRealtimeCallback,
		subscriberUID,
		onResetCacheNeededCallback,
	) => {
		
		subscribeOnStream(
			symbolInfo,
			resolution,
			onRealtimeCallback,
			subscriberUID,
			onResetCacheNeededCallback,
			lastBarsCache.get(symbolInfo.full_name),
		);
		
	},

	unsubscribeBars: (subscriberUID) => {
		
		unsubscribeFromStream(subscriberUID);
		
	},
};
