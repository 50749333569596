import React, { useState, useEffect, useRef } from "react";
import { useLocation } from 'react-router-dom';
import VerificationSteps from "./VerificationSteps";
import VerificationKYC from "./VerificationKYC";
import Loading from "../components/Global/Loading";

const Verification = () => {

    const location = useLocation();

    const [showSteps, setShowSteps] = useState(null);
    const section = useRef(null);

    useEffect(() => {//link değişirse
        if(location && location.pathname){
            if(location.pathname.includes("steps")){
                setShowSteps(true);
            }
            else{
                setShowSteps(false);
            }
        }
        else{
            setShowSteps(false);
        }
    }, [location]);

    useEffect(() => {
        if(section && section.current){
            section.current.style.animation = "none";
            let tempForAnimation = section.current.offsetHeight;
            section.current.style.animation = "open-animation 1.5s";
        }
    }, [showSteps]);

    return(
        <div id="main" className="main">
            <div className={`allsections allsections-border scrollbarhide section-verification ${showSteps == false && " kyc"}`} ref={section}>
                {showSteps != null ? (
                    showSteps ? <VerificationSteps pageType={null} /> : <div className="section-profile"><VerificationKYC /></div>
                ) : <Loading />}
            </div>
        </div>
    )

}

export default Verification;