import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { calcNotificationDate } from '../Lib/globalFunctions';
import moment from "moment-timezone";
import { toast } from 'react-toastify';
import axios from 'axios';
import { Auth } from 'aws-amplify';
import withLoadingData from "../Container/withLoadingData";
import Loading from "../components/Global/Loading";
import isEqual from "react-fast-compare";
import { onGetNotificationsMore, onUpdateNotificationsByApi } from "../Reducer/kpriceReducer";
import "./section_notifications.css";

const SectionNotifications = ({isNotificationsLoading, location}) => {

    const dispatch = useDispatch();

    const notificationsData = useSelector((state) => state.kprice.notifications.data);
    const [activeNotificationsLenght, setActiveNotificationsLenght] = useState(0);
    const [updateLock, setUpdateLock] = useState(false);
    const [getNewNotifications, setGetNewNotifications] = useState(false);
    const itemsScrollPosition = useRef(0);
    const notificationsElements = useRef(null);

    useEffect(() => {
        setGetNewNotifications(false);

        let activeNotifications = 0;
        if(notificationsData && notificationsData.notifications){
            notificationsData.notifications.forEach(notification => {
                if(notification.notification_deleted == "0" && notification.state != "1"){
                    activeNotifications += 1;
                }
            });
        }
        setActiveNotificationsLenght(activeNotifications);

        if(notificationsData.updated_log_id && notificationsData.updated_log_id_info){
            notificationAnimation(notificationsData.updated_log_id, notificationsData.updated_log_id_info);
        }
    }, [notificationsData]);

    useEffect(() => {
        if(notificationsElements && notificationsElements.current){
            if(getNewNotifications){
                notificationsElements.current.scrollTop = notificationsElements.current.scrollHeight - notificationsElements.current.clientHeight;
            }
            else{
                notificationsElements.current.scrollTop = itemsScrollPosition.current;
            }
        }
    }, [getNewNotifications]);

    const Notifications = ({ notification }) => {
        if(notification.notification_type == "Verification"){
            return <>
                <NotificationsDate notification={notification} />
                <Template_Verification notification={notification} />
            </>;
        }
        else if(notification.notification_type == "Welcome"){
            return <>
                <NotificationsDate notification={notification} />
                <Template_Welcome notification={notification} />
            </>;
        }
        else if(notification.notification_type == "Deposit"){
            return <>
                <NotificationsDate notification={notification} />
                <Template_Deposit notification={notification} />
            </>;
        }
        else if(notification.notification_type == "Withdraw"){
            return <>
                <NotificationsDate notification={notification} />
                <Template_Withdraw notification={notification} />
            </>;
        }
        else if(notification.notification_type == "Transfer"){
            return <>
                <NotificationsDate notification={notification} />
                <Template_Transfer notification={notification} />
            </>;
        }
        else if(notification.notification_type == "Futures Margin Call"){
            return <>
                <NotificationsDate notification={notification} />
                <Template_Futures_Margin_Call notification={notification} />
            </>;
        }
    }

    const NotificationsDate = ({ notification }) => {
        return <>
            <div className="section-notifications-date" title={notification.event_date}>
                {calcNotificationDate(notification.event_date, moment)}
            </div>
        </>;
    }

    const NotificationsTitle = ({ notification, title }) => {
        return <>
            <div className={`title-area${location && location == "header" ? " no-close" : ""}`}>
                <div className={`title-left${location && location == "header" ? " no-close" : ""}`}>
                    {notification.state == "0" && <div className={`notice${location && location == "header" ? " no-close" : ""}`}></div>}
                    <span className={location && location == "header" ? "no-close" : undefined}>{title}</span>
                </div>
                <div className={`title-right${location && location == "header" ? " no-close" : ""}`}>
                    {notification.state == "0" && <i className={location && location == "header" ? "fa-solid fa-eye no-close" : "fa-solid fa-eye"} title="Mark as read" onClick={() => notificationUpdate("readed", "single", notification.log_id)}></i>}
                    <i className={location && location == "header" ? "fa-solid fa-circle-xmark no-close" : "fa-solid fa-circle-xmark"} title="Remove" onClick={() => notificationUpdate("deleted", "single", notification.log_id)}></i>
                </div>
            </div>
        </>;
    }

    const Template_Verification = ({ notification }) => {
        return (
            <>
                <div className={`section-notifications-element${notification.state == "0" ? " clickable" : ""}${location && location == "header" ? " no-close" : ""}`} data-id={notification.log_id} onClick={() => {if(notification.state == "0"){notificationUpdate("readed", "single", notification.log_id);}}}>
                    <NotificationsTitle notification={notification} title="Verification" className={location && location == "header" ? "no-close" : undefined} />
                    <label className={location && location == "header" ? "no-close" : undefined}>
                        {`You've leveled up, your new level is ${notification && notification.notification_extras && notification.notification_extras.new_status_level ? notification.notification_extras.new_status_level : ""}!`}
                    </label>
                </div>
            </>
        );
    }

    const Template_Welcome = ({ notification }) => {
        return (
            <>
                <div className={`section-notifications-element${notification.state == "0" ? " clickable" : ""}${location && location == "header" ? " no-close" : ""}`} data-id={notification.log_id} onClick={() => {if(notification.state == "0"){notificationUpdate("readed", "single", notification.log_id);}}}>
                    <NotificationsTitle notification={notification} title="Welcome" className={location && location == "header" ? "no-close" : undefined} />
                    <label className={location && location == "header" ? "no-close" : undefined}>
                        Welcome to KPRICE! You registered with the email address {notification && notification.notification_extras && notification.notification_extras.masked_email ? notification.notification_extras.masked_email : ""}.
                    </label>
                </div>
            </>
        );
    }

    const Template_Deposit = ({ notification }) => {
        return (
            <>
                <div className={`section-notifications-element${notification.state == "0" ? " clickable" : ""}${location && location == "header" ? " no-close" : ""}`} data-id={notification.log_id} onClick={() => {if(notification.state == "0"){notificationUpdate("readed", "single", notification.log_id);}}}>
                    <NotificationsTitle notification={notification} title="Deposit" className={location && location == "header" ? "no-close" : undefined} />
                    {notification && notification.notification_extras && notification.notification_extras.status && notification.notification_extras.status == "approved" && 
                    <label className={location && location == "header" ? "no-close" : undefined}>
                        A deposit transaction of {notification && notification.notification_extras && notification.notification_extras.count ? notification.notification_extras.count : ""} {notification && notification.notification_extras && notification.notification_extras.currency ? notification.notification_extras.currency : ""} to your account was successfully completed.
                    </label>}
                    {notification && notification.notification_extras && notification.notification_extras.status && notification.notification_extras.status == "denied" && 
                    <label className={location && location == "header" ? "no-close" : undefined}>
                        A deposit transaction of {notification && notification.notification_extras && notification.notification_extras.count ? notification.notification_extras.count : ""} {notification && notification.notification_extras && notification.notification_extras.currency ? notification.notification_extras.currency : ""} to your account was failed.
                    </label>}
                </div>
            </>
        );
    }

    const Template_Withdraw = ({ notification }) => {
        return (
            <>
               <div className={`section-notifications-element${notification.state == "0" ? " clickable" : ""}${location && location == "header" ? " no-close" : ""}`} data-id={notification.log_id} onClick={() => {if(notification.state == "0"){notificationUpdate("readed", "single", notification.log_id);}}}>
                    <NotificationsTitle notification={notification} title="Withdraw" className={location && location == "header" ? "no-close" : undefined} />
                    {notification && notification.notification_extras && notification.notification_extras.status && notification.notification_extras.status == "approved" &&
                    <label className={location && location == "header" ? "no-close" : undefined}>
                        A withdraw transaction of {notification && notification.notification_extras && notification.notification_extras.net_count ? notification.notification_extras.net_count : ""} {notification && notification.notification_extras && notification.notification_extras.currency ? notification.notification_extras.currency : ""} from your account was successfully completed.
                    </label>}
                    {notification && notification.notification_extras && notification.notification_extras.status && notification.notification_extras.status == "denied" &&
                    <label className={location && location == "header" ? "no-close" : undefined}>
                        A withdraw transaction of {notification && notification.notification_extras && notification.notification_extras.count ? notification.notification_extras.count : ""} {notification && notification.notification_extras && notification.notification_extras.currency ? notification.notification_extras.currency : ""} from your account was failed.
                    </label>}
                </div>
            </>
        );
    }

    const Template_Transfer = ({ notification }) => {
        return (
            <>
                <div className={`section-notifications-element${notification.state == "0" ? " clickable" : ""}${location && location == "header" ? " no-close" : ""}`} data-id={notification.log_id} onClick={() => {if(notification.state == "0"){notificationUpdate("readed", "single", notification.log_id);}}}>
                    <NotificationsTitle notification={notification} title="Transfer" className={location && location == "header" ? "no-close" : undefined} />
                    {notification && notification.notification_extras && notification.notification_extras.status && notification.notification_extras.status == "approved" &&
                    <label className={location && location == "header" ? "no-close" : undefined}>
                        A transfer transaction of {notification && notification.notification_extras && notification.notification_extras.count ? notification.notification_extras.count : ""} {notification && notification.notification_extras && notification.notification_extras.currency ? notification.notification_extras.currency : ""} from {notification && notification.notification_extras && notification.notification_extras.transfer_type && (notification.notification_extras.transfer_type == "spot_to_future" ? "Spot to Future" : "Future to Spot")} was completed successfully.
                    </label>}
                    {notification && notification.notification_extras && notification.notification_extras.status && notification.notification_extras.status == "denied" &&
                    <label className={location && location == "header" ? "no-close" : undefined}>
                        Transfer of {notification && notification.notification_extras && notification.notification_extras.count ? notification.notification_extras.count : ""} {notification && notification.notification_extras && notification.notification_extras.currency ? notification.notification_extras.currency : ""} from {notification && notification.notification_extras && notification.notification_extras.transfer_type && (notification.notification_extras.transfer_type == "spot_to_future" ? "Spot to Future" : "Future to Spot")} failed.
                    </label>}
                </div>
            </>
        );
    }

    const Template_Futures_Margin_Call = ({ notification }) => {
        return (
            <>
                <div className={`section-notifications-element${notification.state == "0" ? " clickable" : ""}${location && location == "header" ? " no-close" : ""}`} data-id={notification.log_id} onClick={() => {if(notification.state == "0"){notificationUpdate("readed", "single", notification.log_id);}}}>
                    <NotificationsTitle notification={notification} title="Futures Margin Call" className={location && location == "header" ? "no-close" : undefined} />
                    <label className={location && location == "header" ? "no-close" : undefined}>
                        The marjin ratio of your Kprice Futures account has reached %100.
                    </label>
                </div>
            </>
        );
    }

    
    function adjustNotificationData(data) {
        let adjustedData = { ...data };
        
        if ('unread_notifications_length' in adjustedData) {
          adjustedData.unread_notifications_length = Number(adjustedData.unread_notifications_length);
        }
        
        if (adjustedData.notification && 'event_timestamp' in adjustedData.notification) {
          adjustedData.notification.event_timestamp = Number(adjustedData.notification.event_timestamp);
        }
      
        return adjustedData;
    }

    const notificationUpdate = async (updateType, type, logId) => {
        if(type != "" && !updateLock){
            setUpdateLock(true);
            
            let data = {};
            
            if(type == "single"){
                data = {
                    "type": type,
                    "update_type": updateType,
                    "log_id": logId
                };
            }
            else if(type == "multiple"){
                data = {
                    "type": type,
                    "update_type": updateType
                };
            }

            const session = await Auth.currentSession();
            const jwtToken = session.getIdToken().getJwtToken();

            axios.post('https://api.kpricemarket.com/notifications/update', 
                JSON.stringify(data),
                { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${jwtToken}` } }
            )
            .then(function (response) {

                if(response.status == "200"){
                    const newNotification = adjustNotificationData(response.data);

                    if(updateType == "deleted"){//eğer siliniyorsa animasyonun gözükmesi için state güncelleme işlemi 1 saniye geciktiriliyor
                        if(type == "single" && updateType == "deleted"){
                            notificationAnimation([logId], "delete");
                        }
                        else if(type == "multiple" && updateType == "deleted"){
                            let logIds = [];
                            if(notificationsData && notificationsData.notifications){
                                for (let i = 0; i < notificationsData.notifications.length; i++) {
                                    logIds.push(notificationsData.notifications[i].log_id);
                                }
                                if(logIds.length > 0){
                                    notificationAnimation(logIds, "delete");
                                }
                            }
                        }

                        setTimeout(() => {
                            dispatch(onUpdateNotificationsByApi(newNotification));
                        }, 1000);
                    }
                    else{
                        dispatch(onUpdateNotificationsByApi(newNotification));
                    }
                }
                else{
                    toast.error(`There was a problem updating notification!${response.data.message ? ` (${response.data.message})` : ""}`);
                }
                
                setUpdateLock(false);
            })
            .catch(function (error) {
                toast.error(`There was a problem updating notification!${error.response.data.error ? ` (${error.response.data.error})` : ""}`);
                setUpdateLock(false);
            });
        }
    }

    const notificationElementsOnScroll = async (e) => {
        const { scrollTop, scrollHeight, clientHeight } = e.target;
        if (scrollTop + clientHeight + 5 >= scrollHeight && notificationsData && notificationsData.last_evaluated_key && !getNewNotifications) {//eğer divin en altına gelindiyse
            if(notificationsElements && notificationsElements.current){
                itemsScrollPosition.current = notificationsElements.current.scrollTop;
            }
            setGetNewNotifications(true);
            dispatch(onGetNotificationsMore());
        }
    }

    const notificationAnimation = (log_id, updateType) => {
        const elements = document.getElementsByClassName("section-notifications-element");
        for (let i = 0; i < elements.length; i++) {
            if(log_id.includes(elements[i].dataset.id)){
                elements[i].style.animation = "";
                setTimeout(() => {
                    if(elements[i]){
                        if(updateType == "update"){
                            elements[i].style.animation = "open-animation 1.5s";
                        }
                        else if(updateType == "delete"){
                            elements[i].style.animation = "close-animation 1.5s";
                        }
                    }
                }, 10);
            }
        }
    }

    return(
        <div id="section-notifications" className="allsections allsections-border scrollbarhide section-notifications">
            {isNotificationsLoading != false ? 
            <Loading /> : <>
                <div className="section-notifications-title">
                    <label>Notifications {activeNotificationsLenght > 0 && `(${notificationsData && notificationsData.unread_notifications_length ? notificationsData.unread_notifications_length : 0})`}</label>
                    <div>
                        {notificationsData && notificationsData.notifications && notificationsData.notifications.length > 0 &&
                        <>
                            {notificationsData && notificationsData.unread_notifications_length && notificationsData.unread_notifications_length > 0 ? <i className={location && location == "header" ? "fa-solid fa-eye no-close" : "fa-solid fa-eye"} title="Mark all notifications as read" onClick={() => notificationUpdate("readed", "multiple", null)}></i> : ""}
                            {activeNotificationsLenght > 0 && <i className={location && location == "header" ? "fa-solid fa-trash no-close" : "fa-solid fa-trash"} title="Remove all notifications" onClick={() => notificationUpdate("deleted", "multiple", null)}></i>}
                        </>}
                    </div>
                </div>
                <div className="section-notifications-elements scrollbarhide" ref={notificationsElements} onScroll={(e) => notificationElementsOnScroll(e)}>
                    {notificationsData && notificationsData.notifications && notificationsData.notifications.length > 0 && activeNotificationsLenght > 0 ? 
                    <>
                        {notificationsData.notifications.map((notification) => (
                            notification && notification.notification_deleted == "0" && notification.state != "1" &&
                            <div className="item" key={notification.log_id}>
                                <Notifications notification={notification} />
                            </div>
                        ))}
                        {getNewNotifications && <div className="loading"><Loading /></div>}
                    </> : 
                    <div className="no-notification">
                        <i className="fa-solid fa-bell-slash"></i>
                        <span>There are no notifications</span>
                    </div>}
                </div>
            </>}
        </div>
    );
    
}

export default withLoadingData()(React.memo(SectionNotifications, isEqual));