import React from "react";
import { Link } from 'react-router-dom';
import "../global.css";
import image_404 from "../images/404/404_image.png";
import gif_404 from "../images/404/404_robot.gif";

const Page404 = () => {
    
    return(
        <div className="page404">
            <div className="image">
                <img src={image_404} className="image1" alt="Page not found" />
                <div className="link">
                    <Link to='/'>
                        <i className="fa-solid fa-chevron-left"></i>
                    </Link>
                    <Link to='/'>
                        Back to Home
                    </Link>
                </div>
            </div>
            <img src={gif_404} className="image2" alt="Page not found" />
        </div>
    )

}

export default Page404;