import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from './components/AuthContext/AuthContext';
import '@aws-amplify/ui-react/styles.css';
import awsExports from './aws-exports';
import { Amplify} from 'aws-amplify';


import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import theme from "./styles/theme";
import GlobalStyle from "./styles/GlobalStyle";
import store from './store';

const isLocal = window.location.hostname === 'localhost';

Amplify.configure(awsExports);



const authConfig = {
  ...awsExports,
};


// Configure a custom GraphQL endpoint
Amplify.configure({
  API: {
    aws_appsync_graphqlEndpoint: "https://hi4zwu3zqvamdnfh5ndc6wgue4.appsync-api.us-east-1.amazonaws.com/graphql",
    aws_appsync_region: "us-east-1",
    aws_appsync_authenticationType: "AWS_IAM",
  },
  Auth: authConfig
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <BrowserRouter>
          <AuthProvider>
            
            <App />
          </AuthProvider>
        </BrowserRouter>
      </Provider>
    </ThemeProvider>,
  // </React.StrictMode>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
