import React, { useEffect, useState, useMemo } from "react";
import { useSelector } from "react-redux";
import { Link } from 'react-router-dom';
import { editNumber_reducing_trailing_zeros, editNumber_reducing_decimal_places, editNumber_increasing_decimal_places, editNumber_separate_with_comma, editNumber_control_decimal_places_length } from '../Lib/calculationFunctions';
import withLoadingData from "../Container/withLoadingData";
import isEqual from "react-fast-compare";
import Loading from "./Global/Loading";
import { MaterialReactTable } from 'material-react-table';
import "../Pages/page_dashboard.css";

const SearchHistory = ({ pageLink, close }) => {

    const currenciesData = useSelector((state) => state.kprice.currencies.data);
    const productsData = useSelector((state) => state.kprice.products.data);
    const productsMiniTickerData = useSelector((state) => state.kprice.productsMiniTicker.data);
    const [searchHistory, setSearchHistory] = useState(["", "", ""]);
    const [coinListSearch, setCoinListSearch] = useState("");

    useEffect(() => {
        const storedHistory = localStorage.getItem("searchHistory");
        if (storedHistory) {
            setSearchHistory(JSON.parse(storedHistory));
        }
    }, []);

    useEffect(() => {
        const handleStorageChange = (event) => {
            if (event.key === "searchHistory") {
                const updatedHistory = JSON.parse(event.newValue);
                setSearchHistory(updatedHistory);
            }
        };
    
        window.addEventListener("storage", handleStorageChange);
    
        return() => {
            window.removeEventListener("storage", handleStorageChange);
        };
    }, []);

    const updateSearchHistory = (newSearch) => {
        let newHistory = [...searchHistory];

        if(newSearch == "clear"){
            if(searchHistory[0] != "" || searchHistory[1] != "" || searchHistory[2] != ""){
                newHistory = ["", "", ""];
                setSearchHistory(newHistory);
                localStorage.setItem("searchHistory", JSON.stringify(newHistory));
            }
        }
        else if(newSearch){
            const index = newHistory.indexOf(newSearch);

            if(index > -1){
                newHistory.splice(index, 1);
            }
            else{
                newHistory.pop();
            }

            newHistory.unshift(newSearch);
            setSearchHistory(newHistory);
            localStorage.setItem("searchHistory", JSON.stringify(newHistory));
        }
    }

    const handleChangeText = (text) => {
        const filteredText = text.toUpperCase().replace(/[^A-Z0-9/]/g, '');
        setCoinListSearch(filteredText);
    };

    const [tableProducts, setTableProducts] = useState(null);//tablo verileri

    const columnsProducts = useMemo(() => [//tablo bilgileri
        { accessorKey: 'searchName1', header: 'Name', size: 80, Cell: ({ row }) => row.original.nameForTable },//row.original.nameForTable geçen yer newData içindeki changeForSorting değeri
        { accessorKey: 'price', header: 'Price', size: 40, Cell: ({ row }) => row.original.priceForTable },
        { accessorKey: 'change', header: 'Change', size: 40, Cell: ({ row }) => row.original.changeForTable },//row.original.changeForTable geçen yer newData içindeki changeForSorting değeri
    ], []);

    const calcPrice = (price, show_places) => {
        const response = editNumber_control_decimal_places_length(editNumber_reducing_trailing_zeros(price), show_places);
        if(response){
            return editNumber_separate_with_comma(editNumber_increasing_decimal_places(editNumber_reducing_decimal_places(editNumber_reducing_trailing_zeros(price), show_places), show_places));
        }
        else{
            return editNumber_separate_with_comma(editNumber_reducing_trailing_zeros(price));
        }
    }

    const tableProducts_name = (key) => {
        let currenciesName = key.split("-")[0];
        return <div className="section-currencystatus-element-link chart" key={key}>
            <div className="section-currencystatus-element-name">
                <Link to={`/${pageLink}?market=${key}`} className="innerlink" onClick={() => {updateSearchHistory(key);close();}}>
                    <img src={currenciesData && currenciesData[currenciesName] ? currenciesData[currenciesName].picture_url : undefined} />
                </Link>
                <Link to={`/${pageLink}?market=${key}`} className="innerlink" onClick={() => {updateSearchHistory(key);close();}}>
                    <div className="nametext">
                        <label>{currenciesData && currenciesData[currenciesName] ? currenciesData[currenciesName].currency_name : ""}</label>
                        <span><p>{key.split("-")[0]}</p>/{key.split("-")[1]}</span>
                    </div>
                </Link>
            </div>
        </div>;
    }

    const tableProducts_price = (key) => {
        let show_places = productsData && productsData[key] && productsData[key].show_places ? productsData[key].show_places : "NaN";
        let currentPrice = productsMiniTickerData && productsMiniTickerData[key] && productsMiniTickerData[key].currentPrice ? productsMiniTickerData[key].currentPrice : null;
        let previousCurrentPrice = productsMiniTickerData && productsMiniTickerData[key] && productsMiniTickerData[key].previousCurrentPrice ? productsMiniTickerData[key].previousCurrentPrice : null;
        
        if(currentPrice && previousCurrentPrice && document.getElementById(`status-element-${key}`)){
            let currentCP = document.getElementById(`status-element-${key}`).dataset.current_price;
            let currentPCP = document.getElementById(`status-element-${key}`).dataset.previous_current_price;
            if(currentCP != currentPrice || currentPCP != previousCurrentPrice){
                if(currentPrice > previousCurrentPrice){
                    document.getElementById(`status-element-${key}`).classList.remove("success", "danger");
                    setTimeout(() => {
                        if(document.getElementById(`status-element-${key}`)){
                            document.getElementById(`status-element-${key}`).classList.add("success");
                        }
                    }, 10);
                }
                else if(currentPrice < previousCurrentPrice){
                    document.getElementById(`status-element-${key}`).classList.remove("success", "danger");
                    setTimeout(() => {
                        if(document.getElementById(`status-element-${key}`)){
                            document.getElementById(`status-element-${key}`).classList.add("danger");
                        }
                    }, 10);
                }
                document.getElementById(`status-element-${key}`).dataset.current_price = currentPrice;
                document.getElementById(`status-element-${key}`).dataset.previous_current_price = previousCurrentPrice;
            }
        }
        return <Link to={`/${pageLink}?market=${key}`} key={key} className="section-currencystatus-element-link" onClick={() => {updateSearchHistory(key);close();}}>
            <div id={`status-element-${key}`} className="section-currencystatus-element-price riseAndFallValueAnimation" data-current_price="" data-previous_current_price="">
                {currentPrice ? calcPrice(currentPrice, show_places) : "NaN"}
            </div>
        </Link>;
    }

    const tableProducts_change = (key) => {
        return <Link to={`/${pageLink}?market=${key}`} key={key} className="section-currencystatus-element-link" onClick={() => {updateSearchHistory(key);close();}}>
            <div className="section-currencystatus-element-change">
                <div className={productsMiniTickerData && productsMiniTickerData[key] ? (productsMiniTickerData[key].percentage_difference_24h != null ? (productsMiniTickerData[key].percentage_difference_24h >= 0 ? "success" : "danger") : "danger") : "danger"}>{productsMiniTickerData && productsMiniTickerData[key] ? (productsMiniTickerData[key].percentage_difference_24h != null ? editNumber_separate_with_comma(editNumber_increasing_decimal_places(editNumber_reducing_decimal_places(editNumber_reducing_trailing_zeros(productsMiniTickerData[key].percentage_difference_24h), 2), 2)) : "NaN") : "NaN"}%</div>
            </div>
        </Link>;
    }

    useEffect(() => {
        const productKeys = productsMiniTickerData ? Object.keys(productsMiniTickerData) : [];

        const processData = () => {
            const newData = [];
            
            if(coinListSearch != ""){
                productKeys.forEach((key) => {
                    let productStatusSpotActive = productsData && productsData[key] && productsData[key].status_spot ? (productsData[key].status_spot == "active" ? true : false) : true;
                    let productStatusFutureActive = productsData && productsData[key] && productsData[key].status_future ? (productsData[key].status_future == "active" ? true : false) : true;
                    if((pageLink == "trade" && productStatusSpotActive) || (pageLink == "future" && productStatusFutureActive)){
                        newData.push({
                            key: key,
                            name: currenciesData && currenciesData[key.split("-")[0]] ? currenciesData[key.split("-")[0]].currency_name : "",
                            nameForTable: tableProducts_name(key),
                            price: productsMiniTickerData && productsMiniTickerData[key] && productsMiniTickerData[key].currentPrice ? productsMiniTickerData[key].currentPrice : 0,
                            priceForTable: tableProducts_price(key),
                            change: productsMiniTickerData && productsMiniTickerData[key] && productsMiniTickerData[key].percentage_difference_24h != null ? productsMiniTickerData[key].percentage_difference_24h : 0,
                            changeForTable: tableProducts_change(key),
                            searchName1: `${key.split("-")[0]}/${key.split("-")[1]}`,
                            searchName2: `${key.split("-")[0]}${key.split("-")[1]}`
                        });
                    }
                });

                const filtered = newData.filter(item => 
                    item.searchName1.toLowerCase().includes(coinListSearch.toLowerCase()) ||
                    item.searchName2.toLowerCase().includes(coinListSearch.toLowerCase())
                );
                
                return filtered;
            };

            return newData;
        }

        setTableProducts(processData());
    }, [productsMiniTickerData, currenciesData, coinListSearch]);

    return(
        <div className="section-walletlist-dialog-transfer">
            <div id="section-currencystatus" className="section-currencystatus search-history">
                {tableProducts == null ? (
                    <div className="currencyStatusLoading"><Loading /></div>
                ) : (
                    <>
                        <i className="fa-solid fa-magnifying-glass search-icon"></i>
                        <input type="text" placeholder="Search" className="search" onChange={(e) => handleChangeText(e.target.value)} />
                        {tableProducts.length > 0 ? 
                            <MaterialReactTable 
                                columns={columnsProducts} 
                                data={tableProducts} 
                                enablePagination={false}
                                enableRowVirtualization={true}
                                enableColumnActions={false}
                                initialState={{
                                    sorting: [{id: 'searchName1', desc: false}]
                                }} 
                                className="section-currencystatus-table" 
                            />
                            : 
                            (coinListSearch == "" ? 
                                <div className="no-search">
                                    <div className="title">
                                        <span>Search History</span>
                                        <i className="fa-solid fa-trash" onClick={() => updateSearchHistory("clear")}></i>
                                    </div>
                                    <div className="history">
                                        {(searchHistory[0] != "" || searchHistory[1] != "" || searchHistory[2] != "") ? 
                                            <>
                                                {searchHistory.map((item, index) => (
                                                    (item != "" && 
                                                        <Link to={`/${pageLink}?market=${item}`} key={index} className="innerlink" onClick={() => {updateSearchHistory(item);close();}}>
                                                            {`${item.split("-")[0]} / ${item.split("-")[1]}`}
                                                        </Link>
                                                    )
                                                ))}
                                            </>
                                            :
                                            <div className="no-history">
                                                <i className="fa-regular fa-folder-open"></i>
                                                <span>No history</span>
                                            </div>
                                        }
                                    </div>
                                </div>
                                : 
                                <div className="no-search">
                                    <div className="history">
                                        <div className="no-history">
                                            <i className="fa-regular fa-circle-xmark"></i>
                                            <span>No results</span>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        
                    </>
                    
                )}
            </div>
        </div>
    )
    
}

export default SearchHistory;