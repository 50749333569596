import { combineReducers } from "redux";
import { kpriceReducer, kpriceSaga } from "./kpriceReducer";
import { loadingReducer } from "./loadingReducer";
import { all } from "redux-saga/effects";

const rootReducer = combineReducers({
  kprice: kpriceReducer,
  Loading: loadingReducer,
});

function* rootSaga() {
  yield all([kpriceSaga()]);
}

export { rootReducer, rootSaga };
