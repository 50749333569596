/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:9795f7fb-63fa-4ec7-b5ca-d212cc9459ed",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_fBSXcmZKG",
    "aws_user_pools_web_client_id": "7hinlpbq1s5cphrolg5h00j9gd",
    "oauth": {
        "domain": "oauth.kpricemarket.com.auth.us-east-1.amazoncognito.com",
        "scope": [
            "openid"
        ],
        "redirectSignIn": "https://kpricemarket.com",
        "redirectSignOut": "https://kpricemarket.com/logout",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "SMS",
        "TOTP"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_UPPERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
