import React, { createContext, useState, useEffect } from 'react';
import { Auth, Hub } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { onUserLoadedInit, onUserLoggedOut } from "../../Reducer/kpriceReducer";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {

  const [isInitialValuesLoaded, setIsInitialValuesLoaded] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  const dispatch = useDispatch();
  useEffect(() => {
    if(user != null && isInitialValuesLoaded == false){
      const username = user.username;
      dispatch(onUserLoadedInit({ username }));
      setIsInitialValuesLoaded(true);
      //console.log(user.signInUserSession.idToken.jwtToken)
      //console.log(user)
    }
    else if(user == null){
      dispatch(onUserLoggedOut());
      setIsInitialValuesLoaded(false);
    }
  }, [user]);

  useEffect(() => {
    // Başlangıçta, kullanıcının zaten oturum açıp açmadığını kontrol edin.
    Auth.currentAuthenticatedUser()
      .then((currentUser) => {
        setIsAuthenticated(true);
        setUser(currentUser);
      })
      .catch(() => {
        setIsAuthenticated(false);
        setUser(null);
      });

    // Oturum açma durumunu dinleme
    const listener = ({ payload }) => {
      switch (payload.event) {
        case 'signIn':
          setIsAuthenticated(true);
          setUser(payload.data);
          navigate('/'); 
          break;
        case 'signOut':
          setIsAuthenticated(false);
          setUser(null);
          navigate('/login'); 
          break;
        default:
          break;
      }
    };

    Hub.listen('auth', listener);

    return () => Hub.remove('auth', listener);
  }, []);

  const signOut = () => {
    Auth.signOut()
      .then(() => {
        setIsAuthenticated(false);
        setUser(null);
      })
      .catch((error) => {
        //console.error('Error signing out: ', error);
      });
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, setIsAuthenticated, user, setUser, signOut }}>
      {children}
    </AuthContext.Provider>
  );
};
