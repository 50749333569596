import React, { useState, useEffect, useRef } from "react";
import { Auth } from 'aws-amplify';
import { toast } from 'react-toastify';
import 'material-react-toastify/dist/ReactToastify.css';
import "./page_changepassword.css";
import Loading from "../components/Global/Loading";

const ChangePassword = () => {
    
    const [firstProcess, setFirstProcess] = useState(false);

    const passwordInput = useRef(null);
    const confirmPasswordInput = useRef(null);
    
    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmNewPassword, setConfirmNewPassword] = useState("");

    const [newPasswordInfoShow, setNewPasswordInfoShow] = useState(false);
    const [newPasswordConditions, setNewPasswordConditions] = useState([false, false, false, false, false]);

    const [oldPasswordShow, setOldPasswordShow] = useState(false);
    const [newPasswordShow, setNewPasswordShow] = useState(false);
    const [confirmNewPasswordShow, setConfirmNewPasswordShow] = useState(false);

    const [submitPasswordLoading, setSubmitPasswordLoading] = useState(false);

    const changePasswordFunction = async () => {//şifre değiştirme işlemleri
        setSubmitPasswordLoading(true);
        if(oldPassword != "" && newPassword != "" && confirmNewPassword != "" && newPassword == confirmNewPassword && !newPasswordConditions.includes(false)){
            try {
                const user = await Auth.currentAuthenticatedUser();
                await Auth.changePassword(user, oldPassword, newPassword);
                setFirstProcess(false);
                setOldPassword("");
                setNewPassword("");
                setConfirmNewPassword("");
                passwordInput.current.classList.remove("danger");
                confirmPasswordInput.current.classList.remove("danger");
                toast.success("Your password has been changed successfully!"); 
                setSubmitPasswordLoading(false);
            }
            catch (err) {
                if (err.message.includes('previousPassword')) {
                    toast.error("Invalid old password!");
                }
                else{
                    toast.error(err.message);
                }
                setSubmitPasswordLoading(false);
            }
        }
        else{
            if(oldPassword == ""){
                toast.error("Enter your Old Password to change password!");
            }
            else if(newPasswordConditions.includes(false)){
                toast.error("Check the password you entered to change your password!");
                passwordInput.current.classList.add("danger");
                confirmPasswordInput.current.classList.add("danger");
                setNewPasswordInfoShow(true);
            }
            else if(newPassword != confirmNewPassword){
                toast.error("New Password and Confirm New Password are not the same, please check!");
                passwordInput.current.classList.add("danger");
                confirmPasswordInput.current.classList.add("danger");
            }
            else{
                toast.error("There was a problem changing the password!");
            }
            setSubmitPasswordLoading(false);
        }
    };

    const controlPassword = (password) => {
        // Uzunluk kontrolü (min 8, max 64 karakter)
        const min8Max64 = password.length >= 8 && password.length <= 64;
                
        // En az bir sayı kontrolü
        const hasNumber = /\d/.test(password);

        // En az bir büyük harf kontrolü
        const hasUppercase = /[A-Z]/.test(password);

        // En az bir küçük harf kontrolü
        const hasLowercase = /[a-z]/.test(password);

        // Özel karakter kontrolü
        const specialCharactersPattern = /[\^\$\*\.\[\]\{\}\(\)\?\-\"!@#%&\/\\,><':;\|_~`+=]/;
        const hasSpecialCharacter = specialCharactersPattern.test(password);

        return [min8Max64, hasNumber, hasSpecialCharacter, hasUppercase, hasLowercase];
    }
    
    useEffect(() => {//girilen şifre kriterleri kontrol ediliyor
        if(!firstProcess){
            setFirstProcess(true);
        }
        else{
            const tempConditions = controlPassword(newPassword);
            setNewPasswordConditions(tempConditions);
    
            if(tempConditions.includes(false) || newPassword != confirmNewPassword){
                passwordInput.current.classList.add("danger");
                confirmPasswordInput.current.classList.add("danger");
            }
            else{
                passwordInput.current.classList.remove("danger");
                confirmPasswordInput.current.classList.remove("danger");
                setNewPasswordInfoShow(false);
            }
        }
        
    }, [newPassword, confirmNewPassword]);
    
    return(
        <div id="main" className="main">
            <div className="allsections allsections-border scrollbarhide section-changepassword">
                <div className="main-form">
                    <div className="title">
                        <p>Please enter your old and new password to change your password!</p>
                    </div>
                    <div className="inputs new-password">
                        <label>Old Password</label>
                        <div className="password-show-hide old" style={oldPasswordShow ? {visibility: "hidden"} : undefined} onClick={() => setOldPasswordShow(true)}><i className="fa-solid fa-eye"></i><span>Show</span></div>
                        <div className="password-show-hide old" style={oldPasswordShow ? undefined : {visibility: "hidden"}} onClick={() => setOldPasswordShow(false)}><i className="fa-solid fa-eye-slash"></i><span>Hide</span></div>
                        <input type={oldPasswordShow ? "text" : "password"} placeholder="Old Password" value={oldPassword} onChange={(event) => setOldPassword(event.target.value)} />
                        <div className="label-with-info">
                            <label>New Password</label>
                            <i className="fa-solid fa-circle-info password-info" onMouseEnter={() => setNewPasswordInfoShow(true)}></i>
                        </div>
                        <div className="password-info-area" style={newPasswordInfoShow ? undefined : {display: "none"}}>
                            <i className="fa-solid fa-circle-xmark close" onClick={() => setNewPasswordInfoShow(false)}></i>
                            <div>
                                <i className="fa-solid fa-circle-xmark danger" style={newPasswordConditions[0] ? {display: "none"} : undefined}></i>
                                <i className="fa-solid fa-circle-check success" style={newPasswordConditions[0] ? undefined : {display: "none"}}></i>
                                <span>Minimum 8, Maximum 64 characters</span>
                            </div>
                            <div>
                                <i className="fa-solid fa-circle-xmark danger" style={newPasswordConditions[1] ? {display: "none"} : undefined}></i>
                                <i className="fa-solid fa-circle-check success" style={newPasswordConditions[1] ? undefined : {display: "none"}}></i>
                                <span>Contains at least 1 number</span>
                            </div>
                            <div>
                                <i className="fa-solid fa-circle-xmark danger" style={newPasswordConditions[2] ? {display: "none"} : undefined}></i>
                                <i className="fa-solid fa-circle-check success" style={newPasswordConditions[2] ? undefined : {display: "none"}}></i>
                                <span>Contains at least 1 special character</span>
                            </div>
                            <div>
                                <i className="fa-solid fa-circle-xmark danger" style={newPasswordConditions[3] ? {display: "none"} : undefined}></i>
                                <i className="fa-solid fa-circle-check success" style={newPasswordConditions[3] ? undefined : {display: "none"}}></i>
                                <span>Contains at least 1 uppercase letter</span>
                            </div>
                            <div>
                                <i className="fa-solid fa-circle-xmark danger" style={newPasswordConditions[4] ? {display: "none"} : undefined}></i>
                                <i className="fa-solid fa-circle-check success" style={newPasswordConditions[4] ? undefined : {display: "none"}}></i>
                                <span>Contains at least 1 lowercase letter</span>
                            </div>
                        </div>
                        <div className="password-show-hide first" style={newPasswordShow ? {visibility: "hidden"} : undefined} onClick={() => setNewPasswordShow(true)}><i className="fa-solid fa-eye"></i><span>Show</span></div>
                        <div className="password-show-hide first" style={newPasswordShow ? undefined : {visibility: "hidden"}} onClick={() => setNewPasswordShow(false)}><i className="fa-solid fa-eye-slash"></i><span>Hide</span></div>
                        <input type={newPasswordShow ? "text" : "password"} ref={passwordInput} placeholder="Min. 8 characters" minLength="8" maxLength="64" autoComplete="new-password" value={newPassword} onChange={(event) => setNewPassword(event.target.value)} />
                        <label>Confirm New Password</label>
                        <div className="password-show-hide" style={confirmNewPasswordShow ? {visibility: "hidden"} : undefined} onClick={() => setConfirmNewPasswordShow(true)}><i className="fa-solid fa-eye"></i><span>Show</span></div>
                        <div className="password-show-hide" style={confirmNewPasswordShow ? undefined : {visibility: "hidden"}} onClick={() => setConfirmNewPasswordShow(false)}><i className="fa-solid fa-eye-slash"></i><span>Hide</span></div>
                        <input type={confirmNewPasswordShow ? "text" : "password"} ref={confirmPasswordInput} placeholder="Min. 8 characters" minLength="8" maxLength="64" autoComplete="new-password" value={confirmNewPassword} onChange={(event) => setConfirmNewPassword(event.target.value)} />
                    </div>
                    <button type="button" onClick={() => changePasswordFunction()} disabled={submitPasswordLoading}>{submitPasswordLoading ? <Loading /> : "Change Password"}</button>
                </div>
            </div>
        </div>
    )

}

export default ChangePassword;