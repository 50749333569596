import React, { useState, useEffect } from 'react';

const CountdownTimer = ({ timestamp, addSeconds, onTimerEnd, format }) => {
    const [timeLeft, setTimeLeft] = useState(() => {
        if (timestamp === "") {
            return null;
        }
        const targetTime = new Date(Number(timestamp)).getTime() + (addSeconds ? addSeconds * 1000 : 0);
        return targetTime - Date.now();
    });

    useEffect(() => {
        if (!timestamp) {
            return;
        }

        const targetTime = new Date(Number(timestamp)).getTime() + (addSeconds ? addSeconds * 1000 : 0);
        const timeLeft = targetTime - Date.now();

        if (timeLeft <= 0) {
            onTimerEnd(false);
        } else {
            onTimerEnd(true);
            setTimeLeft(timeLeft);

            const interval = setInterval(() => {
                const updatedTimeLeft = targetTime - Date.now();
                if (updatedTimeLeft <= 0) {
                    clearInterval(interval);
                    setTimeLeft(0);
                    onTimerEnd(false);
                } else {
                    setTimeLeft(updatedTimeLeft);
                }
            }, 1000);

            return () => clearInterval(interval);
        }
    }, [timestamp, addSeconds, onTimerEnd]);

    if (timeLeft === null) {
        return "";
    }

    // Zaman formatlama fonksiyonunu güncelliyoruz
    const formatTimeLeft = (time) => {
        const totalSeconds = Math.floor(time / 1000);
        const totalMinutes = Math.floor(totalSeconds / 60);
        const remainingSeconds = totalSeconds % 60; // Dakikanın içinde kalan saniyeler

        switch(format) {
            case 'hour':
                const hours = Math.floor(time / (1000 * 60 * 60));
                const minutes = Math.floor((time % (1000 * 60 * 60)) / (1000 * 60));
                const seconds = Math.floor((time % (1000 * 60)) / 1000);
                return `${hours < 10 ? '0' : ''}${hours}:${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
            case 'minute':
                // Dakika ve kalan saniye bilgisiyle formatlayarak döndür
                return `${totalMinutes < 10 ? '0' : ''}${totalMinutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
            case 'second':
                return `${totalSeconds}`; // Direkt toplam saniye değerini string olarak döndür
            default:
                // Eğer bir hata olursa ve tanımlı formatlardan biri gelmezse, tamamıyla saat:dk:sn formatında döndür.
                const hoursD = Math.floor(time / (1000 * 60 * 60));
                const minutesD = Math.floor((time % (1000 * 60 * 60)) / (1000 * 60));
                const secondsD = Math.floor((time % (1000 * 60)) / 1000);
                return `${hoursD < 10 ? '0' : ''}${hoursD}:${minutesD < 10 ? '0' : ''}${minutesD}:${secondsD < 10 ? '0' : ''}${secondsD}`;
        }
    };

    return timestamp != "" ? <div className='timer'>{timeLeft > 0 ? formatTimeLeft(timeLeft) : '00:00:00'}</div> : "";
};

export default CountdownTimer;
