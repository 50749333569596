import React from "react";
import './pages_company.css';

const Privacy = () => {
    
    return(
        <div id="main" className="main">
            <div className="allsections allsections-border scrollbarhide pages-company no-text-subtitle">
                <label className="title">1. GENERAL</label>
                
                <label className="text"><label className="title subtitle">1.1</label>Kprice Market  is committed to protect all users ' privacy. Before using or accessing websites operated by Kprice including www.kpricemarket.com, API located on https://kpricemarket.com and any services made available through the websites, any associated mobile applications and APIs such as https://kpricemarket (collectively, the Services ) any related software, forums, blogs, social media pages and other relevant platforms operated or maintained by Kprice (collectively, we , our , us or the Sites ), you should read this Privacy Policy carefully to familiarize with your rights and what we can do in in terms of your personal information.</label>
                
                <label className="text"><label className="title subtitle">1.2</label>All natural persons or other subjects who log onto the Sites shall be users of the Sites. For the convenience of wording in this Privacy Policy, the users are referred to as 'you' or any other applicable forms of the second-person pronouns.</label>
                
                <label className="text"><label className="title subtitle">1.3</label>For the convenience of wording in this Privacy Policy, you and we are collectively referred to as both parties , and individually as party , a party or one party .</label>
                <label className="title">2. PURPOSES OF THE PRIVACY POLICY</label>
                <label className="text">The Privacy Policy stipulates the types of information of yours ( Personal Data ) that we may collect through your log into the Sites, your registration with the Sites, and/or use the Services we offer, as well as how we shall use and protect the information so collected.</label>
                <label className="title">3. YOUR CONSENT</label>
                <label className="text">To ensure that you have full confidence in our handling of Personal Data, you are advised to read and understand the terms and conditions of this Privacy Policy in detail. In particular, upon your log into our Sites, regardless of whether you have registered with the Sites, you signify your acceptance, understanding and confirmation to the following:</label>
                
                <label className="text"><label className="title subtitle">3.1</label>You, on the basis of your own free will and your requisite consent, agree to disclose your Personal Data to us;</label>
                
                <label className="text"><label className="title subtitle">3.2</label>You will comply with all the terms and conditions of this Privacy Policy;</label>
                
                <label className="text"><label className="title subtitle">3.3</label>You agree that we may collect your information through your log into any of the Sites, your registration with the Sites, and/or your use of the Services offered by us;</label>
                
                <label className="text"><label className="title subtitle">3.4</label>You agree to any changes and modifications that we may make to our Privacy Policy in the future;</label>
                
                <label className="text"><label className="title subtitle">3.5</label>You agree that our branches, affiliates and employees may contact you in connection with the products and Services that in our reasonable opinion that you may be interested in (unless you have indicated in written to us that you do not want to receive such information).</label>
                <label className="title">4. INFORMATION COLLECTED</label>
                
                <label className="text"><label className="title subtitle">4.1</label>When you use the Sites, you agree to permit us to use cookies to track each of your actions and collect and keep all the information that you leave on the Sites, including but not limited to your IP address, location, and other information. For more detail regarding our use of Cookies, please read our Cookies Policy.</label>
                
                <label className="text"><label className="title subtitle">4.2</label>If you are willing to use the Services offered by the Sites, you will be required to fill in and provide the following two categories of information:</label>
                
                <label className="text"><label className="title subtitle">4.2.1</label>Identity Information. Such information can help us verify whether you are eligible to be registered as a user of our Sites. It includes but is not limited to your name, residence address, mailing address, other certificates and corresponding numbers thereof that are issued by your country or government to certify your identity information, as well as all other information that can help us to verify your identity ( Identity information ).</label>
                
                <label className="text"><label className="title subtitle">4.2.2</label>Service information. Such information helps us contact you and provide you with the Services, including but not limited to your phone number, fax number, valid email address, mailing address, and information concerning your debit card and/or other financial institution accounts ( Service information ).</label>
                
                <label className="text"><label className="title subtitle">4.3</label>When you use any of the Sites or the Services, we may collect more information necessary from our exclusive mailboxes or in other manners that we consider as in compliance with relevant requirements, for the purpose of improving the functions of the Sites, enhancing your experience of using our Sites and Services as well as the security thereof, or as is required by any court order, any applicable laws, administrative regulations or any order of any competent government agency or authorities.</label>
                
                <label className="text"><label className="title subtitle">4.4</label>If you visit any links to third-party websites, pages, contents or platforms which may be accessible through links from the Sites or any link of any of our third-party partners, you must and will be required to agree to and comply with the separate and independent privacy policy of such third parties. This Privacy Policy does not apply to any third party websites and we will not bear any liability for the contents and activities</label>
                <label className="title">5. HOW WE COLLECT PERSONAL DATA</label>
                <label className="text">We collect your personal data in the following manner:</label>
                
                <label className="text"><label className="title subtitle">5.1</label>Information you provide to us directly when contacting us;</label>
                
                <label className="text"><label className="title subtitle">5.2</label>Information we receive from third parties, such as third party service providers;</label>
                
                <label className="text"><label className="title subtitle">5.3</label>Information acquired by us during the course of our relationship and dealings with you;</label>
                
                <label className="text"><label className="title subtitle">5.4</label>Information collected through the your use of our Sites and Services; and</label>
                
                <label className="text"><label className="title subtitle">5.5</label>Information gathered from publicly available sources.</label>
                <label className="title">6. RESIDENTS WITHIN THE EUROPEAN ECONOMIC AREA ( EEA )</label>
                
                <label className="text"><label className="title subtitle">6.1</label>For the Personal Data protected by General Data Protection Regulation ( GDPR ), you have a series of legal rights related to the Personal Data you keep in our Sites, including:</label>
                
                <label className="text"><label className="title subtitle">6.1.1</label>Obtain information about your processing Personal Data and access the Personal Data you have left in our Sites. Please note that in some cases, we have right to refuse such requests for access to copies of Personal Data (especially for information subject to legal professional privileges).</label>
                
                <label className="text"><label className="title subtitle">6.1.2</label>If your Personal Data is inaccurate or incomplete, you may ask us to make corrections. In order for us to do so we may request that you provide a copy of valid identity documents to prove the authenticity of your Identity Information.</label>
                
                <label className="text"><label className="title subtitle">6.1.3</label>In some cases you may request us to delete your Personal Data. Please note that under certain circumstances (such as for the purposes of public interest, public health or scientific and historical research), we have the right to retain your Personal Data even if you ask us to delete it.</label>
                
                <label className="text"><label className="title subtitle">6.1.4</label>In some cases, you can lodge an objection to the processing of your Personal Data and ask us to limit the processing of your Personal Data. Similarly, in some cases, we have the right to refuse your request even if you raise objections or ask us to restrict the processing of your Personal Data.</label>
                
                <label className="text"><label className="title subtitle">6.1.5</label>In some cases, you may withdraw your consent in respect of collection and processing of your Personal Data. If there are other legitimate reasons, we also have the right to continue using or processing without your permission.</label>
                
                <label className="text"><label className="title subtitle">6.1.6</label>If you reside in the EEA and have a concern about our processing of your Personal Data that we are not able to resolve through our internal resolution process, you have the right to lodge a complaint with the data privacy authority where you reside. For contact details of your local Data Protection Authority, please see: http://ec.europa.eu/justice/data-protection/article-29/structure/data-protection-authorities/index_en.htm.</label>
                
                <label className="text"><label className="title subtitle">6.2</label>We will assess all requests and complaints we have received and provide you with timely responses. We may ask you to provide a valid copy of the identity document so that we can fulfill our security obligations and prevent unauthorized data disclosure. We have the right to charge you a reasonable administrative fee if your request for access to data is clearly unfounded or out of our obligation, or if you request us to provide additional copies of your Personal Data.</label>
                
                <label className="text"><label className="title subtitle">6.3</label>To facilitate with the Services provided to customers located in the EEA, we request explicit consent for the transfer of Personal Data from the EEA to outside of the area. If you are an individual located in the EEA and you decline to consent to such transfer, you will no longer be able to use our Sites and Services. You will still have the ability to withdraw your digital assets and fiat currency in your accounts opened with us however, all other functionalities will be disabled.</label>
                <label className="title">7. COOKIES</label>
                
                <label className="text"><label className="title subtitle">7.1</label>When you visit our Sites, we use Google stats via cookies to record our performance and check the effect of online advertising. Cookies are a small amount of data that is sent to your browser and stored on your computer hard drive. Only when you use your computer to access our Sites can the cookies be sent to your computer hard drive.</label>
                
                <label className="text"><label className="title subtitle">7.2</label>Cookies are usually used to record the habits and preferences of visitors in browsing the items on our Sites. The information collected by cookies is non- registered and collective statistical data and does not involve Personal Data.</label>
                
                <label className="text"><label className="title subtitle">7.3</label>Cookies, which enable the Sites or Services provider system to recognize your browser and capture and recall information, they cannot be used to obtain data on your hard drive, your email address, or your Personal Data. Most browsers are designed to accept cookies. You can opt to set your browser to reject cookies, or to notify you as soon as possible if you are loaded on cookies. However, if you set your browser to disable cookies, it is possible that you may not be able to launch or use some functions of our Sites. For more information regarding how we use and how can you disable cookies please refer to our Cookies Policy.</label>
                <label className="title">8. PURPOSES OF INFORMATION</label>
                
                <label className="text"><label className="title subtitle">8.1</label>We will use your information that we collect for the following purposes or in the following ways:</label>
                
                <label className="text"><label className="title subtitle">8.1.1</label>to provide you with our Services through our Sites;</label>
                
                <label className="text"><label className="title subtitle">8.1.2</label>to identify and confirm your identity when you use our Sites or Services;</label>
                
                <label className="text"><label className="title subtitle">8.1.3</label>to improve and upgrade the Services and the Sites(your information and feedback received by us can help us improve the Services and the functions and performances of the Sites, so that we can more effectively respond to your enquiries and other needs);</label>
                
                <label className="text"><label className="title subtitle">8.1.4</label>to keep statistics relating to the use of our Sites and to be used for data analysis carried out in cooperation with government agencies, public affairs institutions;</label>
                
                <label className="text"><label className="title subtitle">8.1.5</label>to personalize your experience (your information will help us to better respond to your personalized needs);</label>
                
                <label className="text"><label className="title subtitle">8.1.6</label>to facilitate transactions (your information, whether public or private, will not be sold, exchanged, transferred, or otherwise provided to any other company on any grounds without your consent, except for where doing so is expressly for the purpose of completing the transaction you require);</label>
                
                <label className="text"><label className="title subtitle">8.1.7</label>to send e-mail regularly (the email address that you provide for the purpose of processing orders may be used to receive information on and updates to your orders, in addition to newsletters, updates, related products or Services information, etc., that we may send to you from time to time);</label>
                
                <label className="text"><label className="title subtitle">8.1.8</label>to contact you on matters related to your account, including, to request any additional information or documentation;</label>
                
                <label className="text"><label className="title subtitle">8.1.9</label>to set up security measures to secure your account, including without limitation, to carry out two-factor authentication;</label>
                
                <label className="text"><label className="title subtitle">8.1.10</label>to assess your credit risk;</label>
                
                <label className="text"><label className="title subtitle">8.1.11</label>to assess your risk score according to parameters determined by us;</label>
                
                <label className="text"><label className="title subtitle">8.1.12</label>to detect and reduce risks of fraud;</label>
                
                <label className="text"><label className="title subtitle">8.1.13</label>to enforce/defend our rights;</label>
                
                <label className="text"><label className="title subtitle">8.1.14</label>to comply with applicable laws, regulations and/or orders required by competent authorities; and</label>
                
                <label className="text"><label className="title subtitle">8.1.15</label>to meet other purposes as specified in the User Agreement of the Sites and all legal means adopted for satisfying such purposes.</label>
                
                <label className="text"><label className="title subtitle">8.2</label>We do not sell, trade, or otherwise transfer your Personal Data or allow others to collect, use your Personal Data; however, we may disclose and transfer your Personal Data in the following circumstances:</label>
                
                <label className="text"><label className="title subtitle">8.2.1</label>To our affiliates, trusted third parties who help us operate our Sites, manage our business, or provide Services to you, provided that such parties agree and have adopted appropriate measures to keep such information confidential; and</label>
                
                <label className="text"><label className="title subtitle">8.2.2</label>When we believe that relevant information disclosure is appropriate, or it is required by any of the applicable laws, regulations, rules or by any order of courts or other competent authorities, and is necessary for executing the strategy of our Sites and ensuring the proper functioning of the Sites, or as may be necessary for the related parties to provide Services, or for the protection of the rights, property or safety of us or other persons.</label>
                <label className="text">However, your information will not be provided to other parties for marketing, advertising or other similar purposes. We have implemented international standards to prevent money laundering, terrorist financing and circumventing trade and economic sanctions, which requires us to undertake due diligence on our customers. This may include the use of third-party data and services providers which we will cross-reference with your Personal Data.</label>
                
                <label className="text"><label className="title subtitle">8.3</label>You agree that we have the right to share your Personal Data with:</label>
                
                <label className="text"><label className="title subtitle">8.3.1</label>Member of our company group, which includes our subsidiaries, holding companies and companies under common control and their respective contractors, affiliates, employees or representatives;</label>
                
                <label className="text"><label className="title subtitle">8.3.2</label>Our service providers and other third parties who assist us in providing Services to you;</label>
                
                <label className="text"><label className="title subtitle">8.3.3</label>Entities in connection with any financing, acquisition or dissolution proceedings which involve disclosing certain portion or all of our business or assets; and</label>
                
                <label className="text"><label className="title subtitle">8.3.4</label>Law enforcement agencies and authorities, officers, regulators or other third parties to comply with any law, court order, subpoenas or government requests.</label>
                <label className="title">9. PROTECTION OF PERSONAL DATA</label>
                
                <label className="text"><label className="title subtitle">9.1</label>We adopt appropriate physical, electronic, management and technical measures so as to protect and safeguard the security of your Personal Data. We will, to the greatest extent possible, ensure that any Personal Data collected through our Sites shall not be subject to nuisance by any third party unrelated to us. The security measures that we may take include but not limited to:</label>
                
                <label className="text"><label className="title subtitle">9.1.1</label>Physical measures: records of your Personal Data will be stored in a properly locked place.</label>
                
                <label className="text"><label className="title subtitle">9.1.2</label>Electronic measures: The computer data that contain your Personal Data will be stored in computer systems and storage medias that are subject to strict login restrictions.</label>
                
                <label className="text"><label className="title subtitle">9.1.3</label>Management measures: only staff members duly authorized by us can access your Personal Data, and these staff members shall comply with our internal code concerning Personal Data confidentiality.</label>
                
                <label className="text"><label className="title subtitle">9.1.4</label>Technical measures: such encryption techniques as Secure Socket Layer Encryption may be used to convey your Personal Data.</label>
                
                <label className="text"><label className="title subtitle">9.1.5</label>Other measures: our network servers are protected by proper 'firewall '.</label>
                
                <label className="text"><label className="title subtitle">9.2</label>If you are aware of any security flaws in our Sites, please contact us immediately so that we can take appropriate actions as soon as possible.</label>
                
                <label className="text"><label className="title subtitle">9.3</label>Despite of the above-mentioned technical and security measures, we cannot guarantee that the information transmitted via the Sites is absolutely safe, so we cannot guarantee that the Personal Data that you provide to us through our Website will be safe at all time. We will not be held liable for any losses or damages arising from or caused by any event that may occur in connection with unauthorized access to your Personal Data, and we shall not be held liable for compensation for such losses or damages.</label>
                <label className="title">10. DATA RETENTION</label>
                
                <label className="text"><label className="title subtitle">10.1</label>We strive to maintain the relevance, reliability, accuracy, completeness and currency of the personal information we hold and to protect its privacy and security.</label>
                
                <label className="text"><label className="title subtitle">10.2</label>We keep Personal Data only for as long as is reasonably necessary for the purpose for which it was collected or to comply with any applicable legal or ethical reporting or document retention requirements. We will not retain your Personal Data in a form which permits the identification of the data subject for longer than needed for the legitimate purpose or purposes for which we originally collected it, including for the purpose of satisfying any legal, accounting or reporting requirements.</label>
                
                <label className="text"><label className="title subtitle">10.3</label>To determine the appropriate retention period for Personal Data, we consider the amount, nature, and sensitivity of the personal data, the potential risk of harm from unauthorized use or disclosure of your Personal Data, the purposes for which we process your Personal Data and whether we can achieve those purposes through other means, and the applicable legal requirements. In some circumstances we will anonymize your personal information so that it can no longer be associated with you, in which case we will use such information without further notice to you.</label>
                
                <label className="text"><label className="title subtitle">10.4</label>At the end of the retention period, we will securely delete or destroy data retained, and require our sub-processors or third-party suppliers to do likewise.</label>
                <label className="title">11. MINORS</label>
                
                <label className="text"><label className="title subtitle">11.1</label>Our Sites, Services and our products are not meant for persons who may be defined as minors under applicable laws. As such, if you are below 18, please terminate all access to our Sites and Services immediately. We reserve the right (but not the obligation) to demand at any time evidence for our review to verify that you are above 18 years of age. In the event that you are under 18 years of age, or we reasonably suspect that you are under 18 years of age and you are unable to prove otherwise, all your use of our APIs, account registrations, access to the Sites, enrolments, subscriptions and purchases with us (if any) shall be immediately terminated and no refunds of any kind shall be provided to you.</label>
                
                <label className="text"><label className="title subtitle">11.2</label>To clarify, we do not knowingly collect Personal Data from minors, however, through the internet, we are not able to ascertain on our end whether such individuals are minors. It is thus the responsibility of parents or guardians to ensure that their minors do not access our Website, use our Sites and/or Services, download our App, enrol for any of our events, download or access our related applications, email us, or provide us with Personal Data without their parental/guardian's consent. If a parent or guardian becomes aware that his or her minor has accessed our Sites or provided us with Personal Data without his/her consent, he or she should contact us at support@kpricemarket.com promptly.</label>
                <label className="title">12. MODIFICATION TO THE PRIVACY POLICY</label>
                <label className="text">For expediency and for compliance with any changes in the law, we reserve the right to amend this Privacy Policy from time to time in our sole discretion without prior notice to you. We may inform you of the modifications made to the Privacy Policy by updating and publishing the effective date of the release of new versions hereof. Sometimes, we may issue a notice to inform you of the modification made in the Privacy Policy, which, however, is not an obligation for us. You shall regularly review the Privacy Policy, and if you do not agree to such updates, you shall immediately stop accessing the Sites. You agree that it is your sole responsibility to keep yourself updated of any amendments by reviewing this Privacy Policy at our Sites including the Website regularly.</label>
                <label className="title">13. COMMUNICATION WITH US</label>
                
                <label className="text"><label className="title subtitle">13.1</label>If you have any requests and comments, you can send an email to support@kpricemarket.com which is the only valid and official email through which we communicate with you. We will therefore not bear any liability for your failure to using effective contact information, any act or omission.</label>
                
                <label className="text"><label className="title subtitle">13.2</label>We only publish announcements and information on the basis of the valid and effective contact information on the Sites or post announcements on this Sites.Therefore, we shall not be held liable for any losses arising from your trust in the information that has not been obtained through the above-mentioned means.</label>
                
                <label className="text"><label className="title subtitle">13.3</label>If you have any questions regarding our Privacy Policy, you are welcome to contact us at any time.</label>
                <label className="title">14. GOVERNING LAW AND JURISDICTION</label>
                
                <label className="text"><label className="title subtitle">14.1</label>The terms and conditions of this Privacy Policy are governed by the laws of Republic of Saint Vincent and the Grenadines. In the event of any disputes arising from this Privacy Policy and your use of our Sites and Services ( Dispute ), you must first contact us at support@kpricemarket.com regarding your concern and use your best endeavours to amicably settle any dispute in good faith. We on our part will also use our best endeavours to amicably settle your concerns in good faith. However, if no amicable resolution is reached within thirty (30) days, such Dispute shall be referred to and finally resolved by arbitration in Saint Vincent and the Grenadines to the exclusion of the ordinary courts, in accordance with the Arbitration Rules of the International Chamber of Commerce for the time being in force which rules are deemed to be incorporated by reference in this Clause. The seat of arbitration shall be Saint Vincent and the Grenadines. The arbitration tribunal shall consist of one arbitrator. The language of the arbitration shall be in English.</label>
                
                <label className="text"><label className="title subtitle">14.2</label>The Parties further agree that following the commencement of arbitration, they will attempt in good faith to resolve the Dispute through mediation. Any settlement reached in the course of the mediation shall be referred to the then-seating arbitral tribunal and maybe made a consent award on agreed terms.</label>
                <label className="text">If you have any questions regarding this Privacy Policy or your Personal Data, please contact us at support@kpricemarket.com.</label>
            </div>
        </div>
    )

}

export default Privacy;