import React, { useEffect, useState, useRef, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../components/Global/Loading";
import { AuthContext } from '../components/AuthContext/AuthContext';
import { onWithdrawHistoryOpen, onGetWithdrawHistoryMore, onDepositHistoryOpen, onGetDepositHistoryMore, onTransferHistoryOpen, onGetTransferHistoryMore, onHistorySectionClosed } from "../Reducer/kpriceReducer";

const SectionTransactionHistoryContent = ({ currency }) => {
    
    const { isAuthenticated, user, signOut } = useContext(AuthContext);
    const dispatch = useDispatch();

    const currenciesData = useSelector((state) => state.kprice.currencies.data);
    const depositHistoryData = useSelector((state) => state.kprice.depositHistory.data);
    const withdrawHistoryData = useSelector((state) => state.kprice.withdrawHistory.data);
    const transferHistoryData = useSelector((state) => state.kprice.transferHistory.data);

    const mainForm = useRef(null);
    const infoForm = useRef(null);
    const [tabValue, setTabValue] = useState("Deposit");
    const [detailItem, setDetailItem] = useState(null);
    const [loadMore, setLoadMore] = useState(false);
    const itemsScrollPosition = useRef(0);
    const historyElements = useRef(null);
    
    const [loadWithdraw, setLoadWithdraw] = useState(false);
    const [loadDeposit, setLoadDeposit] = useState(false);
    const [loadTransfer, setLoadTransfer] = useState(false);

    const NoItem = () => {
        return <div className="no-item">
            <i className="fa-regular fa-folder-open"></i>
            <span>Transaction not found</span>
        </div>;
    }

    const historyElementsOnScroll = async (e, historydata) => {
        if(isAuthenticated){
            const { scrollTop, scrollHeight, clientHeight } = e.target;
            if (scrollTop + clientHeight + 5 >= scrollHeight && historydata && historydata.last_evaluated_key && !loadMore) {//eğer divin en altına gelindiyse
                if(historyElements && historyElements.current){
                    itemsScrollPosition.current = historyElements.current.scrollTop;
                }
                setLoadMore(true);
                loadMoreHistory();
            }
        }
    }

    const loadMoreHistory = () => {
        if(isAuthenticated){
            if(tabValue == "Deposit"){
                dispatch(onGetDepositHistoryMore({ currency }));
            }
            else if(tabValue == "Withdraw"){
                dispatch(onGetWithdrawHistoryMore({ currency }));
            }
            else if(tabValue == "Transfer"){
                dispatch(onGetTransferHistoryMore({ currency }));
            }
        }
    }

    useEffect(() => {//formlar arasında geçiş yapılınca tetikleniyor, animasyon sağlıyoruz
        if(mainForm && mainForm.current){
            mainForm.current.style.animation = "none";
            let tempForAnimation = mainForm.current.offsetHeight;
            mainForm.current.style.animation = "open-animation 1.5s";
        }
    }, [detailItem]);
    
    useEffect(() => {//sekmeler arasında geçiş yapılınca tetikleniyor, animasyon sağlıyoruz
        if(infoForm && infoForm.current){
            infoForm.current.style.animation = "none";
            let tempForAnimation = infoForm.current.offsetHeight;
            infoForm.current.style.animation = "open-animation 1.5s";
        }
    }, [tabValue]);

    useEffect(() => {//currency değiştikçe veriler tekrar yükleniyor
        setLoadDeposit(false);
        setLoadWithdraw(false);
        setLoadTransfer(false);
    }, [currency]);

    useEffect(() => {//history değerlerinin çekilmesi
        if(isAuthenticated && currency && currenciesData && currenciesData[currency]){
            if(tabValue == "Deposit" && !loadDeposit){
                dispatch(onDepositHistoryOpen({ currency }));
                setLoadDeposit(true);
            }
            else if(tabValue == "Withdraw" && !loadWithdraw){
                dispatch(onWithdrawHistoryOpen({ currency }));
                setLoadWithdraw(true);
            }
            else if(tabValue == "Transfer" && !loadTransfer){
                dispatch(onTransferHistoryOpen({ currency }));
                setLoadTransfer(true);
            }
        }
    }, [currency, currenciesData, tabValue, loadDeposit, loadWithdraw, loadTransfer, isAuthenticated]);
    
    useEffect(() => {
        setLoadMore(false);
    }, [depositHistoryData, withdrawHistoryData, transferHistoryData]);

    useEffect(() => {
        if(historyElements && historyElements.current){
            if(loadMore){
                historyElements.current.scrollTop = historyElements.current.scrollHeight - historyElements.current.clientHeight;
            }
            else{
                historyElements.current.scrollTop = itemsScrollPosition.current;
            }
        }
    }, [loadMore]);

    useEffect(() => {
        return () => {
            let backToButton = document.getElementsByClassName("section-walletlist-dialog-back-to");
            if(backToButton && backToButton[0]){
                backToButton[0].style.display = "flex";
            }
            dispatch(onHistorySectionClosed());
        };
    }, []);

    useEffect(() => {
        let backToButton = document.getElementsByClassName("section-walletlist-dialog-back-to");
        if(backToButton && backToButton[0]){
            if(detailItem == null){
                backToButton[0].style.display = "flex";
            }
            else{
                backToButton[0].style.display = "none";
            }
        }
    }, [detailItem]);

    return(
        <div className="section-walletlist-dialog-transaction-history" ref={mainForm}>
            {detailItem == null ? 
                <>
                    <div className="inner-title">Transaction History</div>
                    <div className="tab-area">
                        <div onClick={() => setTabValue("Deposit")} className={tabValue == "Deposit" ? "active" : undefined}>Deposit</div>
                        <div onClick={() => setTabValue("Withdraw")} className={tabValue == "Withdraw" ? "active" : undefined}>Withdraw</div>
                        {currency == "USDT" && <div onClick={() => setTabValue("Transfer")} className={tabValue == "Transfer" ? "active" : undefined}>Transfer</div>}
                    </div>
                    <div className="info-area" ref={infoForm}>
                        {tabValue == "Deposit" ?
                            <>
                                {!isAuthenticated || (depositHistoryData && depositHistoryData.history) ? 
                                    (isAuthenticated && depositHistoryData.history.length > 0 ? 
                                        <div className="items" ref={historyElements} onScroll={(e) => historyElementsOnScroll(e, depositHistoryData)}>
                                            {depositHistoryData.history.map((item, index) => (
                                                <>
                                                    <div className="item" key={index} onClick={() => setDetailItem(item)}>
                                                        <div>
                                                            <label>Deposit {item.currency}</label>
                                                            <label>{item.count}</label>
                                                        </div>
                                                        <div>
                                                            <span>{item.event_date}</span>
                                                            <span className={item.status}>
                                                                {item.status == "approved" && <><i className="fa-solid fa-circle-check"></i>Approved</>}
                                                                {item.status == "pending" && <><i className="fa-solid fa-clock"></i>Pending</>}
                                                                {item.status == "denied" && <><i className="fa-solid fa-circle-xmark"></i>Denied</>}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    {depositHistoryData.history.length != (index + 1) && <div className="separator"></div>}
                                                </>
                                            ))}
                                            {loadMore && <div className="loading"><Loading /></div>}
                                        </div>
                                        : <NoItem />
                                    )
                                    : <div className="loading"><Loading /></div>}
                            </>
                            :
                            (tabValue == "Withdraw" ?
                                <>
                                    {!isAuthenticated || (withdrawHistoryData && withdrawHistoryData.history) ? 
                                        (isAuthenticated && withdrawHistoryData.history.length > 0 ? 
                                            <div className="items" ref={historyElements} onScroll={(e) => historyElementsOnScroll(e, withdrawHistoryData)}>
                                                {withdrawHistoryData.history.map((item, index) => (
                                                    <>
                                                        <div className="item" key={index} onClick={() => setDetailItem(item)}>
                                                            <div>
                                                                <label>Withdraw {item.currency}</label>
                                                                <label>{item.count}</label>
                                                            </div>
                                                            <div>
                                                                <span>{item.event_date}</span>
                                                                <span className={item.status}>
                                                                    {item.status == "approved" && <><i className="fa-solid fa-circle-check"></i>Approved</>}
                                                                    {item.status == "pending" && <><i className="fa-solid fa-clock"></i>Pending</>}
                                                                    {item.status == "denied" && <><i className="fa-solid fa-circle-xmark"></i>Denied</>}
                                                                </span>
                                                            </div>
                                                        </div>
                                                        {withdrawHistoryData.history.length != (index + 1) && <div className="separator"></div>}
                                                    </>
                                                ))}
                                                {loadMore && <div className="loading"><Loading /></div>}
                                            </div>
                                            : <NoItem />
                                        )
                                        : <div className="loading"><Loading /></div>}
                                </>
                                :
                                (tabValue == "Transfer" ?
                                    <>
                                        {!isAuthenticated || (transferHistoryData && transferHistoryData.history) ? 
                                            (isAuthenticated && transferHistoryData.history.length > 0 ? 
                                                <div className="items" ref={historyElements} onScroll={(e) => historyElementsOnScroll(e, transferHistoryData)}>
                                                    {transferHistoryData.history.map((item, index) => (
                                                        <>
                                                            <div className="item" key={index} onClick={() => setDetailItem(item)}>
                                                                <div>
                                                                    <label>Transfer {item.currency}</label>
                                                                    <label>{item.count}</label>
                                                                </div>
                                                                <div>
                                                                    <span>{item.event_date}</span>
                                                                    <span className={item.status}>
                                                                        {item.status == "approved" && <><i className="fa-solid fa-circle-check"></i>Approved</>}
                                                                        {item.status == "pending" && <><i className="fa-solid fa-clock"></i>Pending</>}
                                                                        {item.status == "denied" && <><i className="fa-solid fa-circle-xmark"></i>Denied</>}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            {transferHistoryData.history.length != (index + 1) && <div className="separator"></div>}
                                                        </>
                                                    ))}
                                                    {loadMore && <div className="loading"><Loading /></div>}
                                                </div>
                                                : <NoItem />
                                            )
                                            : <div className="loading"><Loading /></div>}
                                    </>
                                    : ""
                                )
                            )
                        }
                    </div>
                </>
                :
                <div className="transaction-detail">
                    <>
                        <div className="back-to-main">
                            <div className="back-to" onClick={() => setDetailItem(null)}>
                                <i className="fa-solid fa-chevron-left"></i>
                            </div>
                        </div>
                        <div className="top">
                            <p>{tabValue}</p>
                            <label>{detailItem.count}<div>{detailItem.currency}</div></label>
                            <span className={detailItem.status}>
                                {detailItem.status == "approved" && <><i className="fa-solid fa-circle-check"></i>Approved</>}
                                {detailItem.status == "pending" && <><i className="fa-solid fa-clock"></i>Pending</>}
                                {detailItem.status == "denied" && <><i className="fa-solid fa-circle-xmark"></i>Denied</>}
                            </span>
                        </div>
                        <div className="bottom">
                            <div className="items">
                                {detailItem.transaction_id && <div className="item"><label>Transaction Id</label><span>{detailItem.transaction_id}</span></div>}
                                {detailItem.network && <div className="item"><label>Network</label><span>{detailItem.network}</span></div>}
                                {detailItem.address && <div className="item"><label>Address</label><span>{detailItem.address}</span></div>}
                                {detailItem.fee_count && <div className="item"><label>Fee</label><span>{detailItem.fee_count}</span></div>}
                                {detailItem.event_date && <div className="item"><label>Event Date</label><span>{detailItem.event_date}</span></div>}
                                {detailItem.result_date && <div className="item"><label>Result Date</label><span>{detailItem.result_date}</span></div>}
                                {detailItem.transfer_type && <div className="item"><label>Transfer Type</label><span>{detailItem.transfer_type == "spot_to_future" ? "Spot to Future" : "Future to Spot"}</span></div>}
                            </div>
                        </div>
                    </>
                </div>
            }
        </div>
    )
    
}

export default SectionTransactionHistoryContent;