import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getPriceForWalletCurrencies, getPriceForCurrency, formatNumberWithCommas, formatNumberWithShowPlaces } from '../Lib/calculationFunctions';
import { editNumber_reducing_trailing_zeros, editNumber_reducing_trailing_zeros_special, editNumber_reducing_decimal_places, editNumber_increasing_decimal_places, editNumber_separate_with_comma, editNumber_control_decimal_places_length } from '../Lib/calculationFunctions';
import { Link } from 'react-router-dom';

const SectionCurrencyInfoPopupContent = ({ currency, setDisplayCurrencyDialog, setDisplayTransferDialog, setDisplayTransactionHistoryDialog }) => {
    
    const currenciesData = useSelector((state) => state.kprice.currencies.data);
    const productsData = useSelector((state) => state.kprice.products.data);
    const productsMiniTickerData = useSelector((state) => state.kprice.productsMiniTicker.data);
    const walletData = useSelector((state) => state.kprice.wallet.data);

    const [currentPriceForCurrency, setCurrentPriceForCurrency] = useState("NaN");

    const [goTradeProducts, setGoTradeProducts] = useState([]);

    const calcPrice = (price, show_places) => {
        const response = editNumber_control_decimal_places_length(editNumber_reducing_trailing_zeros(price), show_places);
        if(response){
            return editNumber_separate_with_comma(editNumber_increasing_decimal_places(editNumber_reducing_decimal_places(editNumber_reducing_trailing_zeros(price), show_places), show_places));
        }
        else{
            return editNumber_separate_with_comma(editNumber_reducing_trailing_zeros(price));
        }
    }

    const calcPriceForCurrencies = (key) => {
        let currentPrice = key && productsMiniTickerData ? productsMiniTickerData[key].currentPrice : "NaN";
        let show_places = key && productsData && productsData[key] && productsData[key].show_places ? productsData[key].show_places : null;
        
        return calcPrice(currentPrice, show_places);
    }

    useEffect(() => {
        let currentPrice = currency && productsMiniTickerData ? getPriceForCurrency(currency, productsMiniTickerData) : "NaN";
        let show_places = currency && productsData && productsData[`${currency}-USDT`] && productsData[`${currency}-USDT`].show_places ? productsData[`${currency}-USDT`].show_places : null;
        
        setCurrentPriceForCurrency(calcPrice(currentPrice, show_places));

        const goTradeData = [];
        if(Object.keys(productsMiniTickerData).filter(key => key.startsWith(`${currency ? currency : "NaN"}-`)).length > 0){
            Object.keys(productsMiniTickerData).filter(key => key.startsWith(`${currency ? currency : "NaN"}-`)).forEach((key) => {
                if(!productsData[key].status_spot || (productsData[key].status_spot && productsData[key].status_spot == "active")){
                    goTradeData.push(key);
                }
            })
        }
        setGoTradeProducts(goTradeData);
    }, [currency, productsData, productsMiniTickerData]);

    return(
        <div className="section-walletlist-dialog-body">
            <div className="body-info">
                <div className="count">
                    <span>{currency && walletData && walletData[currency] && walletData[currency].count && currenciesData && currenciesData[currency] && currenciesData[currency].show_places ? editNumber_separate_with_comma(editNumber_reducing_trailing_zeros_special(editNumber_increasing_decimal_places(editNumber_reducing_decimal_places(walletData[currency].count, currenciesData[currency].show_places), currenciesData[currency].show_places))) : 0}</span>
                    <label>{currency && walletData && walletData[currency] && currenciesData && currenciesData["USDT"] && currenciesData["USDT"].show_places && productsMiniTickerData && getPriceForWalletCurrencies(currency, walletData, productsMiniTickerData) ? <>≈ <div>$</div>{editNumber_separate_with_comma(editNumber_increasing_decimal_places(editNumber_reducing_decimal_places(editNumber_reducing_trailing_zeros(getPriceForWalletCurrencies(currency, walletData, productsMiniTickerData)), currenciesData["USDT"].show_places), currenciesData["USDT"].show_places))}</> : <>≈ <div>$</div>0</>}</label>
                </div>
                <div className="price">
                    <span>Price</span>
                    <label>{`${currentPriceForCurrency} USDT`}</label>
                </div>
                <div className="transaction-history" onClick={() => {setDisplayCurrencyDialog(false);setDisplayTransactionHistoryDialog(true);}}>
                    <div>
                        <i className="fa-solid fa-receipt"></i>
                        Transaction History
                    </div>
                    <i className="fa-solid fa-chevron-right"></i>
                </div>
                {goTradeProducts.length > 0 && 
                    <div className="currencies">
                        <label className="title">Go Trade</label>
                        <div className="items">
                            {goTradeProducts.map(key => (
                                <Link className="item" key={key} to={`/trade?market=${key}`}>
                                    <div className="item-title"><label>{key.split("-")[0]}</label><span> / {key.split("-")[1]}</span></div>
                                    <div className="item-info"><label>{calcPriceForCurrencies(key)}</label><span className={productsMiniTickerData[key].percentage_difference_24h > 0 ? "success" : (productsMiniTickerData[key].percentage_difference_24h < 0 ? "danger" : undefined)}>{productsMiniTickerData && productsMiniTickerData[key] && productsMiniTickerData[key].percentage_difference_24h != null ? productsMiniTickerData[key].percentage_difference_24h : "NaN"}%</span></div>
                                </Link>
                            ))}
                        </div>
                    </div>
                }
            </div>
            <div className="button">
                <div>
                    {currency && walletData && walletData[currency] && walletData[currency].currency && walletData[currency].currency == "USDT" ? <button className="button-element" type="button" onClick={() => {setDisplayCurrencyDialog(false);setDisplayTransferDialog(true);}}><i className="fa-solid fa-coins"></i>Transfer</button> : ""}
                    <div>
                        <Link to="/deposit" state={{currency: currency ? currency : null}} className="button-element">
                            <i className="fa-solid fa-money-bill"></i>Deposit
                        </Link>
                        <Link to="/withdraw" state={{currency: currency ? currency : null}} className="button-element">
                            <i className="fa-solid fa-hand-holding-dollar"></i>Withdraw
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
    
}

export default SectionCurrencyInfoPopupContent;