import React, { useEffect, useState, useMemo } from "react";
import { useSelector } from "react-redux";
import Loading from "../../components/Global/Loading";
import { editNumber_reducing_trailing_zeros, editNumber_reducing_decimal_places, editNumber_increasing_decimal_places, editNumber_separate_with_comma } from '../../Lib/calculationFunctions';

const SectionLastTrades = () => {

    const selectedProduct = useSelector((state) => state.kprice.selectedProduct);
    const lastTrades = useSelector((state) => state.kprice.lastTrades.data);
    const productsData = useSelector((state) => state.kprice.products.data);

    return(
        <div className="allsections allsections-border section-lasttrades">
            <div className="title">
                Market Trades
            </div>
            {lastTrades.product_id == selectedProduct && lastTrades.last_trades && lastTrades.last_trades.length > 0 ? 
                <>
                    <div className="items-title">
                        <span>Price ({lastTrades.product_id.split("-")[1]})</span>
                        <span>Amount ({lastTrades.product_id.split("-")[0]})</span>
                        <span>Time</span>
                    </div>
                    <div className="items">
                        {lastTrades.last_trades.map((item, index) => (
                            <div className="item">
                                <span className={item.m == 1 ? "success" : (item.m == 2 ? "danger" : undefined)}>{editNumber_separate_with_comma(editNumber_increasing_decimal_places(editNumber_reducing_decimal_places(editNumber_reducing_trailing_zeros(item.p), productsData && productsData[lastTrades.product_id] && productsData[lastTrades.product_id].show_places ? productsData[lastTrades.product_id].show_places : "NaN"), productsData && productsData[lastTrades.product_id] && productsData[lastTrades.product_id].show_places ? productsData[lastTrades.product_id].show_places : "NaN"))}</span>
                                <span>{item.v}</span>
                                <span>{item.time}</span>
                            </div>
                        ))}
                    </div>
                </>
                : 
                <div className="loading"><Loading /></div>
            }
        </div>
    )

}

export default SectionLastTrades;