import React, { useState, useEffect, useRef } from "react";
import QRCode from 'qrcode.react';
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { onPageDepositOpen } from "../Reducer/kpriceReducer";
import Loading from "../components/Global/Loading";
import { toast } from 'react-toastify';
import "./page_deposit.css";

const Deposit = () => {
    
    const dispatch = useDispatch();

    const location = useLocation();
    const stateCurrency = location.state && location.state.currency ? location.state.currency : "USDT";

    const QuickSearchOptions = ["BTC", "USDT", "USDC", "BNB", "ETH"];

    const [coinList, setCoinList] = useState([]);//select coin listesi
    const [coinListSearch, setCoinListSearch] = useState("");//select coin listesindeki search input değeri
    const currenciesData = useSelector((state) => state.kprice.currencies.data);
    const depositData = useSelector((state) => state.kprice.deposit.data);
    const userInfo = useSelector((state) => state.kprice.userInfo.data);
    
    const [selectedCoin, setSelectedCoin] = useState(stateCurrency);//select coin değeri
    const [displaySelectCoin, setDisplaySelectCoin] = useState(false);//select coin görünürlüğü

    const [addressValue, setAddressValue] = useState("");//seçili adres değeri

    const [selectedNetwork, setSelectedNetwork] = useState(null);//seçili network değeri
    const [displaySelectNetwork, setDisplaySelectNetwork] = useState(false);//network select popup görünürlüğü
    
    //select coin ve select network görünürlüğü dışarıya tıklandığında otomatik kapanması için
    const dropdownRef1 = useRef(null);
    const dropdownRef2 = useRef(null);
    const dropdownRef3 = useRef(null);
    const dropdownRef4 = useRef(null);

    useEffect(() => {
        const newData = [];

        if(currenciesData && depositData && depositData.currency_networks){
            const coins = Object.keys(depositData.currency_networks);
            coins.forEach((item) => {
                let itemFind = true;
                let searchItem = "";

                if (coinListSearch != "") {
                    itemFind = false;
                    if(item && currenciesData && currenciesData[item] && currenciesData[item].currency_name){
                        searchItem = `${item} ${currenciesData[item].currency_name}`;
                    }
                    else if(item){
                        searchItem = `${item}`;
                    }
                    else if(currenciesData && item && currenciesData[item] && currenciesData[item].currency_name){
                        searchItem = `${currenciesData[item].currency_name}`;
                    }

                    if(searchItem != "" && searchItem.toString().toLowerCase().includes(coinListSearch.toLowerCase())){
                        itemFind = true;
                    }
                }

                if(itemFind){
                    newData.push(item);
                }
            });
        }
        
        const newDataSorted = newData.sort((a, b) => {
            // Sayısal anahtarları doğru sıralamak için ek bir kontrol
            const numA = parseInt(a, 10);
            const numB = parseInt(b, 10);
          
            if (!isNaN(numA) && !isNaN(numB)) {
              return numA - numB;
            }
          
            return a.localeCompare(b);
        });
        setCoinList(newDataSorted);
    }, [currenciesData, depositData, coinListSearch]);

    useEffect(() => {//network temizleme
        setSelectedNetwork(null);
    }, [selectedCoin]);

    useEffect(() => {//adres güncelleme
        if(selectedNetwork && depositData && depositData.currency_networks && depositData.currency_networks[selectedCoin].includes(selectedNetwork) && depositData.deposit_addresses && depositData.deposit_addresses[selectedNetwork]){
            setAddressValue(depositData.deposit_addresses[selectedNetwork]);
        }
        else{
            setAddressValue("");
        }
    }, [selectedNetwork, depositData]);

    useEffect(() => {//deposit verileri ve listener
        if(userInfo && userInfo.deposit_permission && userInfo.deposit_permission == "True"){
            dispatch(onPageDepositOpen());
        }

        //select coin görünürlüğü dışarıya tıklandığında otomatik kapanması için
        document.addEventListener('click', hiddenCustomizedSelect);
        return () => {//component kapanırken event listener temizleniyor
          document.removeEventListener('click', hiddenCustomizedSelect);
        };
    }, [userInfo]);

    const hiddenCustomizedSelect = (e) => {//select coin görünürlüğü dışarıya tıklandığında otomatik kapanması için
        if (dropdownRef1.current && !dropdownRef1.current.contains(e.target) && dropdownRef2.current && !dropdownRef2.current.contains(e.target)) {
            setDisplaySelectCoin(false);
        }

        if (dropdownRef3.current && !dropdownRef3.current.contains(e.target) && dropdownRef4.current && !dropdownRef4.current.contains(e.target)) {
            setDisplaySelectNetwork(false);
        }
    };

    const addressCopy = async (text) => {
        try {
            await navigator.clipboard.writeText(text);
            toast.success("Successfully copied!");
        } catch (err) {
            toast.danger("Copy failed!");
        }
    };
    
    return(
        <div id="main" className="main">
            <div className="allsections allsections-border scrollbarhide section-deposit">
                <div className="deposit-form">
                    <div className="deposit-form-element">
                        <div className={selectedCoin ? "deposit-form-element-title active" : "deposit-form-element-title"}>
                            <span>1</span>
                            <div></div>
                        </div>
                        <div className="deposit-form-element-main">
                            <div className={displaySelectCoin ? "deposit-form-element-main-title active" : "deposit-form-element-main-title"}>Select Coin</div>
                            <div className="deposit-form-element-select" ref={dropdownRef1} onClick={() => {if(displaySelectCoin){setDisplaySelectCoin(false)}else{setDisplaySelectCoin(true)}}}>
                                {currenciesData ? 
                                    (currenciesData[selectedCoin] ? <div>
                                        <img src={currenciesData[selectedCoin] && currenciesData[selectedCoin].picture_url ? currenciesData[selectedCoin].picture_url : undefined} />
                                        <span>{currenciesData[selectedCoin] && currenciesData[selectedCoin].currency ? currenciesData[selectedCoin].currency : "NaN"}</span>
                                        <label>{currenciesData[selectedCoin] && currenciesData[selectedCoin].currency_name ? currenciesData[selectedCoin].currency_name : "NaN"}</label>
                                    </div> 
                                    : <div>
                                        <img src={undefined} />
                                        <span>NaN</span>
                                        <label>NaN</label>
                                    </div>)
                                : (userInfo && userInfo.deposit_permission && userInfo.deposit_permission == "False" ? 
                                <span className="placeholder">Select Coin</span>
                                : <div className="section-deposit-loading"><Loading /></div>)}
                                <i className="fa-solid fa-caret-up" style={displaySelectCoin ? {} : {display: "none"}}></i>
                                <i className="fa-solid fa-caret-down" style={displaySelectCoin ? {display: "none"} : {}}></i>
                            </div>
                            <div className="deposit-form-element-select-options" ref={dropdownRef2} style={displaySelectCoin ? {} : {display: "none"}}>
                                <div className="search-area">
                                    <div>
                                        <input type="text" className="search" placeholder="Search Coin" value={coinListSearch} onChange={(e) => setCoinListSearch(e.target.value)} />
                                        <i className="fa-solid fa-magnifying-glass"></i>
                                    </div>
                                    {QuickSearchOptions && coinList && currenciesData && 
                                        <div className="quick-search">
                                            {QuickSearchOptions.map((item, index) => (
                                                currenciesData[item] && 
                                                <div className={selectedCoin == currenciesData[item].currency ? "item active" : "item"} onClick={() => {setDisplaySelectCoin(false);setSelectedCoin(currenciesData[item].currency);}}>
                                                    <img src={currenciesData[item].picture_url ? currenciesData[item].picture_url : undefined} />
                                                    <span>{currenciesData[item].currency ? currenciesData[item].currency : "NaN"}</span>
                                                </div>
                                            ))}
                                        </div>
                                    }
                                </div>
                                <div className="options">
                                    {coinList.map((item, index) => (
                                        currenciesData[item] && 
                                        <div className={selectedCoin == currenciesData[item].currency ? "option active" : "option"} onClick={() => {setDisplaySelectCoin(false);setSelectedCoin(currenciesData[item].currency);}}>
                                            <img src={currenciesData[item].picture_url ? currenciesData[item].picture_url : undefined} />
                                            <span>{currenciesData[item].currency ? currenciesData[item].currency : "NaN"}</span>
                                            <label>{currenciesData[item].currency_name ? currenciesData[item].currency_name : "NaN"}</label>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="deposit-form-element">
                        <div className={selectedNetwork ? "deposit-form-element-title active" : "deposit-form-element-title"}>
                            <span>2</span>
                            <div></div>
                        </div>
                        <div className="deposit-form-element-main">
                            <div className={displaySelectNetwork ? "deposit-form-element-main-title active" : "deposit-form-element-main-title"}>Select Network</div>
                            <div className="deposit-form-element-select noimg" ref={dropdownRef3} onClick={() => {if(displaySelectNetwork){setDisplaySelectNetwork(false)}else{setDisplaySelectNetwork(true)}}}>
                                {selectedNetwork ? <div><span>{depositData.network_types[selectedNetwork].network_tag}</span><label>{selectedNetwork}</label></div> : <span className="placeholder">Select Network</span>}
                                <i className="fa-solid fa-caret-up" style={displaySelectNetwork ? {} : {display: "none"}}></i>
                                <i className="fa-solid fa-caret-down" style={displaySelectNetwork ? {display: "none"} : {}}></i>
                            </div>
                            <div className="deposit-form-element-select-options" ref={dropdownRef4} style={displaySelectNetwork ? {} : {display: "none"}}>
                                <div className="info">
                                    Please note that only supported networks on Kprice platform are shown. If you deposit via another network your assets may be lost.
                                </div>
                                {depositData && depositData.network_types && depositData.deposit_addresses && depositData.currency_networks && depositData.currency_networks[selectedCoin] && depositData.currency_networks[selectedCoin].map((item) => (
                                    <div className={selectedNetwork ? (selectedNetwork == item ? "option active" : "option") : "option"} onClick={() => {setSelectedNetwork(item);setDisplaySelectNetwork(false);}}>
                                        <span>{depositData.network_types[item].network_tag}</span>
                                        <label>{item}</label>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="deposit-form-element">
                        <div className={selectedNetwork ? "deposit-form-element-title active" : "deposit-form-element-title"}>
                            <span>3</span>
                        </div>
                        <div className="deposit-form-element-main">
                            <div className={selectedNetwork ? "deposit-form-element-main-title active" : "deposit-form-element-main-title"}>Deposit Address</div>
                            <div className={selectedNetwork ? "deposit-form-address active" : "deposit-form-address"}>
                                {selectedNetwork ? 
                                <div className="deposit-form-address-main">
                                    <div className="deposit-form-address-qr"><QRCode value={addressValue} /></div>
                                    <div className="deposit-form-address-info"><label>Address</label><span>{addressValue}</span></div>
                                    <i className="fa-solid fa-copy" onClick={() => addressCopy(addressValue)}></i>
                                </div> : ""
                                }
                            </div>
                            {/* <div className="deposit-form-result" style={selectedNetwork ? {undefined} : {visibility: "hidden"}}>
                                <span>Minimum deposit</span>
                                <span>More than 0.01 USDT</span>
                            </div> */}
                            {!(userInfo && userInfo.deposit_permission && userInfo.deposit_permission == "True") ? 
                            <Link to="/verification" className="withdraw-form-element-amount-error">You must verify to deposit money!</Link>
                            : ""
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default Deposit;