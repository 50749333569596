import React, { useEffect } from "react";
import { useSelector } from "react-redux";

const withLast24HourData = (WrappedComponent) => (props) => {
  const product_id = props.product_id;  // product_id'yi prop olarak al

  const last24HourData  = useSelector(
    (state) => state.kprice.products.data[product_id]?.last24h
  ); 

  useEffect(() => {
    //console.log(product_id+"++++++++++++++++++")
  }, [last24HourData]);

  return <WrappedComponent {...props} data={last24HourData} />;
};

export default withLast24HourData;