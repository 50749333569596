import React, { useState, useEffect, useRef } from "react";
import SectionCurrencyStatus from "./section_currencystatus";

const SectionCenterTabs = ({ pageLink }) => {

    const [tabValue, setTabValue] = useState("Top Gainers");
    const tabArea = useRef(null);

    useEffect(() => {
        tabArea.current.style.animation = "none";
        let tempForAnimation = tabArea.current.offsetHeight;
        tabArea.current.style.animation = "open-animation 1.5s";
    }, [tabValue]);
    
    return(
        <div className="allsections allsections-border section-centertabs">
            <div className="section-centertabs-tabs">
                <div className={tabValue == "Hot" ? "active" : undefined} onClick={() => setTabValue("Hot")}><i className="fa-solid fa-fire"></i>Hot</div>
                <div className={tabValue == "Favorite" ? "active" : undefined} onClick={() => setTabValue("Favorite")}>Favorite</div>
                <div className={tabValue == "Top Gainers" ? "active" : undefined} onClick={() => setTabValue("Top Gainers")}>Top Gainers</div>
                <div className={tabValue == "Top Losers" ? "active" : undefined} onClick={() => setTabValue("Top Losers")}>Top Losers</div>
                <div className={tabValue == "Volume" ? "active" : undefined} onClick={() => setTabValue("Volume")}>Volume</div>
            </div>
            <div className="section-centertabs-tab-area" ref={tabArea}>
                {tabValue == "Hot" && <SectionCurrencyStatus type="hot" pageLink={pageLink} />}
                {tabValue == "Favorite" && <SectionCurrencyStatus type="favorite" pageLink={pageLink} />}
                {tabValue == "Top Gainers" && <SectionCurrencyStatus type="positive" pageLink={pageLink} />}
                {tabValue == "Top Losers" && <SectionCurrencyStatus type="negative" pageLink={pageLink} />}
                {tabValue == "Volume" && <SectionCurrencyStatus type="volume" pageLink={pageLink} />}
            </div>
        </div>
    )

}

export default SectionCenterTabs;