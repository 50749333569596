import React, { useEffect, useContext } from "react";
import { AuthContext } from '../components/AuthContext/AuthContext';
import SectionWallet from "./Dashboard-Sections/section_wallet";
import SectionWalletList from "./Dashboard-Sections/section_walletlist";
import SectionCenterTabs from "./Dashboard-Sections/section_centertabs";
import SectionQuickActions from "./Dashboard-Sections/section_quickactions";
import SectionNotifications from "./section_notifications";
import "./page_dashboard.css";

const Dashboard = () => {

    const { isAuthenticated, user, signOut } = useContext(AuthContext);

    useEffect(() => {
        if(document.getElementById("main") && document.getElementById("footer")){
            document.getElementById("main").style.display = "none";
            document.getElementById("footer").style.display = "none";
        }
        
        setTimeout(() => {
            if(document.getElementById("main") && document.getElementById("footer")){
                document.getElementById("main").style.display = "block";
                document.getElementById("footer").style.display = "flex";
            }
        }, 1);
    }, []);
    
    return(
        <>
            <div id="main" className={isAuthenticated ? "main dashboard logged-in" : "main dashboard"}>
                <div className="dashboard-sections">
                    {isAuthenticated ? 
                        <>
                            <div className="dashboard-section1">
                                <SectionWallet />
                                <SectionWalletList />
                            </div>
                            <div className="dashboard-section2">
                                <SectionCenterTabs pageLink="trade" />
                            </div>
                            <div className="dashboard-section3">
                                <SectionQuickActions />
                                <SectionNotifications />
                            </div>
                        </> :
                        <div className="single-section">
                            <SectionCenterTabs pageLink="trade" />
                        </div>
                    }
                </div>
            </div>
        </>
    )

}

export default Dashboard;