import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getPriceForWalletCurrencies, formatNumberWithCommas, formatNumberWithShowPlaces } from '../../Lib/calculationFunctions';
import { editNumber_reducing_trailing_zeros, editNumber_reducing_decimal_places, editNumber_increasing_decimal_places, editNumber_separate_with_comma, editNumber_control_decimal_places_length } from '../../Lib/calculationFunctions';
import wallet2 from "../../images/walletsection2.png";
import Decimal from 'decimal.js';

const SectionWallet = () => {

    const [totalPrice, setTotalPrice] = useState("NaN");
    const [totalAmount, setTotalAmount] = useState("NaN");
    const productsMiniTickerData = useSelector((state) => state.kprice.productsMiniTicker.data);
    const walletData = useSelector((state) => state.kprice.wallet.data);
    const walletDataKeys = walletData ? Object.keys(walletData) : [];
    const currenciesData = useSelector((state) => state.kprice.currencies.data);
    
    useEffect(() => {
        let totalPrice = new Decimal(0);
        let totalAmount = new Decimal(0);
        
        if(productsMiniTickerData && walletData){
            walletDataKeys.forEach((item) => {
                let itemPrice = getPriceForWalletCurrencies(item, walletData, productsMiniTickerData);
                if(itemPrice != "NaN"){
                    totalPrice = new Decimal(itemPrice).plus(totalPrice);
                }
            });

            if(totalPrice != 0){
                if(productsMiniTickerData["BTC-USDT"] && productsMiniTickerData["BTC-USDT"].currentPrice){
                    totalAmount = totalPrice.dividedBy(new Decimal(productsMiniTickerData["BTC-USDT"].currentPrice));
                }
            }

            let priceShowPlaces = currenciesData && currenciesData["USDT"] && currenciesData["USDT"].show_places ? currenciesData["USDT"].show_places : null;
            let amountShowPlaces = currenciesData && currenciesData["BTC"] && currenciesData["BTC"].show_places ? currenciesData["BTC"].show_places : null;

            setTotalPrice(editNumber_separate_with_comma(editNumber_increasing_decimal_places(editNumber_reducing_decimal_places(editNumber_reducing_trailing_zeros(totalPrice.toString()), priceShowPlaces), priceShowPlaces)));
            setTotalAmount(editNumber_separate_with_comma(editNumber_increasing_decimal_places(editNumber_reducing_decimal_places(editNumber_reducing_trailing_zeros(totalAmount.toString()), amountShowPlaces), amountShowPlaces)));
        }
    }, [currenciesData, productsMiniTickerData, walletData]);

    return(
        <div id="section-wallet" className="allsections">
            <div className="allsections-title">
                <i className="fa-solid fa-wallet"></i>
                <label>Wallet Cryptocurrency</label>
            </div>
            <div className="allsections-border section-wallet">
                <div className="section-wallet-left">
                    <div className="section-wallet-title">Total Assets</div>
                    <div className="section-wallet-total">
                        <label>≈<span>$</span>{totalPrice}</label>
                    </div>
                    <div className="section-wallet-btc">
                        <label>≈ {totalAmount}</label>
                        <span>BTC</span>
                    </div>
                </div>
                <img src={wallet2} className="section-wallet-right" />
            </div>
        </div>
    )

}

export default SectionWallet;