import React, { useEffect, useState, useMemo } from "react";
import { useSelector } from "react-redux";
import SectionCurrencyInfoPopupContent from '../section_curreny_info_popup_content';
import SectionTransferPopupContent from '../section_transfer_popup_content';
import SectionTransactionHistoryContent from '../section_transaction_history_content';

const SectionTradeCurrencyInfo = ({ currency }) => {
    
    const currenciesData = useSelector((state) => state.kprice.currencies.data);
    const [displayCurrencyDialog, setDisplayCurrencyDialog] = useState(true);//currency popup görünürlüğünü tutuyor
    const [displayTransferDialog, setDisplayTransferDialog] = useState(false);//transfer popup görünürlüğünü tutuyor
    const [displayTransactionHistoryDialog, setDisplayTransactionHistoryDialog] = useState(false);//transaction history popup görünürlüğünü tutuyor

    useEffect(() => {//currency değiştikçe sıfırlanıyor
        setDisplayCurrencyDialog(true);
        setDisplayTransferDialog(false);
        setDisplayTransactionHistoryDialog(false);
    }, [currency]);

    return(
        <div className="allsections allsections-border section-tradecurrencyinfo">
            <div className="section-tradecurrencyinfo-header">
                <img src={currency && currenciesData && currenciesData[currency].picture_url ? currenciesData[currency].picture_url : undefined} />
                <label>{currency && currenciesData && currenciesData[currency].currency ? currenciesData[currency].currency : "NaN"}</label>
                <span>{currency && currenciesData && currenciesData[currency].currency_name ? currenciesData[currency].currency_name : "NaN"}</span>
            </div>
            {(displayTransferDialog || displayTransactionHistoryDialog) && 
                <div className="section-walletlist-dialog-back-to" onClick={() => {setDisplayTransferDialog(false);setDisplayTransactionHistoryDialog(false);setDisplayCurrencyDialog(true);}}>
                    <i className="fa-solid fa-chevron-left"></i>
                </div>
            }
            {displayCurrencyDialog && <SectionCurrencyInfoPopupContent currency={currency} setDisplayCurrencyDialog={setDisplayCurrencyDialog} setDisplayTransferDialog={setDisplayTransferDialog} setDisplayTransactionHistoryDialog={setDisplayTransactionHistoryDialog} />}
            {displayTransferDialog && <SectionTransferPopupContent innerTitle="Transfer" />}
            {displayTransactionHistoryDialog && <SectionTransactionHistoryContent currency={currency} />}
        </div>
    )

}

export default SectionTradeCurrencyInfo;