import React from "react";
import './pages_company.css';

const Fee = () => {

    return(
        <div id="main" className="main">
            <div className="allsections allsections-border scrollbarhide pages-company">
                <label className="text">You can trade hundreds of cryptocurrencies on the Spot and Futures markets through Kprice.</label>
                <label className="text">For information on referral commission earnings, see the referral terms page.</label>
                <label className="text">At everyday UTC-00:00AM , system will update user‘s fee level and take effect two hours later.</label>
                <table>
                    <tr className="main-title">
                        <td colSpan="5">Spot Transactions</td>
                    </tr>
                    <tr className="title">
                        <td>Type</td>
                        <td>Requirement Assents</td>
                        <td colSpan="2">30 - Days Cumulative Spot Trading Vol</td>
                        <td>Maker / Taker</td>
                    </tr>
                    <tr>
                        <td>Standart</td>
                        <td>{"<100,000"}</td>
                        <td>or</td>
                        <td>≥ 1,000,000</td>
                        <td>0.003</td>
                    </tr>
                    <tr>
                        <td>VIP1</td>
                        <td>≥ 100,000</td>
                        <td>or</td>
                        <td>≥ 500,000</td>
                        <td>0.001</td>
                    </tr>
                    <tr>
                        <td>VIP2</td>
                        <td>≥ 500,000</td>
                        <td>or</td>
                        <td>≥ 10,000,000</td>
                        <td>0.0005</td>
                    </tr>
                    <tr>
                        <td>VIP3</td>
                        <td>≥ 1,000,000</td>
                        <td>or</td>
                        <td>≥ 25,000,000</td>
                        <td>0.0002</td>
                    </tr>
                </table>
                <label className="text">The related visual shows the classification of account types.</label>
                <label className="text"><i className="fa-solid fa-circle"></i>In the standard account type, a commission of 0.003 is charged for each trade made in the spot market.</label>
                <label className="text"><i className="fa-solid fa-circle"></i>In order to switch to the Vip1 account type, you are expected to have a volume of 100,000 USD above your spot asset or 5,000,000 USD on a monthly basis.</label>
                <label className="text"><i className="fa-solid fa-circle"></i>In Vip1 account type, a commission of 0.001 is charged for each trade made in the spot market.</label>
                <label className="text"><i className="fa-solid fa-circle"></i>In order to switch to the Vip2 account type, you are expected to have a volume of USD 500,000 above your spot asset or USD 10,000,000 on a monthly basis.</label>
                <label className="text"><i className="fa-solid fa-circle"></i>In Vip2 account type, a commission of 0.0005 is charged for each trade made in the spot market.</label>
                <label className="text"><i className="fa-solid fa-circle"></i>In order to switch to Vip3 account type, you are expected to have a volume of 1,000,000 usd above your spot asset or 25,000,000 usd on a monthly basis.</label>
                <label className="text"><i className="fa-solid fa-circle"></i>In Vip3 account type, a commission of 0.0002 is charged for each trade made in the spot market.</label>
                <label className="text"><i className="fa-solid fa-circle"></i>Considering the volume and number of lots created in futures trading positions, 0.03 of the collateral amount used is taken as commission at the time of the transaction.</label>
            </div>
        </div>
    )

}

export default Fee;