import React from "react";
import './pages_company.css';

const SupportDisableAccount = () => {
    
    return(
        <div id="main" className="main">
            <div className="allsections allsections-border scrollbarhide pages-company">
                <label className="title">How Do I Disable My Kprice Account?</label>
                <label className="text">If you notice suspicious activity on your Kprice account, you can disable your account to protect your assets. Please note that once your account has been disabled, you will need to contact Kprice Support to reactivate it. If you accidentally disabled your account, you must wait at least two hours before you can reactivate your account.</label>
                <label className="title">How can I disable my Kprice account from the Kprice App?</label>
                <label className="text"><b>1.</b> Log in to your Kprice App and go to [Account] - [Security].</label>
                <label className="text"><b>2.</b> Read the notes carefully and tap [Disable Account]. Tap [OK] in the confirmation pop-up window.</label>
                <label className="text">To learn more about your Kprice account, please visit the Account Functions FAQ page.</label>
            </div>
        </div>
    )

}

export default SupportDisableAccount;