const verificationOnKeyDown = (e, index, setInfoVerificationCode) => {//verification kodunun girildiği input'lardan birindeyken sağ ok veya sol ok tuşuna basıldığında yandaki input'lara geçiş yapılıyor
    if (e.key === "ArrowRight") {
        if (index < 5) e.target.nextElementSibling.focus();
    } else if (e.key === "ArrowLeft") {
        if (index > 0) e.target.previousElementSibling.focus();
    } else if (e.key === "Backspace") {
        e.preventDefault();
        
        setInfoVerificationCode(prev => {
            const newValues = [...prev];
            newValues[index] = '';
            return newValues;
        });

        // Ve bir önceki input'a odaklan (eğer bu ilk input değilse)
        if (index > 0) e.target.previousElementSibling.focus();
    }
}

const verificationOnChange = (e, index, infoVerificationCode, setInfoVerificationCode, areaId = null) => {//verification kodunun girildiği input'larda bilgi girilince otomatik olarak sağındaki input'a geçiyor
    const value = e.target.value;
    const newValues = [...infoVerificationCode];

    if(value.length == 1){
        // İlk karakteri mevcut inputa yerleştir
        newValues[index] = value.charAt(0) || '';

        // Eğer ekstra karakterler varsa, sonraki inputlara dağıt
        let extraCharacters = value.slice(1);
        let nextIndex = index + 1;

        while (extraCharacters && nextIndex < infoVerificationCode.length) {
            newValues[nextIndex] = extraCharacters.charAt(0);
            extraCharacters = extraCharacters.slice(1);
            nextIndex += 1;
        }

        setInfoVerificationCode(newValues);

        // Otomatik olarak uygun sonraki inputa odaklan
        let nextInput;
        if(areaId){
            nextInput = document.getElementById(areaId).querySelectorAll('input[type="text"]')[nextIndex - extraCharacters.length];
        }
        else{
            nextInput = document.querySelectorAll('input[type="text"]')[nextIndex - extraCharacters.length];
        }

        if (nextInput) {
            nextInput.focus();
        }
    }
    else{
        pasteVerification(e, setInfoVerificationCode);
    }
}

const pasteVerification = (e, setInfoVerificationCode) => {//doğrulama kodu yapıştırılınca tetiklenen fonksiyon
    let pastedData = "";
    if(e && e.clipboardData){
        pastedData = e.clipboardData.getData('text');
    }
    else{
        pastedData = e.target.value;
    }
    
    if(pastedData.length == 6){
        const codes = pastedData.split("");
        setInfoVerificationCode(codes);
        return true;
    }
    else{
        return false;
    }
}

const calcNotificationDate = (date, moment) => {
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const eventDate = moment.tz(date, "YYYY-MM-DD HH:mm", userTimeZone);
    const today = moment().tz(userTimeZone).startOf('day');
    const yesterday = moment().tz(userTimeZone).subtract(1, 'days').startOf('day');

    if(eventDate.isSame(today, 'd')){
        return 'Today';
    }
    else if(eventDate.isSame(yesterday, 'd')){
        return 'Yesterday';
    }
    else{
        return eventDate.format('YYYY-MM-DD, HH:mm');
    }
}

const checkMobileScreen = (resolution) => {
    if (window.innerWidth <= resolution) {
        return true;
    }
    else if (window.innerWidth > resolution) {
        return false;
    }
};

export {
    verificationOnKeyDown,
    verificationOnChange,
    pasteVerification,
    calcNotificationDate,
    checkMobileScreen
};