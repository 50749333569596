import React, { useState, useEffect, useRef, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from 'react-router-dom';
import { AuthContext } from '../components/AuthContext/AuthContext';
import { onChangeSelectedProduct } from "../Reducer/kpriceReducer";
import { TVChartContainer } from "../components/TVChartContainer/index";
import SectionTradeCryptocurrencyList from "./Trade-Sections/section_tradecryptocurrencylist";
import SectionTradeBuySell from "./Trade-Sections/section_tradebuysell";
import SectionTradeOrderHistory from "./Trade-Sections/section_tradeorderhistory";
import SectionTradeMainChartTopTitle from "./Trade-Sections/section_trademaincharttoptitle";
import SectionTradeCurrencyInfo from './Trade-Sections/section_tradecurrencyinfo';
import SectionLastTrades from './Trade-Sections/section_lasttrades';
import Loading from "../components/Global/Loading";
import "./page_trade.css";

const Trade = () => {

  const { isAuthenticated, user, signOut } = useContext(AuthContext);

  const mainArea = useRef(null);
  const productsData = useSelector((state) => state.kprice.products.data);
  const selectedProduct = useSelector((state) => state.kprice.selectedProduct);
  
  const [ohlcIntervalId, setOhlcIntervalId] = useState(null);

  const dispatch = useDispatch();

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  const location = useLocation();
  const query = useQuery();
  const navigate = useNavigate();
  const [currentSelectedProduct, setCurrentSelectedProduct] = useState(query.get("market"));
  const [chartReady, setChartReady] = useState(false);
  const [viewMode, setViewMode] = useState(1);
  const [productActive, setProductActive] = useState(null);

  useEffect(() => {//link değişirse
    const currentMarket = query.get("market");
    if (currentMarket && currentSelectedProduct != currentMarket && selectedProduct != currentMarket) {
      setCurrentSelectedProduct(currentMarket);
    }
  }, [location]);

  useEffect(() => {//seçili product değişirse
    mainArea.current.style.animation = "none";
    let tempForAnimation = mainArea.current.offsetHeight;
    mainArea.current.style.animation = "open-animation 1s";
  }, [selectedProduct]);
  
  useEffect(() => {
    if(currentSelectedProduct && currentSelectedProduct != selectedProduct){
      if(productIsExist(currentSelectedProduct) == 1){
        const newSelectedProduct = currentSelectedProduct;
        const fromPage = "trade";
        setChartReady(false);
        dispatch(onChangeSelectedProduct({ newSelectedProduct, fromPage }));
      }
    }
    
    if (productsData && productIsExist(query.get("market")) == 0){
      if(selectedProduct){
        navigate(`/trade?market=${selectedProduct}`);
        setCurrentSelectedProduct(selectedProduct);
      }
      else{
        const fromPage = "trade";
        const tempProductsDataKeys = productsData ? Object.keys(productsData) : [];
        let newSelectedProduct = null;
        if (tempProductsDataKeys.includes("BTC-USDT")) {
          newSelectedProduct = "BTC-USDT";
        } else {
          newSelectedProduct = tempProductsDataKeys[0];
        }
        setChartReady(false);
        dispatch(onChangeSelectedProduct({ newSelectedProduct, fromPage }));
      }
    }

    if(productIsExist(query.get("market")) == 2){
      navigate(`/${query.get("market") == "" ? "null" : query.get("market")}`);
    }
    else if(productIsExist(query.get("market")) == 1){
      setProductActive(true);
    }
  }, [currentSelectedProduct, selectedProduct, productsData]);

  const productIsExist = (product) => {//seçili product var mı?
    if (productsData && Object.keys(productsData).length > 0) {
      const productsDataKeys = Object.keys(productsData);
      if(productsDataKeys.includes(product)){
        if(productsData[product].status_spot){
          if(productsData[product].status_spot == "active"){
            return 1;
          }
          else{
            return 2;
          }
        }
        else{
          return 1;
        }
      }
      else if(product != null){
        return 2;
      }
      else{
        return 0;
      }
    }
    return 3;
  }

  const [newOrderData, setNewOrderData] = useState(null);
  const handleOrderData = (data) => {
    if(isAuthenticated){
      setNewOrderData(data);
    }
  };

  useEffect(() => {
		const adjustWidgetSize = () => {
			if(window.innerWidth <= 500){
        setViewMode(2);
      }
      else{
        setViewMode(1);
      }
		};

		adjustWidgetSize();

		window.addEventListener('resize', adjustWidgetSize);

		return () => {
			window.removeEventListener('resize', adjustWidgetSize);
		};
	}, []);

  return (
    <main id="main" className="main" ref={mainArea}>
      {productActive == null ? 
        <Loading />
        : 
        (productActive && 
          <>
            <div className="trade-sections-top">
              <div className="trade-sections-top1">
                <div className="allsections allsections-border scrollbarhide section-mainchart">
                  <div className="section-maincharttop">
                    <SectionTradeMainChartTopTitle pageLink="trade" />
                  </div>
                  <div className="section-mainchartbottom" style={chartReady ? undefined : {display : "none"}}>
                    <TVChartContainer chartReady={chartReady} setChartReady={setChartReady} viewMode={viewMode} />
                  </div>
                  <div className="section-mainchartbottom loading" style={chartReady ? {display : "none"} : undefined}>
                    <Loading />
                  </div>
                </div>
                <div className="bottom">
                  <SectionTradeBuySell onOrderDataReceived={handleOrderData} />
                  <SectionTradeCurrencyInfo currency={selectedProduct ? selectedProduct.split("-")[0] : null} />
                  <SectionLastTrades />
                </div>
              </div>
              <div className="trade-sections-top2">
                <SectionTradeCryptocurrencyList pageLink="trade" />
              </div>
            </div>
            {isAuthenticated && 
              <div className="trade-sections-bottom">
                <SectionTradeOrderHistory newOrderFromBuySell={newOrderData} />
              </div>
            }
          </>
        )
      }
    </main>
  );
}

export default Trade;