import { createStore, applyMiddleware } from "redux";
import { rootReducer, rootSaga } from "./Reducer";
import createSagaMiddleware from "redux-saga";
import ReduxThunk from "redux-thunk";

import { composeWithDevTools } from "redux-devtools-extension";


const sagaMiddleware = createSagaMiddleware();
const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(ReduxThunk, sagaMiddleware))
);

sagaMiddleware.run(rootSaga);

export default store;