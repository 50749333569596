import React, { useEffect, useState, useMemo } from "react";
import { useSelector } from "react-redux";
import { getPriceForWalletCurrencies, getPriceForCurrency } from '../../Lib/calculationFunctions';
import { editNumber_reducing_trailing_zeros, editNumber_reducing_trailing_zeros_special, editNumber_reducing_decimal_places, editNumber_increasing_decimal_places, editNumber_separate_with_comma } from '../../Lib/calculationFunctions';
import withLoadingData from "../../Container/withLoadingData";
import Loading from "../../components/Global/Loading";
import isEqual from "react-fast-compare";
import { Dialog } from 'primereact/dialog';
import { Link } from 'react-router-dom';
import SectionTransferPopupContent from '../section_transfer_popup_content';
import SectionTransactionHistoryContent from '../section_transaction_history_content';
import SectionCurrencyInfoPopupContent from '../section_curreny_info_popup_content';
import { MaterialReactTable } from 'material-react-table';

const SectionWalletList = ({isWalletLoading}) => {

    const [walletList, setWalletList] = useState({});//liste verileri
    const currenciesData = useSelector((state) => state.kprice.currencies.data);
    const productsData = useSelector((state) => state.kprice.products.data);
    const productsMiniTickerData = useSelector((state) => state.kprice.productsMiniTicker.data);
    const walletData = useSelector((state) => state.kprice.wallet.data);
    const walletDataKeys = walletData ? Object.keys(walletData) : [];
    const [tableData, setTableData] = useState(null);//tablo verileri

    const [selectedWalletCurrency, setSelectedWalletCurrency] = useState(null);//popup içinde bilgileri gözükecek currency tutuluyor
    const [displayCurrencyDialog, setDisplayCurrencyDialog] = useState(false);//currency popup görünürlüğünü tutuyor
    const [displayTransferDialog, setDisplayTransferDialog] = useState(false);//transfer popup görünürlüğünü tutuyor
    const [displayTransactionHistoryDialog, setDisplayTransactionHistoryDialog] = useState(false);//transaction history popup görünürlüğünü tutuyor

    useEffect(() => {
        const processData = () => {
            const newData = {};

            if(currenciesData && productsMiniTickerData && walletData){
                walletDataKeys.forEach((item) => {
                    if(walletData[item] && walletData[item].count && walletData[item].count != "NaN" && walletData[item].count != "0"){
                        let itemprice = getPriceForWalletCurrencies(item, walletData, productsMiniTickerData);
                        let currentPrice = getPriceForCurrency(item, productsMiniTickerData);
                        newData[item] = {
                            picture_url: currenciesData[item] && currenciesData[item].picture_url ? currenciesData[item].picture_url : undefined,
                            name: currenciesData[item] && currenciesData[item].currency_name ? currenciesData[item].currency_name : "NaN",
                            currency: walletData[item] && walletData[item].currency ? walletData[item].currency : "NaN",
                            count: walletData[item] && walletData[item].count ? walletData[item].count : "NaN",
                            price: itemprice,
                            currentPrice: currentPrice,
                            currentPriceShowPlaces: productsData[`${item}-USDT`] && productsData[`${item}-USDT`].show_places ? productsData[`${item}-USDT`].show_places : "NaN"
                        };
                    }
                });
            }
            
            return newData;
        };
        
        setWalletList(processData());
    }, [currenciesData, productsMiniTickerData, walletData]);

    useEffect(() => {
        const newData = [];

        Object.keys(walletList).forEach((item) => {
            newData.push({
                info: tableData_info(walletList[item]),
                sort: Number(walletList[item].price.toString()),
            });
        })

        setTableData(newData);
    }, [walletList]);

    const columnsData = useMemo(() => [//tablo bilgileri
        { accessorKey: 'info', header: 'Info' },
        { accessorKey: 'sort', header: 'Sort', show: false },
    ], []);

    const tableData_info = (item) => {
        return <div className="section-walletlist-element" onClick={() => {setSelectedWalletCurrency(item.currency);setDisplayCurrencyDialog(true);}}>
                    <div className="section-walletlist-left">
                        <img src={item.picture_url} />
                        <div>
                            <label>{item.name}</label>
                            <span>{item.currency}</span>
                        </div>
                    </div>
                    <div className="section-walletlist-right">
                        <label>{currenciesData && currenciesData[item.currency] && currenciesData[item.currency].show_places && editNumber_separate_with_comma(editNumber_reducing_trailing_zeros_special(editNumber_increasing_decimal_places(editNumber_reducing_decimal_places(item.count, currenciesData[item.currency].show_places), currenciesData[item.currency].show_places)))}</label>
                        <span>{item.price !== "NaN" ? `≈ $ ${editNumber_separate_with_comma(editNumber_increasing_decimal_places(editNumber_reducing_decimal_places(editNumber_reducing_trailing_zeros(item.price.toString()), currenciesData["USDT"].show_places), currenciesData["USDT"].show_places))}` : ''}</span>
                    </div>
                </div>;
    }

    return(
        <div id="section-walletlist" className="allsections allsections-border scrollbarhide section-walletlist">
            <Dialog header="" visible={displayCurrencyDialog} draggable={false} resizable={false} className="section-walletlist-dialog" onHide={() => setDisplayCurrencyDialog(false)}>
                <div className="section-walletlist-dialog-header">
                    <img src={selectedWalletCurrency && walletList && walletList[selectedWalletCurrency].picture_url ? walletList[selectedWalletCurrency].picture_url : undefined} />
                    <label>{selectedWalletCurrency && walletList && walletList[selectedWalletCurrency].currency ? walletList[selectedWalletCurrency].currency : "NaN"}</label>
                    <span>{selectedWalletCurrency && walletList && walletList[selectedWalletCurrency].name ? walletList[selectedWalletCurrency].name : "NaN"}</span>
                </div>
                <SectionCurrencyInfoPopupContent currency={selectedWalletCurrency} setDisplayCurrencyDialog={setDisplayCurrencyDialog} setDisplayTransferDialog={setDisplayTransferDialog} setDisplayTransactionHistoryDialog={setDisplayTransactionHistoryDialog} />
            </Dialog>
            <Dialog header="" visible={displayTransferDialog} draggable={false} resizable={false} className="section-walletlist-dialog transfer" onHide={() => setDisplayTransferDialog(false)}>
                <div className="section-walletlist-dialog-back-to" onClick={() => {setDisplayTransferDialog(false);setDisplayCurrencyDialog(true);}}>
                    <i className="fa-solid fa-chevron-left"></i>
                </div>
                <div className="section-walletlist-dialog-header with-back-to">
                    <img src={selectedWalletCurrency && walletList && walletList[selectedWalletCurrency].picture_url ? walletList[selectedWalletCurrency].picture_url : undefined} />
                    <label>{selectedWalletCurrency && walletList && walletList[selectedWalletCurrency].currency ? walletList[selectedWalletCurrency].currency : "NaN"}</label>
                    <span>{selectedWalletCurrency && walletList && walletList[selectedWalletCurrency].name ? walletList[selectedWalletCurrency].name : "NaN"}</span>
                </div>
                <SectionTransferPopupContent innerTitle="Transfer" />
            </Dialog>
            <Dialog header="" visible={displayTransactionHistoryDialog} draggable={false} resizable={false} className="section-walletlist-dialog transfer" onHide={() => setDisplayTransactionHistoryDialog(false)}>
                <div className="section-walletlist-dialog-back-to" onClick={() => {setDisplayTransactionHistoryDialog(false);setDisplayCurrencyDialog(true);}}>
                    <i className="fa-solid fa-chevron-left"></i>
                </div>
                <div className="section-walletlist-dialog-header with-back-to">
                    <img src={selectedWalletCurrency && walletList && walletList[selectedWalletCurrency].picture_url ? walletList[selectedWalletCurrency].picture_url : undefined} />
                    <label>{selectedWalletCurrency && walletList && walletList[selectedWalletCurrency].currency ? walletList[selectedWalletCurrency].currency : "NaN"}</label>
                    <span>{selectedWalletCurrency && walletList && walletList[selectedWalletCurrency].name ? walletList[selectedWalletCurrency].name : "NaN"}</span>
                </div>
                <SectionTransactionHistoryContent currency={walletList && selectedWalletCurrency && walletList[selectedWalletCurrency] && walletList[selectedWalletCurrency].currency ? walletList[selectedWalletCurrency].currency : null} />
            </Dialog>
            {isWalletLoading != false ? //undefined ya da true geldiyse loading gösteriliyor, false ise grafik
                <Loading /> :
                (Object.keys(walletList).length > 0 ? 
                    <div className="section-walletlist-table">
                        <MaterialReactTable 
                            columns={columnsData} 
                            data={tableData} 
                            enablePagination={false}
                            enableRowVirtualization={true}
                            initialState={{
                                columnVisibility: { sort: false },
                                sorting: [{id: 'sort', desc: true}]
                            }} 
                        />
                    </div> :
                    <div className="no-balance">
                        <i className="fa-solid fa-wallet"></i>
                        <span>No Balance</span>
                        <Link to="/deposit">
                            Deposit
                        </Link>
                    </div>
                )
            }
        </div>
    )
    
}

export default withLoadingData()(React.memo(SectionWalletList, isEqual));