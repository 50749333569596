import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { onPageWithdrawOpen } from "../Reducer/kpriceReducer";
import { Dialog } from 'primereact/dialog';
import Loading from "../components/Global/Loading";
import { getValueWithShowPlaces, getMinAndStepWithShowPlaces, getPriceForCurrency, formatNumberWithCommas, formatNumberWithShowPlaces, valueControlWithDigitLimit } from '../Lib/calculationFunctions';
import { editNumber_reducing_trailing_zeros, editNumber_reducing_trailing_zeros_special, editNumber_reducing_decimal_places, editNumber_increasing_decimal_places, editNumber_separate_with_comma } from '../Lib/calculationFunctions';
import { verificationOnKeyDown, verificationOnChange, pasteVerification } from '../Lib/globalFunctions';
import Decimal from 'decimal.js';
import { toast } from 'react-toastify';
import axios from 'axios';
import { Auth } from 'aws-amplify';
import CountdownTimer from "../components/CountdownTimer/CountdownTimer";
import "./page_withdraw.css";
import moment from "moment-timezone";

const Withdraw = () => {
    
    const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
    const dispatch = useDispatch();

    const location = useLocation();
    const stateCurrency = location.state && location.state.currency ? location.state.currency : "USDT";

    const QuickSearchOptions = ["BTC", "USDT", "USDC", "BNB", "ETH"];
    
    const [coinList, setCoinList] = useState([]);//select coin listesi
    const [coinListSearch, setCoinListSearch] = useState("");//select coin listesindeki search input değeri
    const productsMiniTickerData = useSelector((state) => state.kprice.productsMiniTicker.data);
    const currenciesData = useSelector((state) => state.kprice.currencies.data);
    const walletData = useSelector((state) => state.kprice.wallet.data);
    const withdrawData = useSelector((state) => state.kprice.withdraw.data);
    const userInfo = useSelector((state) => state.kprice.userInfo.data);
    const currencyMaxBeforePlaces = useSelector((state) => state.kprice.currencyMaxBeforePlaces);
    
    const [selectedCoin, setSelectedCoin] = useState(stateCurrency);//select coin değeri
    const [displaySelectCoin, setDisplaySelectCoin] = useState(false);//select coin görünürlüğü
    const [selectedCoinMax, setSelectedCoinMax] = useState(0);//selected coin balance değeri
    const [selectedCoinShowPlaces, setSelectedCoinShowPlaces] = useState(null);//selected coin show places değeri
    const [selectedCoinCurrentPrice, setSelectedCoinCurrentPrice] = useState(null);//selected coin current price değeri

    const [addressValue, setAddressValue] = useState("");//girilen address değeri
    const [addressIsValid, setAddressIsValid] = useState(false);//address değerinin geçerliliği
    const [amountValue, setAmountValue] = useState("");//girilen amount değeri
    const [amountValueError, setAmountValueError] = useState(true);//amount hatası
    const [amountValueError2, setAmountValueError2] = useState(true);//amount hatası
    const [minimumWithdraw, setMinimumWithdraw] = useState(0);//minimumWithdraw kontrol değeri
    const [minimumWithdrawShow, setMinimumWithdrawShow] = useState(0);//minimumWithdraw gösterilen değeri
    const [minimumWithdrawShowUsd, setMinimumWithdrawShowUsd] = useState(0);//minimumWithdraw gösterilen değeri usd

    const [selectedNetwork, setSelectedNetwork] = useState(null);//seçili network değeri
    const [displayNetworkSelect, setDisplayNetworkSelect] = useState(false);//network select popup görünürlüğü
    const [networkSelectPrices, setNetworkSelectPrices] = useState(null);//seçili network değeri
    
    const mainForm = useRef(null);
    const [formType, setFormType] = useState("");
    const [sendRequestLoading, setSendRequestLoading] = useState(false);
    const [confirmRequestLoading, setConfirmRequestLoading] = useState(false);
    const [requestInfo, setRequestInfo] = useState(null);
    const [stayRequestInfo, setStayRequestInfo] = useState(false);

    const [phoneVerificationCode, setPhoneVerificationCode] = useState(['', '', '', '', '', '']);
    const [phoneResendCodeLock, setPhoneResendCodeLock] = useState(false);
    const [phoneResendCodeTimestamp, setPhoneResendCodeTimestamp] = useState("");
    const [emailVerificationCode, setEmailVerificationCode] = useState(['', '', '', '', '', '']);
    const [emailResendCodeLock, setEmailResendCodeLock] = useState(false);
    const [emailResendCodeTimestamp, setEmailResendCodeTimestamp] = useState("");
    const [phoneCodeFocus, setPhoneCodeFocus] = useState(false);
    const [emailCodeFocus, setEmailCodeFocus] = useState(false);
    
    //select coin görünürlüğü dışarıya tıklandığında otomatik kapanması için
    const dropdownRef1 = useRef(null);
    const dropdownRef2 = useRef(null);

    useEffect(() => {//select coin listelenecek seçenekler
        const newData = [];

        if(currenciesData && withdrawData && withdrawData.currency_networks){
            const coins = Object.keys(withdrawData.currency_networks);
            coins.forEach((item) => {
                let itemFind = true;
                let searchItem = "";

                if (coinListSearch != "") {
                    itemFind = false;
                    if(item && currenciesData && currenciesData[item] && currenciesData[item].currency_name){
                        searchItem = `${item} ${currenciesData[item].currency_name}`;
                    }
                    else if(item){
                        searchItem = `${item}`;
                    }
                    else if(currenciesData && item && currenciesData[item] && currenciesData[item].currency_name){
                        searchItem = `${currenciesData[item].currency_name}`;
                    }

                    if(searchItem != "" && searchItem.toString().toLowerCase().includes(coinListSearch.toLowerCase())){
                        itemFind = true;
                    }
                }

                if(itemFind){
                    newData.push(item);
                }
            });
        }
        
        const newDataSorted = newData.sort((a, b) => {
            // Sayısal anahtarları doğru sıralamak için ek bir kontrol
            const numA = parseInt(a, 10);
            const numB = parseInt(b, 10);
          
            if (!isNaN(numA) && !isNaN(numB)) {
              return numA - numB;
            }
          
            return a.localeCompare(b);
        });
        
        setCoinList(newDataSorted);
    }, [currenciesData, withdrawData, coinListSearch]);

    useEffect(() => {//coin için max değeri
        if(walletData[selectedCoin] && walletData[selectedCoin].count){
            setSelectedCoinMax(walletData[selectedCoin].count);
        }
        else{
            setSelectedCoinMax(0);
        }
    }, [walletData, selectedCoin]);

    useEffect(() => {//coin için show places ve current price güncel değerleri
        if(currenciesData[selectedCoin] && currenciesData[selectedCoin].show_places){
            setSelectedCoinShowPlaces(currenciesData[selectedCoin].show_places);
        }
        else{
            setSelectedCoinShowPlaces(null);
        }
        
        setSelectedCoinCurrentPrice(getPriceForCurrency(selectedCoin, productsMiniTickerData));
    }, [currenciesData, productsMiniTickerData, selectedCoin]);

    useEffect(() => {//minimumWithdraw güncelleme
        let minValue = selectedNetwork ? new Decimal(withdrawData.network_types && withdrawData.network_types[selectedNetwork] && withdrawData.network_types[selectedNetwork].min_amount_usd ? withdrawData.network_types[selectedNetwork].min_amount_usd : 1).div(new Decimal(selectedCoinCurrentPrice ? selectedCoinCurrentPrice : 1)).toString() : 0;
        let minValueShow = selectedNetwork ? editNumber_separate_with_comma(editNumber_reducing_trailing_zeros_special(editNumber_increasing_decimal_places(editNumber_reducing_decimal_places((new Decimal(withdrawData.network_types && withdrawData.network_types[selectedNetwork] && withdrawData.network_types[selectedNetwork].min_amount_usd ? withdrawData.network_types[selectedNetwork].min_amount_usd : 1).div(new Decimal(selectedCoinCurrentPrice ? selectedCoinCurrentPrice : 1)).toString()), selectedCoinShowPlaces), selectedCoinShowPlaces))) : 0;
        
        setMinimumWithdraw(minValue);
        setMinimumWithdrawShow(minValueShow);
        setMinimumWithdrawShowUsd(withdrawData.network_types && withdrawData.network_types[selectedNetwork] && withdrawData.network_types[selectedNetwork].min_amount_usd ? withdrawData.network_types[selectedNetwork].min_amount_usd : 1);
    }, [selectedNetwork, withdrawData, selectedCoinCurrentPrice, selectedCoinShowPlaces]);
    
    useEffect(() => {//amount değeri kontrolleri
        const regex = /^\d+(\.\d+)?$/;

        setAmountValueError(false);
        setAmountValueError2(false);

        if(amountValue != ""){
            const isRegexValid = regex.test(amountValue);

            if(Number(amountValue) >= Number(selectedCoinMax)){
                setAmountValue(getValueWithShowPlaces(selectedCoinShowPlaces, selectedCoinMax));
            }
            else{
                if(!isRegexValid || (currencyMaxBeforePlaces != null && !valueControlWithDigitLimit(amountValue, currencyMaxBeforePlaces)) || Number(amountValue) <= 0 || amountValue == "-0" || amountValue == "00"){
                    setAmountValueError2(true);
                }
                else{
                    setAmountValue(getValueWithShowPlaces(selectedCoinShowPlaces, amountValue));
                }
            }

            if(Number(minimumWithdraw) > Number(amountValue)){
                setAmountValueError(true);
            }
        }
    }, [amountValue, selectedCoin, selectedCoinCurrentPrice, selectedCoinShowPlaces, minimumWithdraw, currencyMaxBeforePlaces]);

    useEffect(() => {//network temizleme
        setSelectedNetwork(null);
    }, [selectedCoin]);

    useEffect(() => {//network popup içinde gösterilecek fiyatlar çekiliyor
        let newData = {};
        Object.keys(productsMiniTickerData).forEach((item) => {
            newData[item] = {
                currentPrice: productsMiniTickerData[item] && productsMiniTickerData[item].currentPrice ? productsMiniTickerData[item].currentPrice : 0
            };
        });
        setNetworkSelectPrices(newData);
    }, [productsMiniTickerData]);

    useEffect(() => {//yapıştırma algılama
        const handlePaste = (e) => {
            if(formType == "requestInfo"){
                if(!emailCodeFocus && (phoneVerificationCode.join('') == "" || phoneVerificationCode.join('') == null || !phoneVerificationCode.every(code => code !== '') || phoneCodeFocus)){
                    if(!pasteVerification(e, setPhoneVerificationCode)){
                        toast.error("Verification code must be 6 digits, please check the code you pasted!");
                    }
                }
                else{
                    if(!pasteVerification(e, setEmailVerificationCode)){
                        toast.error("Verification code must be 6 digits, please check the code you pasted!");
                    }
                }
            }
        }

        window.addEventListener('paste', handlePaste);
        return () => {
          window.removeEventListener('paste', handlePaste);
        };
    }, [formType, phoneVerificationCode, emailVerificationCode, phoneCodeFocus, emailCodeFocus]);

    useEffect(() => {//açılış işlemleri ve listener
        dispatch(onPageWithdrawOpen());

        getRequest();

        //select coin görünürlüğü dışarıya tıklandığında otomatik kapanması için
        document.addEventListener('click', hiddenCustomizedSelect);
        return () => {//component kapanırken event listener temizleniyor
          document.removeEventListener('click', hiddenCustomizedSelect);
        };
    }, []);

    useEffect(() => {//formlar arasında geçiş yapılınca tetikleniyor, animasyon sağlıyoruz
        if(mainForm && mainForm.current){
            mainForm.current.style.animation = "none";
            let tempForAnimation = mainForm.current.offsetHeight;
            mainForm.current.style.animation = "open-animation 1.5s";
        }

        if(formType == "requestInfo"){
            setPhoneVerificationCode(['', '', '', '', '', '']);
            setEmailVerificationCode(['', '', '', '', '', '']);
            setCoinListSearch("");
        }
    }, [formType]);

    useEffect(() => {//requestInfo sayacı bitmişse
        if(!stayRequestInfo && formType == "requestInfo"){
            setSelectedCoin(stateCurrency);
            setAddressValue("");
            setAddressIsValid(false);
            setAmountValue("");
            setSelectedNetwork(null);
            setRequestInfo(null);
            setFormType("sendRequest");
        }
    }, [stayRequestInfo]);

    const hiddenCustomizedSelect = (e) => {//select coin görünürlüğü dışarıya tıklandığında otomatik kapanması için
        if (dropdownRef1.current && !dropdownRef1.current.contains(e.target) && dropdownRef2.current && !dropdownRef2.current.contains(e.target)) {
            setDisplaySelectCoin(false);
        }
    };

    const validateAndSetAddress = (element) => {///[^a-zA-Z0-9_.-]/g
        const regex = /^[a-zA-Z0-9_.-]*$/;
        const isValid = regex.test(element.target.value);
    
        if (!isValid || element.target.value == "" || element.target.value.length > 512){
            element.target.classList.add('danger');
            setAddressIsValid(false);
        }
        else{
            element.target.classList.remove('danger');
            setAddressIsValid(true);
        }
        
        setAddressValue(element.target.value);
    };

    const getRequest = async () => {
        const session = await Auth.currentSession();
        const jwtToken = session.getIdToken().getJwtToken();

        try {
            const response = await axios.get(`https://api.kpricemarket.com/withdraw/request`, {
                headers: {
                    'Authorization': `Bearer ${jwtToken}`
                }
            });

            if(response.status == 200){
                if(Object.keys(response.data).length === 0){
                    setFormType("sendRequest");
                }
                else{
                    setRequestInfo(response.data);
                    setFormType("requestInfo");
                    setStayRequestInfo(true);
                    setPhoneResendCodeTimestamp(response.data.phone_last_code_timestamp);
                    setPhoneResendCodeLock(true);
                    setEmailResendCodeTimestamp(response.data.mail_last_code_timestamp);
                    setEmailResendCodeLock(true);
                }
            }
            else{
                toast.error("There was a problem loading the page, please report it!");
            }
        }
        catch (error) {
            toast.error("There was a problem loading the page, please report it!");
        }
    }

    const sendRequest = async () => {
        if(selectedCoin && addressValue && addressIsValid && amountValue && selectedNetwork && !amountValueError && !amountValueError2 && !sendRequestLoading && withdrawData.network_types && withdrawData.network_types[selectedNetwork] && (withdrawData.network_types[selectedNetwork].fee_usd || withdrawData.network_types[selectedNetwork].fee_usd == 0) && userInfo && userInfo.withdraw_permission && userInfo.withdraw_permission == "True"){
            setSendRequestLoading(true);

            let data = {
                "withdraw_type": "crypto_address",
                "currency": selectedCoin,
                "network": selectedNetwork,
                "address": addressValue,
                "count": amountValue
            }

            const session = await Auth.currentSession();
            const jwtToken = session.getIdToken().getJwtToken();
            
            axios.post('https://api.kpricemarket.com/withdraw/request', 
                JSON.stringify(data),
                { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${jwtToken}` } }
            )
            .then(function (response) {
                if(response.status == "200"){
                    setRequestInfo(response.data.data);
                    setFormType("requestInfo");
                    setStayRequestInfo(true);
                    setPhoneResendCodeTimestamp(response.data.data.phone_last_code_timestamp);
                    setPhoneResendCodeLock(true);
                    setEmailResendCodeTimestamp(response.data.data.mail_last_code_timestamp);
                    setEmailResendCodeLock(true);
                }
                else{//hatalı durum için
                    toast.error(`There was a problem sending the withdrawal, please try again!${response.data.message ? ` (${response.data.message})` : ""}`);
                }

                setSendRequestLoading(false);
            })
            .catch(function (error) {
                toast.error(`There was a problem sending the withdrawal, please try again!${error.response.data.error ? ` (${error.response.data.error})` : ""}`);
                setSendRequestLoading(false);
            });
        }
    }

    const resendCode = async (type) => {
        if(formType == "requestInfo" && ((type == "phone" && !phoneResendCodeLock) || (type == "email" && !emailResendCodeLock))){
            let data = {
                "resend_type": type,
            }

            const session = await Auth.currentSession();
            const jwtToken = session.getIdToken().getJwtToken();

            axios.post('https://api.kpricemarket.com/withdraw/request/resend', 
                JSON.stringify(data),
                { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${jwtToken}` } }
            )
            .then(function (response) {

                if(response.status == "200"){
                    if(response.data.resend_type == "phone"){
                        toast.success(`Verification code successfully sent to ${requestInfo.masked_phone_number}, please check!`);
                        setPhoneResendCodeTimestamp(response.data.last_code_timestamp);
                        setPhoneResendCodeLock(true);
                    }
                    else if(response.data.resend_type == "email"){
                        toast.success(`Verification code successfully sent to ${requestInfo.masked_email}, please check!`);
                        setEmailResendCodeTimestamp(response.data.last_code_timestamp);
                        setEmailResendCodeLock(true);
                    }
                }
                else{//hatalı durum için
                    toast.error(`There was a problem sending the verification code, please try again!${response.data.message ? ` (${response.data.message})` : ""}`);
                }
            })
            .catch(function (error) {
                toast.error(`There was a problem sending the verification code, please try again!${error.response.data.error ? ` (${error.response.data.error})` : ""}`);
            });
        }
        else{
            toast.error("The verification code may be sent once every 60 seconds, please try again after a while!");
        }
    }

    const confirmRequest = async () => {
        if(!confirmRequestLoading && requestInfo && phoneVerificationCode.join('') != "" && phoneVerificationCode.join('') != null && phoneVerificationCode.every(code => code !== '') && emailVerificationCode.join('') != "" && emailVerificationCode.join('') != null && emailVerificationCode.every(code => code !== '')){
            setConfirmRequestLoading(true);

            let data = {
                "log_id": requestInfo.log_id,
                "phone_code": phoneVerificationCode.join(''),
                "mail_code": emailVerificationCode.join('')
            }

            const session = await Auth.currentSession();
            const jwtToken = session.getIdToken().getJwtToken();

            axios.post('https://api.kpricemarket.com/withdraw/verification', 
                JSON.stringify(data),
                { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${jwtToken}` } }
            )
            .then(function (response) {

                if(response.status == "200"){
                    toast.success(`${response.data.message}!`);
                    setStayRequestInfo(false);
                }
                else{//hatalı durum için
                    toast.error(`There was a problem validating verification codes, please try again!${response.data.message ? ` (${response.data.message})` : ""}`);
                }

                setConfirmRequestLoading(false);
            })
            .catch(function (error) {
                toast.error(`There was a problem validating verification codes, please try again!${error.response.data.error ? ` (${error.response.data.error})` : ""}`);
                setConfirmRequestLoading(false);
            });
        }
    }
    
    return(
        <div id="main" className="main" ref={mainForm}>
            <div className={formType == "requestInfo" ? "allsections allsections-border scrollbarhide section-withdraw requestInfo" : "allsections allsections-border scrollbarhide section-withdraw"}>
                {formType == "" ? <Loading /> :
                    (formType == "requestInfo" ?
                        <div className="request-info">
                            <div className="info-area">
                                <div className="timer-date">
                                    <div className="timer">
                                        <i className="fa-solid fa-user-clock"></i>
                                        <CountdownTimer timestamp={requestInfo.ttl} addSeconds={0} onTimerEnd={setStayRequestInfo} format="minute" />
                                    </div>
                                    <div className="date">
                                        {moment(Number(requestInfo.event_timestamp)).tz(timeZone).format("YYYY-MM-DD HH:mm")}
                                    </div>
                                </div>
                                <div className="info">
                                    {requestInfo.count} {requestInfo.currency} will be withdrawn from this address
                                </div>
                                <div className="price">
                                    <div>
                                        <label>Net Amount</label>
                                        <span>{requestInfo.net_count} {requestInfo.currency}</span>
                                    </div>
                                    <div>
                                        <label>Fee</label>
                                        <span>{requestInfo.fee_count} {requestInfo.fee_currency}</span>
                                    </div>
                                </div>
                                <div className="network-address">
                                    <label>{requestInfo.network}</label>
                                    <span>{requestInfo.address}</span>
                                </div>
                            </div>
                            <div className="verification-area">
                                <label>Phone Verification</label>
                                <div className="inputs verification" id="withdraw-phone">
                                    {phoneVerificationCode.map((code, index) => (
                                        <input
                                            key={index}
                                            type="text"
                                            placeholder="-"
                                            value={code}
                                            onChange={(e) => verificationOnChange(e, index, phoneVerificationCode, setPhoneVerificationCode, "withdraw-phone")}
                                            onKeyDown={(e) => verificationOnKeyDown(e, index, setPhoneVerificationCode)}
                                            autoComplete="off"
                                            name={`unique-name-${index}`}
                                            onFocus={() => setPhoneCodeFocus(true)}
                                            onBlur={() => setPhoneCodeFocus(false)}
                                        />
                                    ))}
                                </div>
                                <div className="message-area">
                                    <div className={phoneResendCodeLock ? "register locked" : "register"} onClick={() => {if(!phoneResendCodeLock){resendCode("phone")}}}>
                                        <a>Resend Code</a>
                                        {phoneResendCodeLock ? <CountdownTimer timestamp={phoneResendCodeTimestamp} addSeconds={60} onTimerEnd={setPhoneResendCodeLock} format="second" /> : ""}
                                    </div>
                                    <div className="info">
                                        <i className="fa-solid fa-comment"></i>
                                        <span>Verification code sent to {requestInfo.masked_phone_number}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="verification-area">
                                <label>Email Verification</label>
                                <div className="inputs verification" id="withdraw-email">
                                    {emailVerificationCode.map((code, index) => (
                                        <input
                                            key={index}
                                            type="text"
                                            placeholder="-"
                                            value={code}
                                            onChange={(e) => verificationOnChange(e, index, emailVerificationCode, setEmailVerificationCode, "withdraw-email")}
                                            onKeyDown={(e) => verificationOnKeyDown(e, index, setEmailVerificationCode)}
                                            autoComplete="off"
                                            name={`unique-name2-${index}`}
                                            onFocus={() => setEmailCodeFocus(true)}
                                            onBlur={() => setEmailCodeFocus(false)}
                                        />
                                    ))}
                                </div>
                                <div className="message-area">
                                    <div className={emailResendCodeLock ? "register locked" : "register"} onClick={() => {if(!emailResendCodeLock){resendCode("email")}}}>
                                        <a>Resend Code</a>
                                        {emailResendCodeLock ? <CountdownTimer timestamp={emailResendCodeTimestamp} addSeconds={60} onTimerEnd={setEmailResendCodeLock} format="second" /> : ""}
                                    </div>
                                    <div className="info">
                                        <i className="fa-solid fa-envelope"></i>
                                        <span>Verification code sent to {requestInfo.masked_email}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="withdraw-form-element-button">
                                <button type="button" className={!confirmRequestLoading && requestInfo && phoneVerificationCode.join('') != "" && phoneVerificationCode.join('') != null && phoneVerificationCode.every(code => code !== '') && emailVerificationCode.join('') != "" && emailVerificationCode.join('') != null && emailVerificationCode.every(code => code !== '') ? "active" : undefined} onClick={() => confirmRequest()}>{confirmRequestLoading ? <Loading /> : "Confirm Withdraw"}</button>
                            </div>
                        </div>
                        : 
                        <>
                            <Dialog header="Select Network" visible={displayNetworkSelect} draggable={false} resizable={false} className="section-withdraw-dialog" onHide={() => setDisplayNetworkSelect(false)}>
                                <div className="section-withdraw-dialog-body">
                                    <div className="info">
                                        Please note that only supports the token and network you are withdrawing. If you are unsure, kindly check with the receving platform first.
                                    </div>
                                    <div className="elements">
                                        {withdrawData && withdrawData.currency_networks && withdrawData.currency_networks[selectedCoin] && withdrawData.currency_networks[selectedCoin].map((item) => (
                                            <div className={selectedNetwork ? (selectedNetwork == item ? "element active" : "element") : "element"} onClick={() => {setSelectedNetwork(item);setDisplayNetworkSelect(false);}}>
                                                <div className="element-left">
                                                    <span>{withdrawData.network_types && withdrawData.network_types[item] && withdrawData.network_types[item].network_tag ? withdrawData.network_types[item].network_tag : undefined}</span>
                                                    <span>{item}</span>
                                                </div>
                                                <div className="element-right">
                                                    <label>{withdrawData.network_types && withdrawData.network_types[item] && withdrawData.network_types[item].withdraw_arrival_time ? (<>Arrival Time <p>{withdrawData.network_types[item].withdraw_arrival_time}</p></>) : ""}</label>
                                                    <label>Fee <p>$ {withdrawData.network_types && withdrawData.network_types[item] && (withdrawData.network_types[item].fee_usd || withdrawData.network_types[item].fee_usd == 0) ? withdrawData.network_types[item].fee_usd : undefined}</p>{networkSelectPrices[`${selectedCoin}-USDT`] && networkSelectPrices[`${selectedCoin}-USDT`].currentPrice ? <><p>≈ {editNumber_separate_with_comma(editNumber_increasing_decimal_places(editNumber_reducing_decimal_places(editNumber_reducing_trailing_zeros(new Decimal(withdrawData.network_types && withdrawData.network_types[item] && (withdrawData.network_types[item].fee_usd || withdrawData.network_types[item].fee_usd == 0) ? withdrawData.network_types[item].fee_usd : 0).div(new Decimal(networkSelectPrices[`${selectedCoin}-USDT`].currentPrice)).toString()), currenciesData && currenciesData[selectedCoin] && currenciesData[selectedCoin].show_places ? currenciesData[selectedCoin].show_places : null), currenciesData && currenciesData[selectedCoin] && currenciesData[selectedCoin].show_places ? currenciesData[selectedCoin].show_places : null))}</p><span>{selectedCoin}</span></> : ""}</label>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </Dialog>
                            <div className="withdraw-form">
                                <div className="withdraw-form-element">
                                    <div className="withdraw-form-element-title">
                                        Select coin
                                    </div>
                                    <div className="withdraw-form-element-main">
                                        <div className="withdraw-form-element-main-title">Coin</div>
                                        <div className="withdraw-form-element-select" ref={dropdownRef1} onClick={() => {if(displaySelectCoin){setDisplaySelectCoin(false)}else{setDisplaySelectCoin(true)}}}>
                                            {currenciesData ? 
                                                (currenciesData[selectedCoin] ? <div>
                                                    <img src={currenciesData[selectedCoin] && currenciesData[selectedCoin].picture_url ? currenciesData[selectedCoin].picture_url : undefined} />
                                                    <span>{currenciesData[selectedCoin] && currenciesData[selectedCoin].currency ? currenciesData[selectedCoin].currency : "NaN"}</span>
                                                    <label>{currenciesData[selectedCoin] && currenciesData[selectedCoin].currency_name ? currenciesData[selectedCoin].currency_name : "NaN"}</label>
                                                </div> 
                                                : <span className="placeholder">Select Coin</span>)
                                            : <div className="section-withdraw-loading"><Loading /></div>}
                                            <i className="fa-solid fa-caret-up" style={displaySelectCoin ? {} : {display: "none"}}></i>
                                            <i className="fa-solid fa-caret-down" style={displaySelectCoin ? {display: "none"} : {}}></i>
                                        </div>
                                        <div className="withdraw-form-element-select-options" ref={dropdownRef2} style={displaySelectCoin ? {} : {display: "none"}}>
                                            <div className="search-area">
                                                <div>
                                                    <input type="text" className="search" placeholder="Search Coin" value={coinListSearch} onChange={(e) => setCoinListSearch(e.target.value)} />
                                                    <i className="fa-solid fa-magnifying-glass"></i>
                                                </div>
                                                {QuickSearchOptions && coinList && currenciesData && 
                                                    <div className="quick-search">
                                                        {QuickSearchOptions.map((item, index) => (
                                                            currenciesData[item] && 
                                                            <div className={selectedCoin == currenciesData[item].currency ? "item active" : "item"} onClick={() => {setDisplaySelectCoin(false);setSelectedCoin(currenciesData[item].currency);}}>
                                                                <img src={currenciesData[item].picture_url ? currenciesData[item].picture_url : undefined} />
                                                                <span>{currenciesData[item].currency ? currenciesData[item].currency : "NaN"}</span>
                                                            </div>
                                                        ))}
                                                    </div>
                                                }
                                            </div>
                                            <div className="items">
                                                {coinList && currenciesData ? 
                                                    coinList.map((item, index) => (
                                                        currenciesData[item] && 
                                                        <div className={selectedCoin == currenciesData[item].currency ? "item active" : "item"} onClick={() => {setDisplaySelectCoin(false);setSelectedCoin(currenciesData[item].currency);}}>
                                                            <img src={currenciesData[item].picture_url ? currenciesData[item].picture_url : undefined} />
                                                            <span>{currenciesData[item].currency ? currenciesData[item].currency : "NaN"}</span>
                                                            <label>{currenciesData[item].currency_name ? currenciesData[item].currency_name : "NaN"}</label>
                                                        </div>
                                                    ))
                                                : <Loading />}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="withdraw-form-element">
                                    <div className="withdraw-form-element-title">
                                        Send to
                                    </div>
                                    <div className="withdraw-form-element-main">
                                        <div className="withdraw-form-element-main-title">Address</div>
                                        <input type="text" placeholder="Enter Address" value={addressValue} onChange={(event) => validateAndSetAddress(event, event.target.value)} />
                                        <i className="fa-solid fa-newspaper address-icon"></i>
                                        <div className="withdraw-form-element-main-title">Network</div>
                                        <div className="withdraw-form-element-select noimg" onClick={() => setDisplayNetworkSelect(true)}>
                                            {selectedNetwork ? <div><span>{withdrawData.network_types && withdrawData.network_types[selectedNetwork] && withdrawData.network_types[selectedNetwork].network_tag ? withdrawData.network_types[selectedNetwork].network_tag : undefined}</span><label>{selectedNetwork}</label></div> : <span className="placeholder">Select Network</span>}
                                            <i className="fa-solid fa-caret-up" style={displayNetworkSelect ? {} : {display: "none"}}></i>
                                            <i className="fa-solid fa-caret-down" style={displayNetworkSelect ? {display: "none"} : {}}></i>
                                        </div>
                                        <div className="withdraw-form-element-main-title">Amount</div>
                                        <input type="number" placeholder="Enter Amount" className={(amountValueError || amountValueError2) ? "amount danger" : "amount"} step={getMinAndStepWithShowPlaces(selectedCoinShowPlaces)} min={getMinAndStepWithShowPlaces(selectedCoinShowPlaces)} value={amountValue} onChange={(event) => setAmountValue(event.target.value)} />
                                        <div className={selectedCoinMax && amountValue != "" && getValueWithShowPlaces(selectedCoinShowPlaces, selectedCoinMax) == amountValue ? "amount-max-button active" : "amount-max-button"} onClick={() => setAmountValue(selectedCoinMax ? selectedCoinMax : "NaN")}>MAX</div>
                                        <div className="withdraw-form-element-result">
                                            <div className="result-left">
                                                <label>{currenciesData[selectedCoin] && currenciesData[selectedCoin].currency ? currenciesData[selectedCoin].currency : "NaN"} balance</label>
                                                <span>{editNumber_separate_with_comma(editNumber_reducing_trailing_zeros_special(editNumber_increasing_decimal_places(editNumber_reducing_decimal_places(selectedCoinMax, selectedCoinShowPlaces), selectedCoinShowPlaces)))}</span>
                                                <label style={!selectedNetwork ? {visibility: "hidden"} : undefined}>Network fee</label>
                                                <span style={!selectedNetwork ? {visibility: "hidden"} : undefined} className="fee"><p>$ {withdrawData.network_types && withdrawData.network_types[selectedNetwork] && (withdrawData.network_types[selectedNetwork].fee_usd || withdrawData.network_types[selectedNetwork].fee_usd == 0) ? withdrawData.network_types[selectedNetwork].fee_usd : 0}</p><span></span>{selectedNetwork && networkSelectPrices[`${selectedCoin}-USDT`] && networkSelectPrices[`${selectedCoin}-USDT`].currentPrice ? <><p>≈ {editNumber_separate_with_comma(editNumber_reducing_trailing_zeros_special(editNumber_increasing_decimal_places(editNumber_reducing_decimal_places((new Decimal(withdrawData.network_types && withdrawData.network_types[selectedNetwork] && (withdrawData.network_types[selectedNetwork].fee_usd || withdrawData.network_types[selectedNetwork].fee_usd == 0) ? withdrawData.network_types[selectedNetwork].fee_usd : 0).div(new Decimal(networkSelectPrices[`${selectedCoin}-USDT`].currentPrice)).toString()), (currenciesData && currenciesData[selectedCoin] && currenciesData[selectedCoin].show_places ? currenciesData[selectedCoin].show_places : null)), (currenciesData && currenciesData[selectedCoin] && currenciesData[selectedCoin].show_places ? currenciesData[selectedCoin].show_places : null))))}</p><span>{selectedCoin}</span></> : ""}</span>
                                            </div>
                                            <div className="result-right" style={!selectedNetwork ? {visibility: "hidden"} : undefined}>
                                                <label>Minimum withdrawal</label>
                                                <span>$ {minimumWithdrawShowUsd} ≈ {minimumWithdrawShow} {selectedCoin}</span>
                                                <label>Net amount</label>
                                                <span>{amountValue && !amountValueError && !amountValueError2 && networkSelectPrices && withdrawData.network_types && withdrawData.network_types[selectedNetwork] && (withdrawData.network_types[selectedNetwork].fee_usd || withdrawData.network_types[selectedNetwork].fee_usd == 0) ? new Decimal(amountValue).minus(new Decimal(editNumber_reducing_trailing_zeros_special(editNumber_increasing_decimal_places(editNumber_reducing_decimal_places((new Decimal(withdrawData.network_types && withdrawData.network_types[selectedNetwork] && (withdrawData.network_types[selectedNetwork].fee_usd || withdrawData.network_types[selectedNetwork].fee_usd == 0) ? withdrawData.network_types[selectedNetwork].fee_usd : 0).div(new Decimal(selectedCoin && networkSelectPrices[`${selectedCoin}-USDT`] && networkSelectPrices[`${selectedCoin}-USDT`].currentPrice ? networkSelectPrices[`${selectedCoin}-USDT`].currentPrice : 1)).toString()).toString(), (currenciesData && currenciesData[selectedCoin] && currenciesData[selectedCoin].show_places ? currenciesData[selectedCoin].show_places : null)), (currenciesData && currenciesData[selectedCoin] && currenciesData[selectedCoin].show_places ? currenciesData[selectedCoin].show_places : null))))).toString() : <div style={{visibility: "hidden"}}>0</div>}</span>
                                            </div>
                                        </div>
                                        <div className="withdraw-form-element-button">
                                            <button type="button" className={selectedCoin && addressValue && addressIsValid && amountValue && selectedNetwork && !amountValueError && !amountValueError2 && withdrawData.network_types && withdrawData.network_types[selectedNetwork] && (withdrawData.network_types[selectedNetwork].fee_usd || withdrawData.network_types[selectedNetwork].fee_usd == 0) && userInfo && userInfo.withdraw_permission && userInfo.withdraw_permission == "True" ? "active" : undefined} onClick={() => sendRequest()}>{sendRequestLoading ? <Loading /> : "Send Withdraw"}</button>
                                        </div>
                                        {userInfo && userInfo.withdraw_permission && (userInfo.withdraw_permission == "True" ? 
                                        <div className="withdraw-form-element-amount-error" style={(amountValueError || amountValueError2) ? undefined : {display: "none"}}>{amountValueError2 ? "Please enter a valid amount value!" : `Minimum withdrawal amount must be ${minimumWithdraw} ${selectedCoin} or more!`}</div>
                                        : <Link to="/verification" className="withdraw-form-element-amount-error">You must verify to withdraw money!</Link>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </>
                    )
                }
            </div>
        </div>
    )

}

export default Withdraw;