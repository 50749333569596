import React from "react";
import './page_tradingviewinfo.css';

const TradingViewInfo = () => {
    
    return(
        <div id="main" className="main">
            <div className="allsections allsections-border scrollbarhide section-tvinfo">
                <label>If you want to use financial markets effectively, whether you are an individual or a corporation, you can get solutions that fit your budget, as well as insights by following the economic calendar and current news in the markets and taking advantage of its forecasting power.</label>
                <label>Click for more information.</label>
                <a href="https://tradingview.com/widget/" className="more" target="_blank">TradingView</a>
            </div>
        </div>
    )

}

export default TradingViewInfo;