import React from "react";
import SupportContent from "./SupportContent";
import "./page_support.css";

const Support = () => {
    
    return(
        <div id="main" className="main">
            <div className="allsections allsections-border scrollbarhide">
                <SupportContent type="page" />
            </div>
        </div>
    )

}

export default Support;