import React, { useState, useContext } from "react";
import "./page_support.css";
import { AuthContext } from '../components/AuthContext/AuthContext';
import { Link } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import axios from 'axios';
import { toast } from 'react-toastify';
import Loading from "../components/Global/Loading";

const SupportContent = ({ type }) => {

    const [subject, setSubject] = useState("");
    const [subjectDanger, setSubjectDanger] = useState(false);
    const [message, setMessage] = useState("");
    const [messageDanger, setMessageDanger] = useState(false);
    const [submitFormLoading, setSubmitFormLoading] = useState(false);

    const { isAuthenticated, user, signOut } = useContext(AuthContext);

    const patterns = {
        general: /^[^'`"“”‘’«»‹›\<\>;\{\}\$\&\\\%\*\@\!\?\#\^\|\[\]\+=~\/\(\)]*$/,
        no_double_space_or_trim: /^(?! )(?!.*  )(?!.* $).+$/,
        no_other_spaces: /^[^\t\n\r]*$/,
        char_limit_100: /^.{1,100}$/,
        char_limit_1000: /^.{1,1000}$/,
    };

    const validatePattern = (field, fieldLabel) => {//info verification için patternler
        if (field == "") {
            return `Please enter your ${fieldLabel}`;
        }
        else if (!patterns["general"].test(field)) {
            return `Invalid character in ${fieldLabel}`;
        }
        else if (!patterns["no_double_space_or_trim"].test(field)) {
            return `Invalid spacing in ${fieldLabel}`;
        }
        else if (!patterns["no_other_spaces"].test(field)) {
            return `Unexpected spaces in ${fieldLabel}`;
        }
        else if (!patterns["char_limit_100"].test(field) && fieldLabel == "Subject") {
            return `Subject exceeds character limit`;
        }
        else if (!patterns["char_limit_1000"].test(field) && fieldLabel == "Message") {
            return `Message exceeds character limit`;
        }
    };

    const clearAllDanger = () => {
        setSubjectDanger(false);
        setMessageDanger(false);
    }

    const submitForm = async (value, area) => {
        clearAllDanger();
        let tempReturnValue = null;

        if(area == "Submit"){
            let submitControl = true;

            tempReturnValue = validatePattern(subject, "Subject");
            if(tempReturnValue){
                submitControl = false;
                setSubjectDanger(true);
                toast.error(tempReturnValue);
            }

            tempReturnValue = validatePattern(message, "Message");
            if(tempReturnValue){
                submitControl = false;
                setMessageDanger(true);
                toast.error(tempReturnValue);
            }

            if(submitControl){
                setSubmitFormLoading(true);

                let data = {
                    "subject": subject,
                    "message": message,
                }

                const session = await Auth.currentSession();
                const jwtToken = session.getIdToken().getJwtToken();
    
                axios.post('https://api.kpricemarket.com/support', 
                    JSON.stringify(data),
                    { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${jwtToken}` } }
                )
                .then(function (response) {
                    if(response.status == "200"){
                        toast.success("Your support request has been submitted successfully!");
                        setSubject("");
                        setMessage("");
                        setTimeout(() => {
                            clearAllDanger();
                        }, 100);
                    }
                    else{//hatalı durum için
                        toast.error(`There was a problem sending the support request, please try again!${response.data.message ? ` (${response.data.message})` : ""}`);
                    }

                    setSubmitFormLoading(false);
                })
                .catch(function (error) {
                    toast.error(error.response.data.error);
                    setSubmitFormLoading(false);
                });
            }
        }
        else{
            if(area == "Subject"){
                tempReturnValue = validatePattern(value, area);
                if(tempReturnValue){
                    setSubjectDanger(true);
                }
            }
            if(area == "Message"){
                tempReturnValue = validatePattern(value, area);
                if(tempReturnValue){
                    setMessageDanger(true);
                }
            }
        }
    }

    
    return(
        <div className="section-support">
            <div className={`main-form${type == "section" ? " low-padding" : ""}`}>
                {isAuthenticated ? 
                    <>
                        <div className="title">
                            <p>Contact our support team</p>
                        </div>
                        <div className="inputs new-password">
                            <label>Subject</label>
                            <input type="text" placeholder="Subject" className={subjectDanger ? "danger" : undefined} value={subject} onChange={(event) => {setSubject(event.target.value);submitForm(event.target.value, "Subject");}} />
                            <label>Message</label>
                            <textarea placeholder="Message" className={messageDanger ? "danger" : undefined} value={message} onChange={(event) => {setMessage(event.target.value);submitForm(event.target.value, "Message");}}></textarea>
                        </div>
                        <button type="button" onClick={() => submitForm(null, "Submit")} disabled={submitFormLoading}>{submitFormLoading ? <Loading /> : "Submit"}</button>
                    </>
                    : 
                    <>
                        <div className="title">
                            <p>You must log in to create a support request</p>
                        </div>
                        <Link to="/login">Login</Link>
                        <span>If you cannot access your account, you can create a support request at <b>support@kpricemarket.com</b></span>
                    </>
                }
            </div>
        </div>
    )

}

export default SupportContent;