import React from "react";
import './pages_company.css';

const RiskWarning = () => {
    
    return(
        <div id="main" className="main">
            <div className="allsections allsections-border scrollbarhide pages-company">
                <label className="title">General Risk Warning</label>
                <label className="title subtitle">1. How to interpret this Risk Warning</label>
                <label className="text">All terms used in this notice, which are defined in the Kprice User Agreement  (the “User Agreement”), have the same meaning and construction as in the Terms of Use..</label>
                <label className="title subtitle">2. Kprice Services</label>
                <label className="text">This notice provides you with information about the risks associated with Kprice Services. Each Kprice Service has its own distinct risks. This notice provides a general description of the risks when you use Kprice Services.</label>
                <label className="text">This notice does not explain all of the risks or how such risks relate to your personal circumstances. It is important that you fully understand the risks involved before making a decision to use Kprice Services.</label>
                <label className="title subtitle">3. No Tax, Regulatory or Legal Advice</label>
                <label className="text">The taxation of Digital Assets is uncertain, and you are responsible for determining what taxes you might be liable to, and how they apply, when transacting through the Kprice Services. It is your responsibility to report and pay any taxes that may arise from transacting on the Kprice Services, and you acknowledge that Kprice does not provide legal or tax advice relative to these transactions. If you have any doubts about your tax status or obligations when using Kprice Services, or with respect to the Digital Assets held to the credit of your Kprice account, you may wish to seek independent advice.</label>
                <label className="text">You acknowledge that, when, where and as required by applicable legislation, Kprice shall report information regarding your transactions, transfers, distributions or payments to tax or other public authorities. Similarly, when, where and as required by applicable law, Kprice shall withhold taxes related to your transactions, transfers, distributions or payments. Applicable legislation could also prompt Kprice to request you for additional tax information, status, certificates or documentation. You acknowledge that failure to answer these requests within the timeframe defined, may result in withholding taxes by Kprice, to be remitted to tax authorities as defined by relevant law. You are encouraged to seek professional and personal tax advice regarding the above and before making any digital asset transaction.</label>
                <label className="title subtitle">4. Market Risks</label>
                <label className="text">Digital Asset trading is subject to high market risk and price volatility. Changes in value may be significant and may occur rapidly and without warning. Past performance is not a reliable indicator of future performance. The value of an investment and any returns can go down as well as up, and you may not get back the amount you had invested.</label>
                <label className="title subtitle">5. Liquidity risk</label>
                <label className="text">Digital Assets may have limited liquidity which may make it difficult or impossible for you to sell or exit a position when you wish to do so. This may occur at any time, including at times of rapid price movements.</label>
                <label className="text">Highly leveraged positions and large contract sizes mean that traders can incur large losses even in the event of a small movement in the market. You can lose your entire margin on your contract account.</label>
                <label className="title subtitle">6. Fees & Charges</label>
                <label className="text">Our fees and charges are set out here. Kprice may, in its discretion, update the fees & charges from time to time. Please be aware of all costs and charges that apply to you, because such costs and charges will affect the gains you generate from using Kprice Services.</label>
                <label className="title subtitle">7. Availability Risk</label>
                <label className="text">We do not guarantee that the Kprice Services will be available at any particular time or that Kprice Services will not be subject to unplanned service outages or network congestion. It may not be possible for you to buy, sell, store, transfer, send or receive Digital Assets when you wish to do so.</label>
                <label className="text">There are legal requirements in various countries which may restrict the products and services that Kprice can lawfully provide. Accordingly, some products and services and/or certain functionality within the Platform may not be available or may be restricted in certain jurisdictions or regions or to certain Users and any Kprice campaigns, user competitions or other promotions will not be open to (and are not targeted at or intended for) Users to whom restrictions apply. Users are responsible for informing themselves about and observing any restrictions and/or requirements imposed with respect to the access to and use of the Platform and the Kprice Services in each jurisdiction from which the Platform and the Kprice Services are accessed by or on behalf of the User. Kprice reserves the right to change, modify or impose additional restrictions with respect to the access to and use of the Platform and/or the Kprice Services from time to time in its sole discretion without notification.</label>
                <label className="title subtitle">8. Third Party Risk</label>
                <label className="text">Third parties, such as payment providers, custodians, and banking partners may be involved in the provision of Kprice Services. You may be subject to the terms & conditions of these third parties, and Kprice may not be responsible for any loss that these third parties may cause to you.</label>
                <label className="title subtitle">9. Security Risk</label>
                <label className="text">It is not possible for Kprice to eliminate all security risks. You are responsible for keeping your Kprice Account password safe, and you may be responsible for all the transactions under your Kprice Account, whether you authorised them or not. Transactions in Digital Assets may be irreversible, and losses due to fraudulent or unauthorised transactions may not be recoverable.</label>
                <label className="title subtitle">10. Risks related to Digital Assets</label>
                <label className="text">Given the nature of Digital Assets and their underlying technologies, there are a number of intrinsic risks, including but not limited to:</label>
                <label className="text">faults, defects, hacks, exploits, errors, protocol failures or unforeseen circumstances occurring in respect of a Digital Asset or the technologies or economic systems on which the Digital Asset rely;</label>
                <label className="text">transactions in Digital Assets being irreversible. Consequently, losses due to fraudulent or accidental transactions may not be recoverable;</label>
                <label className="text">technological development leading to the obsolescence of a Digital Asset;</label>
                <label className="text">delays causing a transactions not be settled on the scheduled delivery date; and</label>
                <label className="text">attacks on the protocol or technologies on which a Digital Asset depends, including, but not limited to: i. distributed denial of service; ii. sybil attacks; iii. phishing; iv. social engineering; v. hacking; vi. smurfing; vii. malware; viii. double spending; ix. majority-mining, consensus-based or other mining attacks; x. misinformation campaigns; xi. forks; and xii. spoofing.</label>
                <label className="title subtitle">11. Monitoring Risks</label>
                <label className="text">Digital Asset markets are open 24 hours a day, 7 days a week. Rapid price changes may occur at any time, including outside of normal business hours.</label>
                <label className="title subtitle">12. Communication Risks</label>
                <label className="text">When you communicate with us via electronic communication, you should be aware that electronic communications can fail, can be delayed, may not be secure and/or may not reach the intended destination.</label>
                <label className="title subtitle">13. Currency</label>
                <label className="text">Currency exchange fluctuations will impact your gains and losses.</label>
                <label className="title subtitle">14. Legal Risk</label>
                <label className="text">Changes in laws and regulations may materially affect the value of Digital Assets. This risk is unpredictable and may vary from market to market.</label>
            </div>
        </div>
    )

}

export default RiskWarning;