import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { getValueWithShowPlaces, getMinAndStepWithShowPlaces, getPriceForWalletCurrencies, getPriceForCurrency, formatNumberWithCommas, formatNumberWithShowPlaces } from '../Lib/calculationFunctions';
import Loading from "../components/Global/Loading";
import axios from 'axios';
import { Auth } from 'aws-amplify';
import { toast } from 'react-toastify';
import Decimal from 'decimal.js';

const SectionTransferPopupContent = ({ campaignCode, innerTitle }) => {
    
    const currenciesData = useSelector((state) => state.kprice.currencies.data);
    const productsMiniTickerData = useSelector((state) => state.kprice.productsMiniTicker.data);
    const walletData = useSelector((state) => state.kprice.wallet.data);
    const futureProcesses = useSelector((state) => state.kprice.futureProcesses.data);
    const futureProfiles = useSelector((state) => state.kprice.futureProfiles.data);

    const [useCampaignCode, setUseCampaignCode] = useState(campaignCode ? true : false);//kampanya kodu kullanılıyor
    const [campaignIsAvailable, setCampaignIsAvailable] = useState(null);//kampanya kullanılabilir mi
    const [campaignCreditValue, setCampaignCreditValue] = useState(0);//anlık credit değeri
    const [creditDanger, setCreditDanger] = useState(false);//bonus silinme uyarısı

    const [transferSelectFrom, setTransferSelectFrom] = useState("Fiat and Spot");//transfer popup select from değeri
    const [transferSelectTo, setTransferSelectTo] = useState("USDⓈ-M Futures");//transfer popup select to değeri
    const [displayTransferSelectFrom, setDisplayTransferSelectFrom] = useState(false);//transfer popup select from görünürlüğü
    const [displayTransferSelectTo, setDisplayTransferSelectTo] = useState(false);//transfer popup select to görünürlüğü
    const [transferAmountValue, setTransferAmountValue] = useState("");//transfer popup amount değeri
    const showPlacesTransferPrice = currenciesData && currenciesData["USDT"] && currenciesData["USDT"].show_places ? currenciesData["USDT"].show_places : null;
    
    const [USDTWalletForTransfer, setUSDTWalletForTransfer] = useState(null);//transferde gözüken wallet değeri
    const [USDTBalanceForTransfer, setUSDTBalanceForTransfer] = useState(null);//transferde gözüken balance değeri
    const [transferValue, setTransferValue] = useState(null);//transferde gözüken değer
    const [futureProfilesUpdateLocked, setFutureProfilesUpdateLocked] = useState(false);//post sonrası update yapıldıktan sonra true yapılıyor. abonelikteki veri sıfırlanınca false yapılıyor. current price güncellendikçe eski veri ile güncelleme yapılmasını engellemek için

    const [submitTransferLoading, setSubmitTransferLoading] = useState(false);

    //transfer popup içindeki selectlerin dışarıya tıklandığında otomatik kapanması için
    const dropdownRef1 = useRef(null);
    const dropdownRef2 = useRef(null);
    const dropdownRef3 = useRef(null);
    const dropdownRef4 = useRef(null);

    useEffect(() => {//transfer wallet değerinin hesaplanması
        if(futureProfiles && futureProfiles.credit_status && futureProfiles.credit_available){
            if(futureProfiles.credit_status == "active" && futureProfiles.credit_available == "True"){
                setCampaignIsAvailable(true);
            }
            else{
                setCampaignIsAvailable(false);
            }
        }
    }, [futureProfiles]);

    useEffect(() => {//transfer için gösterilecek değer wallet ya da balance
        if(transferSelectFrom == "Fiat and Spot"){
            setTransferValue(USDTWalletForTransfer);
        }
        else{
            setTransferValue(USDTBalanceForTransfer);
        }
    }, [transferSelectFrom, USDTWalletForTransfer, USDTBalanceForTransfer]);

    useEffect(() => {//transfer wallet değerinin hesaplanması
        if(walletData && walletData["USDT"] && walletData["USDT"].count){
            setUSDTWalletForTransfer(walletData["USDT"].count);
        }
        else{
            setUSDTWalletForTransfer(0);
        }
    }, [walletData]);

    useEffect(() => {//transfer balance değerinin hesaplanması
        if(!futureProfilesUpdateLocked){
            if(futureProfiles){
                calcBalance(futureProfiles.balance, null);
            }
            else{
                setUSDTBalanceForTransfer(0);
            }
        }
    }, [futureProcesses, futureProfiles, productsMiniTickerData]);

    useEffect(() => {//abonelikten veri gelince transfer balance değerinin hesaplanma kiliti kaldırıldı
        setFutureProfilesUpdateLocked(false);
    }, [futureProcesses, futureProfiles]);

    const calcBalance = (currentBalance, currentTotalSurety) => {
        let tempTotalBalance = currentBalance ? currentBalance : 0;
        let tempTotalProfit = 0;
        let tempTotalSurety = currentTotalSurety ? currentTotalSurety : 0;
        let tempTotalAssets = 0;
        let tempFreeMargin = 0;
        let calcTotalSurety = currentTotalSurety ? false : true;
        let tempCredit = futureProfiles.credit ? futureProfiles.credit : 0;

        if(futureProcesses && futureProcesses.activeProcesses){
            futureProcesses.activeProcesses.map((item, index) => {
                if(item.future_type == "buy"){
                    tempTotalProfit = new Decimal(tempTotalProfit).plus((new Decimal(productsMiniTickerData[item.product_id].currentPrice).minus(new Decimal(item.entry_price))).times(new Decimal(item.available_count)));
                }
                else if(item.future_type == "sell"){
                    tempTotalProfit = new Decimal(tempTotalProfit).plus((new Decimal(item.entry_price).minus(new Decimal(productsMiniTickerData[item.product_id].currentPrice))).times(new Decimal(item.available_count)));
                }
                
                if(calcTotalSurety){
                    tempTotalSurety = new Decimal(tempTotalSurety).plus(new Decimal(item.available_surety));
                }
            });
        }

        tempTotalAssets = new Decimal(tempTotalBalance).plus(new Decimal(tempTotalProfit));
        tempTotalAssets = tempTotalAssets.plus(new Decimal(tempCredit));
        tempFreeMargin = new Decimal(tempTotalAssets).minus(new Decimal(tempTotalSurety));
        let newValue = tempTotalBalance < tempFreeMargin ? tempTotalBalance : tempFreeMargin.toNumber();

        setUSDTBalanceForTransfer(newValue);
    }

    useEffect(() => {//transfer popup kapanınca içindeki bilgiler temizleniyor
        setDisplayTransferSelectFrom(false);
        setDisplayTransferSelectTo(false);
    }, []);

    useEffect(() => {//transfer input değeri değiştikçe kontrol edilecek
        if(transferAmountValue != ""){
            if(Number(transferAmountValue) >= Number(transferValue)){
                setTransferAmountValue(getValueWithShowPlaces(showPlacesTransferPrice, transferValue));
            }
            else if(transferAmountValue == "00"){
                setTransferAmountValue("0");
            }
            else if(Number(transferAmountValue) < 0 || transferAmountValue == "-0"){
                setTransferAmountValue("");
            }
            else{
                setTransferAmountValue(getValueWithShowPlaces(showPlacesTransferPrice, transferAmountValue));
            }
        }
    }, [transferAmountValue, transferValue, showPlacesTransferPrice]);

    useEffect(() => {//anlık credit değeri hesaplanıyor
        if(transferAmountValue != "" && transferAmountValue != "0"){
            const value = new Decimal(transferAmountValue);
            const creditValue = value.mul(0.20); // Yüzde 20 hesaplanıyor
            setCampaignCreditValue(getValueWithShowPlaces(showPlacesTransferPrice, creditValue));
        }
        else{
            setCampaignCreditValue(0);
        }
    }, [transferAmountValue]);

    useEffect(() => {//bonus silinme uyarısı kontrolü
        if(transferSelectFrom == "USDⓈ-M Futures" && transferSelectTo == "Fiat and Spot" && futureProfiles && futureProfiles.credit_deposit && futureProfiles.balance && futureProfiles.credit_active && futureProfiles.credit_active == "True"){
            if(transferAmountValue != "" && transferAmountValue != "0"){
                const value = new Decimal(transferAmountValue);
                const balance = new Decimal(futureProfiles.balance);
                const creditDeposit = new Decimal(futureProfiles.credit_deposit).toString();
                const newBalance = balance.minus(value).toString();

                if(Number(newBalance) < Number(creditDeposit)){
                    setCreditDanger(true);
                }
                else{
                    setCreditDanger(false);
                }
            }
            else{
                setCreditDanger(false);
            }
        }
        else{
            setCreditDanger(false);
        }
    }, [transferAmountValue, transferSelectFrom, transferSelectTo, futureProfiles]);
    
    useEffect(() => {//transfer popup içindeki selectlerin dışarıya tıklandığında otomatik kapanması için
        document.addEventListener('click', hiddenCustomizedSelect);
        
        return () => {//component kapanırken event listener temizleniyor
          document.removeEventListener('click', hiddenCustomizedSelect);
        };
    }, []);

    const transferConfirm = async () => {

        setSubmitTransferLoading(true);
        const transfer_type = transferSelectFrom == "Fiat and Spot" ? "spot_to_future" : "future_to_spot";
        const currency = "USDT";

        let updateData = {
            transfer_type: transfer_type,
            currency: currency,
            count: transferAmountValue
        }

        if (useCampaignCode) {
            updateData = { ...updateData, campaign_code: campaignCode };
        }

        const session = await Auth.currentSession();
        const jwtToken = session.getIdToken().getJwtToken();

        axios.post('https://api.kpricemarket.com/transfer', 
            JSON.stringify(updateData),
            { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${jwtToken}` } }
        )
        .then(function (response) {
            if(response.status == "200" && response.data.message == "The transfer was successfully"){
                updateValuesAfterTransfer(response.data.data);
                setTransferAmountValue("");
                toast.success("The transfer was successfully!");
            }
            else{
                toast.error(`There was a problem during the transfer!${response.data.message ? ` (${response.data.message})` : ""}`);
            }
            setSubmitTransferLoading(false);
        })
        .catch(function (error) {
            toast.error(`There was a problem during the transfer!${error.response.data.error ? ` (${error.response.data.error})` : ""}`);
            setSubmitTransferLoading(false);
        });

    }

    const updateValuesAfterTransfer = (newData) => {
        if(newData){
            if(newData.currency == "USDT" && newData.total_count){//wallet güncellemesi
                setUSDTWalletForTransfer(newData.total_count);
            }

            if(newData.balance, newData.total_surety){
                setFutureProfilesUpdateLocked(true);
                calcBalance(newData.balance, newData.total_surety);
            }

            if(newData.credit_status && newData.credit_available){
                if(newData.credit_status == "active" && newData.credit_available == "True"){
                    setCampaignIsAvailable(true);
                }
                else{
                    setCampaignIsAvailable(false);
                }
            }
        }
    }

    const hiddenCustomizedSelect = (e) => {//transfer popup içindeki selectlerin dışarıya tıklandığında otomatik kapanması için
        if (dropdownRef1.current && !dropdownRef1.current.contains(e.target) && dropdownRef2.current && !dropdownRef2.current.contains(e.target)) {
            setDisplayTransferSelectFrom(false);
        }

        if (dropdownRef3.current && !dropdownRef3.current.contains(e.target) && dropdownRef4.current && !dropdownRef4.current.contains(e.target)) {
            setDisplayTransferSelectTo(false);
        }
    };

    return(
        <div className="section-walletlist-dialog-transfer">
            {innerTitle ? <div className="inner-title">Transfer</div> : undefined}
            <div className="info">Internal transfers are free on Kprice.</div>
            <div className="transfer1">
                <div className="transfer1-element">
                    <div className="transfer1-element-header">
                        <i className="fa-solid fa-share-from-square"></i>
                        <span>From</span>
                    </div>
                    <div className="transfer1-element-inner" ref={dropdownRef1} onClick={() => {if(displayTransferSelectFrom){setDisplayTransferSelectFrom(false)}else{setDisplayTransferSelectFrom(true)}}}>
                        <span>{transferSelectFrom}</span>
                        <i className="fa-solid fa-caret-up" style={displayTransferSelectFrom ? {} : {display: "none"}}></i>
                        <i className="fa-solid fa-caret-down" style={displayTransferSelectFrom ? {display: "none"} : {}}></i>
                        <div className="transfer1-element-select" ref={dropdownRef2} style={displayTransferSelectFrom ? {} : {display: "none"}}>
                            <span className={transferSelectFrom == "Fiat and Spot" ? "active" : undefined} onClick={() => {setDisplayTransferSelectFrom(false);setTransferSelectFrom("Fiat and Spot");setTransferSelectTo("USDⓈ-M Futures");}}>Fiat and Spot</span>
                            {!useCampaignCode && <span className={transferSelectFrom == "USDⓈ-M Futures" ? "active" : undefined} onClick={() => {setDisplayTransferSelectFrom(false);setTransferSelectFrom("USDⓈ-M Futures");setTransferSelectTo("Fiat and Spot");}}>USDⓈ-M Futures</span>}
                        </div>
                    </div>
                </div>
                <div className="transfer1-element2">
                    <i className="fa-solid fa-arrow-down"></i>
                    {!useCampaignCode && <i className="fa-solid fa-arrows-up-down change" onClick={() => {let tempFrom = transferSelectFrom;setTransferSelectFrom(transferSelectTo);setTransferSelectTo(tempFrom);}}></i>}
                </div>
                <div className="transfer1-element">
                    <div className="transfer1-element-header">
                        <i className="fa-solid fa-location-crosshairs"></i>
                        <span>To</span>
                    </div>
                    <div className="transfer1-element-inner" ref={dropdownRef3} onClick={() => {if(displayTransferSelectTo){setDisplayTransferSelectTo(false)}else{setDisplayTransferSelectTo(true)}}}>
                        <span>{transferSelectTo}</span>
                        <i className="fa-solid fa-caret-up" style={displayTransferSelectTo ? {} : {display: "none"}}></i>
                        <i className="fa-solid fa-caret-down" style={displayTransferSelectTo ? {display: "none"} : {}}></i>
                        <div className="transfer1-element-select" ref={dropdownRef4} style={displayTransferSelectTo ? {} : {display: "none"}}>
                            {!useCampaignCode && <span className={transferSelectTo == "Fiat and Spot" ? "active" : undefined} onClick={() => {setDisplayTransferSelectTo(false);setTransferSelectTo("Fiat and Spot");setTransferSelectFrom("USDⓈ-M Futures");}}>Fiat and Spot</span>}
                            <span className={transferSelectTo == "USDⓈ-M Futures" ? "active" : undefined} onClick={() => {setDisplayTransferSelectTo(false);setTransferSelectTo("USDⓈ-M Futures");setTransferSelectFrom("Fiat and Spot");}}>USDⓈ-M Futures</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="transfer2">
                <label>Coin</label>
                <div className="coin-info">
                    <img src={currenciesData["USDT"] && currenciesData["USDT"].picture_url ? currenciesData["USDT"].picture_url : undefined} />
                    <span className="info1">{currenciesData["USDT"] && currenciesData["USDT"].currency ? currenciesData["USDT"].currency : "NaN"}</span>
                    <span className="info2">{currenciesData["USDT"] && currenciesData["USDT"].currency_name ? currenciesData["USDT"].currency_name : "NaN"}</span>
                </div>
                <div className="amount-title">
                    <label>Amount</label>
                    <div className="amount-info">
                        <div>
                            <div>{transferValue ? formatNumberWithCommas(formatNumberWithShowPlaces(transferValue, currenciesData["USDT"].show_places)) : "0"}</div><span>available /</span>
                        </div>
                        <div>
                            <div>{transferAmountValue != "" && currenciesData && currenciesData["USDT"] && currenciesData["USDT"].show_places ? formatNumberWithCommas(formatNumberWithShowPlaces(transferAmountValue, currenciesData["USDT"].show_places)) : (currenciesData && currenciesData["USDT"] && currenciesData["USDT"].show_places ? formatNumberWithShowPlaces(0, currenciesData["USDT"].show_places) : "0")}</div><span>in order</span>
                        </div>
                    </div>
                </div>
                <input type="number" step={getMinAndStepWithShowPlaces(showPlacesTransferPrice)} min={getMinAndStepWithShowPlaces(showPlacesTransferPrice)} value={transferAmountValue} onChange={(event) => {setTransferAmountValue(event.target.value);}} />
                <div className={transferValue && transferAmountValue != "" && getValueWithShowPlaces(showPlacesTransferPrice, transferValue) == transferAmountValue ? "amount-max-button active" : "amount-max-button"} onClick={() => setTransferAmountValue(transferValue ? transferValue : "")}>MAX</div>
                {useCampaignCode && <div className="credit-info">
                    <label>Credit</label>
                    <div className="coin-info">
                        {campaignCreditValue}
                    </div>
                </div>}
                <div className={(useCampaignCode && campaignIsAvailable != null) || creditDanger ? "confirm-button campaign" : "confirm-button"}>
                    {useCampaignCode && campaignIsAvailable != null && <div className={campaignIsAvailable ? "campaign-info success" : "campaign-info danger"}>{campaignIsAvailable ? `If you confirm the ${campaignCode} campaign, you will have accepted all the terms` : `You cannot benefit from the ${campaignCode} campaign`}</div>}
                    {creditDanger && <div className="campaign-info danger">If you confirm this transaction, your bonus will be deleted</div>}
                    <button type="button" className={((useCampaignCode && campaignIsAvailable) || !useCampaignCode) && transferAmountValue != "" && transferAmountValue != "0" && !submitTransferLoading ? "active" : undefined} disabled={((useCampaignCode && campaignIsAvailable) || !useCampaignCode) && transferAmountValue != "" && transferAmountValue != "0" && !submitTransferLoading ? false : true} onClick={() => transferConfirm()}>{submitTransferLoading ? <Loading /> : "Confirm"}</button>
                </div>
            </div>
        </div>
    )
    
}

export default SectionTransferPopupContent;