import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import "./page_verification.css";
import { toast } from 'react-toastify';
import axios from 'axios';
import { Auth } from 'aws-amplify';
import { verificationOnKeyDown, verificationOnChange, pasteVerification } from '../Lib/globalFunctions';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { PhoneInput } from 'react-international-phone';
import CountryPhoneCodePicker from "../components/CountryPhoneCodePicker";
import 'react-international-phone/style.css';
import Loading from "../components/Global/Loading";
import CountdownTimer from "../components/CountdownTimer/CountdownTimer";
import { Link } from 'react-router-dom';

const ChangePassword = () => {

    const userInfo = useSelector((state) => state.kprice.userInfo.data);
    const mainForm = useRef(null);

    const phoneUtil = PhoneNumberUtil.getInstance();
    const [phoneControlCount, setPhoneControlCount] = useState(0);
    const infoPhoneRef = useRef(null);
    const [phoneNumber, setPhoneNumber] = useState("");
    const [selectedPhoneCountry, setSelectedPhoneCountry] = useState("US");
    const [selectedPhoneCountryCode, setSelectedPhoneCountryCode] = useState("");
    const [resendCodeLock, setResendCodeLock] = useState(false);
    const [resendCodeTimestamp, setResendCodeTimestamp] = useState("");
    const [formNumber, setFormNumber] = useState(0);
    const [viewType, setViewType] = useState("pc");

    const [infoVerificationCode, setInfoVerificationCode] = useState(['', '', '', '', '', '']);
    
    const [phoneVerification, setPhoneVerification] = useState(null);

    const [submitPhoneVerificationLoading, setSubmitPhoneVerificationLoading] = useState(false);
    const [submitPhoneVerifyLoading, setSubmitPhoneVerifyLoading] = useState(false);

    const clearalldanger = () => {//tüm formlardaki hata belirten kırmızı çerçeveleri temizlemek için
        if(document.getElementsByClassName("main-form")[0]){
            let elements1 = document.getElementsByClassName("main-form")[0].getElementsByTagName("input");
            for (let i = 0; i < elements1.length; i++) {
                elements1[i].classList.remove("danger");
            }

            let elements2 = document.getElementsByClassName("main-form")[0].getElementsByTagName("textarea");
            for (let i = 0; i < elements2.length; i++) {
                elements2[i].classList.remove("danger");
            }

            let elements3 = document.getElementsByClassName("main-form")[0].getElementsByTagName("button");
            for (let i = 0; i < elements3.length; i++) {
                elements3[i].classList.remove("danger");
            }

            let elements4 = document.getElementsByClassName("main-form")[0].getElementsByClassName("fake-input");
            for (let i = 0; i < elements4.length; i++) {
                elements4[i].classList.remove("danger");
            }
        }
    }
    
    const isPhoneValid = (phone) => {
        try {
            return phoneUtil.isValidNumber(phoneUtil.parse(phone));
        } catch (error) {
            return false;
        }
    };

    const validateAndUpdatePhoneNumber = () => {
        if(infoPhoneRef && infoPhoneRef.current){
          const onlyNumbers = infoPhoneRef.current.value.replace(/[^0-9]/g, '');
          infoPhoneRef.current.value = onlyNumbers;
        }
    };

    const submitPhone = async (post) => {
        clearalldanger();
        
        if(phoneControlCount > 1 && infoPhoneRef && infoPhoneRef.current){
            validateAndUpdatePhoneNumber();
            if(isPhoneValid(`${selectedPhoneCountryCode}${infoPhoneRef.current.value}`)){
                setPhoneNumber(`${selectedPhoneCountryCode}${infoPhoneRef.current.value}`);
                if(post){
                    setSubmitPhoneVerificationLoading(true);

                    let data = {
                        "phone_number": phoneNumber,
                        "code_type": "new"
                    }

                    const session = await Auth.currentSession();
                    const jwtToken = session.getIdToken().getJwtToken();
        
                    axios.post('https://api.kpricemarket.com/user/phone-update', 
                        JSON.stringify(data),
                        { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${jwtToken}` } }
                    )
                    .then(function (response) {
                        if(response.status == "200"){
                            setFormNumber(1);
                            setResendCodeTimestamp(response.data.last_code_timestamp);
                            setResendCodeLock(true);
                        }
                        else{//hatalı durum için
                            toast.error(`There was a problem sending the verification code, please try again!${response.data.message ? ` (${response.data.message})` : ""}`);
                        }

                        setSubmitPhoneVerificationLoading(false);
                    })
                    .catch(function (error) {
                        toast.error(error.response.data.error);
                        setSubmitPhoneVerificationLoading(false);
                    });
                    
                }
            }
            else{
                infoPhoneRef.current.classList.add("danger");
                if(post){
                    toast.error("Please enter a valid phone number!");
                }
            }
        }
        else{
            setPhoneControlCount(phoneControlCount + 1);
        }
    }

    const resendCode = async () => {
        if(!resendCodeLock){
            let data = {
                "phone_number": phoneNumber,
                "code_type": "resend"
            }

            const session = await Auth.currentSession();
            const jwtToken = session.getIdToken().getJwtToken();

            axios.post('https://api.kpricemarket.com/user/phone-update', 
                JSON.stringify(data),
                { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${jwtToken}` } }
            )
            .then(function (response) {

                if(response.status == "200"){
                    toast.success("Verification code has been sent successfully, please check!");
                    setResendCodeTimestamp(response.data.last_code_timestamp);
                    setResendCodeLock(true);
                }
                else{//hatalı durum için
                    toast.error(`There was a problem sending the verification code, please try again!${response.data.message ? ` (${response.data.message})` : ""}`);
                }
            })
            .catch(function (error) {
                toast.error(error.response.data.error);
            });
        }
        else{
            toast.error("The verification code may be sent once every 60 seconds, please try again after a while!");
        }
    }

    const verifyPhone = async () => {
        if(infoVerificationCode.join('') != "" && infoVerificationCode.join('') != null && infoVerificationCode.every(code => code !== '')){
            setSubmitPhoneVerifyLoading(true);

            let data = {
                "phone_number": phoneNumber,
                "confirm_code": infoVerificationCode.join('')
            }

            const session = await Auth.currentSession();
            const jwtToken = session.getIdToken().getJwtToken();

            axios.post('https://api.kpricemarket.com/user/phone-verification', 
                JSON.stringify(data),
                { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${jwtToken}` } }
            )
            .then(function (response) {
                if(response.status == "200"){
                    toast.success("Phone number changed successfully!");
                }
                else{//hatalı durum için
                    toast.error(`There was a problem verifying the phone number!${response.data.message ? ` (${response.data.message})` : ""}`);
                }

                setSubmitPhoneVerifyLoading(false);
            })
            .catch(function (error) {
                toast.error(error.response.data.error);
                setSubmitPhoneVerifyLoading(false);
            });
        }
        else{
            let codeElements = document.getElementsByClassName("main-form")[0].getElementsByClassName("verification")[0].getElementsByTagName("input");
            for (let i = 0; i < codeElements.length; i++) {
                if(codeElements[i].value == ""){
                    codeElements[i].classList.remove("danger");
                }
            }
            toast.error("Please enter the 6-digit verification code!");
        }
    }

    useEffect(() => {
        submitPhone(false);
    }, [selectedPhoneCountryCode]);

    useEffect(() => {
        if(userInfo && userInfo.customer_id){
            let temp_phone_verification = userInfo.phone_verification ? userInfo.phone_verification : "";
            setPhoneVerification(temp_phone_verification);
            setSelectedPhoneCountry(userInfo.residential_country ? userInfo.residential_country : "US");
        }
        else{
            setSelectedPhoneCountry("US");
        }
    }, [userInfo]);

    useEffect(() => {
        if(mainForm && mainForm.current){
            mainForm.current.style.animation = "none";
            let tempForAnimation = mainForm.current.offsetHeight;
            mainForm.current.style.animation = "open-animation 1.5s";
        }

        const handlePaste = (e) => {
            if(formNumber == 1){
                if(!pasteVerification(e, setInfoVerificationCode)){
                    toast.error("Verification code must be 6 digits, please check the code you pasted!");
                }
            }
        }
        window.addEventListener('paste', handlePaste);
        return () => window.removeEventListener('paste', handlePaste);
    }, [formNumber]);

    useEffect(() => {//listener için
        const checkScreenWidth = () => {
            if(window.innerWidth <= 500){
                setViewType("mobile");
            }
            else{
                setViewType("pc");
            }
        }

        checkScreenWidth();
        
        window.addEventListener('resize', checkScreenWidth);
        return () => {//component kapanırken event listener temizleniyor
          window.removeEventListener('resize', checkScreenWidth);
        };
    }, []);
    
    return(
        <div id="main" className="main">
            <div className="allsections allsections-border scrollbarhide section-verification">
                <div className="section-verification-steps">
                    <div className="main-form change-phone" ref={mainForm}>
                        {phoneVerification == null ? <Loading /> : 
                            (formNumber == 0 ?
                                (phoneVerification == "approved" ? 
                                    <>
                                        <div className="title">
                                            <p>Please enter your phone number!</p>
                                        </div>
                                        <div className="inputs">
                                            <label>Phone Number</label>
                                            <div className="phone-number-area">
                                                <CountryPhoneCodePicker
                                                    selectedCountry={selectedPhoneCountry}
                                                    setSelectedCountry={(code) => setSelectedPhoneCountry(code)}
                                                    selectedCountryPhoneCode={selectedPhoneCountryCode}
                                                    setSelectedCountryPhoneCode={(code) => setSelectedPhoneCountryCode(code)}
                                                />
                                                <span>{selectedPhoneCountryCode}</span>
                                                <input type="text" ref={infoPhoneRef} onChange={() => submitPhone(false)} style={{paddingLeft: selectedPhoneCountryCode ? ((selectedPhoneCountryCode.length * (viewType == "pc" ? 10 : 6)) + 20) : 20}} />
                                            </div>
                                        </div>
                                        <button type="button" className="submit-button" onClick={() => submitPhone(true)} disabled={submitPhoneVerificationLoading}>{submitPhoneVerificationLoading ? <Loading /> : "Change Phone"}</button>
                                    </>
                                    : <>
                                        <div className="title">
                                            <p>To change your phone number, you must first complete the phone verification step.</p>
                                        </div>
                                        <div className="button-area">
                                            <Link to="/verification/steps" className="submit-button">Go Verification</Link>
                                        </div>
                                    </>
                                )
                                : (formNumber == 1 && phoneVerification == "approved" && 
                                    <>
                                        <div className="title">
                                            <p>Please check your phone for the verification code!</p>
                                        </div>
                                        <div className="inputs verification">
                                            {infoVerificationCode.map((code, index) => (
                                                <input
                                                    key={index}
                                                    type="text"
                                                    placeholder="-"
                                                    value={code}
                                                    onChange={(e) => verificationOnChange(e, index, infoVerificationCode, setInfoVerificationCode)}
                                                    onKeyDown={(e) => verificationOnKeyDown(e, index, setInfoVerificationCode)}
                                                    autoComplete="off"
                                                    name={`unique-name-${index}`}
                                                />
                                            ))}
                                        </div>
                                        <button type="button" className="submit-button" onClick={() => verifyPhone()} disabled={submitPhoneVerifyLoading}>{submitPhoneVerifyLoading ? <Loading /> : "Verify"}</button>
                                        <div className="register" onClick={() => {if(!resendCodeLock){resendCode()}}}>
                                            <a className={resendCodeLock ? "locked" : undefined}>Resend Code</a>
                                            {resendCodeLock ? <CountdownTimer timestamp={resendCodeTimestamp} addSeconds={60} onTimerEnd={setResendCodeLock} format="second" /> : ""}
                                        </div>
                                    </>
                                )
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    )

}

export default ChangePassword;