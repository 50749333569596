import { useState, useEffect } from 'react';

const useCamera = (cameraFormActive) => { // cameraFormActive parametresi eklendi
  const [stream, setStream] = useState(null);
  const [cameras, setCameras] = useState([]);
  const [currentCamera, setCurrentCamera] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const getCameras = async () => {
      try {
        await navigator.mediaDevices.getUserMedia({ video: true });
        const devices = await navigator.mediaDevices.enumerateDevices();
        const videoDevices = devices.filter(device => device.kind === 'videoinput');
        setCameras(videoDevices);
        if (videoDevices.length > 0) {
          setCurrentCamera(videoDevices[0]);
          const newStream = await navigator.mediaDevices.getUserMedia({ video: { deviceId: videoDevices[0].deviceId } });
          setStream(newStream);
          setError(null);
        }
      } catch (error) {
        setError('Kameraya erişim engellenmiş. Lütfen tarayıcı ve cihaz ayarlarınızdan kameraya erişim izni veriniz. Erişim izni verdiğiniz halde kamera gözükmüyorsa sayfayı yenileyiniz.');
      }
    };

    if (cameraFormActive) {
      getCameras();
    } else {
      if (stream) {
        stream.getTracks().forEach(track => track.stop());
        setStream(null); // Akışı null olarak ayarla, çünkü artık aktif bir akış yok
      }
    }
    
    // Cihaz değişikliklerini dinle
    navigator.mediaDevices.ondevicechange = () => {
      if (cameraFormActive) { // cameraFormActive kontrolü
        getCameras(); // Cihaz değişikliği olduğunda kameraları yeniden sorgula
      }
    };

    return () => {
      if (stream) {
        stream.getTracks().forEach(track => track.stop());
      }
      navigator.mediaDevices.ondevicechange = null; // Temizlik
    };
  }, [cameraFormActive]); // cameraFormActive'yi useEffect hook'unun bağımlılıklarına ekleyin

  const switchCamera = async (cameraId) => {
    if (!cameraFormActive) return; // cameraFormActive kontrolü

    if (stream) {
      stream.getTracks().forEach(track => track.stop());
    }
    const newStream = await navigator.mediaDevices.getUserMedia({ video: { deviceId: cameraId } });
    setStream(newStream);
    setCurrentCamera(cameras.find(camera => camera.deviceId === cameraId));
  };

  const takePhotoOld = () => {
    if (!cameraFormActive) return Promise.reject(new Error('Camera form is not active')); // cameraFormActive kontrolü

    if (stream) {
      const track = stream.getVideoTracks()[0];
      const imageCapture = new ImageCapture(track);
      return imageCapture.takePhoto();
    }
    return Promise.reject(new Error('No camera stream available'));
  };

  const takePhoto = (videoElement) => {
    if (!videoElement) return Promise.reject(new Error('Video element is not available'));
  
    const canvas = document.createElement('canvas');
    canvas.width = videoElement.videoWidth;
    canvas.height = videoElement.videoHeight;
  
    const context = canvas.getContext('2d');
    context.drawImage(videoElement, 0, 0, canvas.width, canvas.height);
  
    return new Promise((resolve, reject) => {
      canvas.toBlob(blob => {
        if (blob) {
          resolve(blob);
        } else {
          reject(new Error('Failed to capture photo'));
        }
      }, 'image/jpeg');
    });
  };

  return { stream, cameras, currentCamera, switchCamera, takePhoto, error };
};

export default useCamera;
