import React, { useContext } from "react";
import './pages_company.css';
import { AuthContext } from '../components/AuthContext/AuthContext';
import { Link } from 'react-router-dom';

const ReferenceRules = () => {

    const { isAuthenticated, user, signOut } = useContext(AuthContext);

    return(
        <div id="main" className="main">
            <div className="allsections allsections-border scrollbarhide pages-company">
                <label className="title">Invite your friends, earn together.</label>
                <label className="text">Earn 10% commission on every spot trade your referrals make on Kprice.</label>
                <label className="title subtitle bonus-step-title">How to invite your friends?</label>
                <div className="bonus-step">
                    <div className="item">
                        <div className="item-border">
                            <span>1</span>
                            <i className="fa-solid fa-envelope-open-text"></i>
                        </div>
                        <div className="item-info text">Invite your friends to become a member with a referral code. Earn every time a trade is executed.</div>
                    </div>
                    <div className="item">
                        <div className="item-border">
                            <span>2</span>
                            <i className="fa-solid fa-coins"></i>
                        </div>
                        <div className="item-info text">Earn 10% commission instantly when your friends make trades.</div>
                    </div>
                </div>
                <label className="title subtitle">Invite Now</label>
                <label className="text">
                    You can share the shared reference code directly with your friends.
                    <br />
                    <b>-</b>
                </label>
                <label className="title subtitle">How can I benefit from the Referral Campaign?</label>
                <label className="text">To benefit from the referral rewards, you need to create an account and confirm your identity.</label>
                <label className="text">Share your invitation code given to you through the referral campaign with your friends.</label>
                <label className="text">As soon as your friends open an account on Kprice and start trading, your reward will be credited to your account in the traded currency.</label>
                <label className="text">
                    For example;
                    <br />
                    User A invites user B.
                    <br />
                    User B opens an account and starts trading.
                    <br />
                    For each spot trading transaction of user B, 10% of the commission amount is credited to user A's account.
                </label>
                <label className="title subtitle">Terms and Conditions</label>
                <label className="text"><i className="fa-solid fa-circle"></i>All referral commissions (both those received by the inviting users and those shared with their invited friends) are calculated in real time and transferred to the relevant Kprice account every hour.</label>
                <label className="text"><i className="fa-solid fa-circle"></i>Payouts of referral commissions are made in the same asset in which the invited user paid the trading commission</label>
                <label className="text"><i className="fa-solid fa-circle"></i>Spot commission rates for users invited and registered through a referral link will be based on the "default" rate specified in the referral link.</label>
                <label className="text"><i className="fa-solid fa-circle"></i>Kprice reserves the right to disqualify users participating in the Kprice Referral Program who engage in dishonest or abusive activities to earn commissions and to cancel their commissions. These activities include mass account registrations to receive extra bonuses, the use of "phishing" websites containing referral links, the posting of referral links as advertisements in search engines, and any other activity that is illegal, abusive or fraudulent.</label>
                <label className="text"><i className="fa-solid fa-circle"></i>Kprice reserves the right to cancel or modify any Program and the Referral Program Rules at its sole discretion.</label>
                <label className="text"><i className="fa-solid fa-circle"></i>All users are required to strictly abide by the Kprice User agreement. Any breach of the Kprice Terms of Use will prevent the user from earning Referral Commissions.</label>
                <label className="text"><i className="fa-solid fa-circle"></i>Kprice has the sole discretion to decide and determine whether a user will be entitled to any commissions and reserves the right to change these Terms and Conditions from time to time.</label>
                {!isAuthenticated && 
                <div className="button">
                    <button className="join not-authenticated">
                        <Link to="/register">Register</Link>
                    </button>
                </div>}
            </div>
        </div>
    )

}

export default ReferenceRules;