import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import "./page_verification.css";
import { toast } from 'react-toastify';
import axios from 'axios';
import { Auth } from 'aws-amplify';
import { verificationOnKeyDown, verificationOnChange, pasteVerification } from '../Lib/globalFunctions';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import CountryPicker from "../components/CountryPicker";
import CountryPhoneCodePicker from "../components/CountryPhoneCodePicker";
import Loading from "../components/Global/Loading";
import useCamera from "./useCamera";
import CountdownTimer from "../components/CountdownTimer/CountdownTimer";

import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Check from '@mui/icons-material/Check';
import Step1Icon from '@mui/icons-material/EmailRounded';
import Step2Icon from '@mui/icons-material/DescriptionRounded';
import Step3Icon from '@mui/icons-material/PhotoLibraryRounded';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';

const VerificationSteps = ({ pageType }) => {

    const userInfo = useSelector((state) => state.kprice.userInfo.data);
    const maxFileSize = 8 * 1024 * 1024;
    const [activeStep2, setActiveStep2] = useState(0);
    const [activeStep3, setActiveStep3] = useState(0);
    const [formType, setFormType] = useState("");
    const [formNumber, setFormNumber] = useState("");
    const [formNumber1ClassName, setFormNumber1ClassName] = useState("");
    const [formNumber2ClassName, setFormNumber2ClassName] = useState("");
    const [formNumberInner, setFormNumberInner] = useState("");
    const [formNumberInner1ClassName, setFormNumberInner1ClassName] = useState("");
    const [formNumberInner2ClassName, setFormNumberInner2ClassName] = useState("");
    const [formNumberInner3ClassName, setFormNumberInner3ClassName] = useState("");
    const [formNumberInner4ClassName, setFormNumberInner4ClassName] = useState("");
    const [formNumberSpacer, setFormNumberSpacer] = useState("");
    const [formNumberInnerSpacer1, setFormNumberInnerSpacer1] = useState("");
    const [formNumberInnerSpacer2, setFormNumberInnerSpacer2] = useState("");
    const [formNumberInnerSpacer3, setFormNumberInnerSpacer3] = useState("");
    const mainForm = useRef(null);
    const mainFormOuter = useRef(null);
    const [viewType, setViewType] = useState("pc");

    const phoneUtil = PhoneNumberUtil.getInstance();
    const [phoneControlCount, setPhoneControlCount] = useState(0);
    const infoPhoneRef = useRef(null);
    const [phoneNumber, setPhoneNumber] = useState("");
    const [selectedPhoneCountry, setSelectedPhoneCountry] = useState("US");
    const [selectedPhoneCountryCode, setSelectedPhoneCountryCode] = useState("");
    const [resendCodeLock, setResendCodeLock] = useState(false);
    const [resendCodeTimestamp, setResendCodeTimestamp] = useState("");

    const [infoVerificationCode, setInfoVerificationCode] = useState(['', '', '', '', '', '']);

    const patterns = {
        general: /^[^'`"“”‘’«»‹›\<\>;\{\}\$\&\\\%\*\@\!\?\#\^\|\[\]\+=~\/\(\)]*$/,
        no_double_space_or_trim: /^(?! )(?!.*  )(?!.* $).+$/,
        no_other_spaces: /^[^\t\n\r]*$/,
        char_limit_256: /^.{1,256}$/,
        char_limit_512: /^.{1,512}$/,
        identity_number: /^[0-9A-Za-z][0-9A-Za-z /-]{0,18}[0-9A-Za-z]$/,
        date_of_birth: /^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/,
        postal_code: /^[0-9A-Za-z- ]{1,16}$/
    };
    const currentYear = new Date().getFullYear() - 1;
    const countries = {
        AF: "Afghanistan",
        AL: "Albania",
        DZ: "Algeria",
        AS: "American Samoa",
        AD: "Andorra",
        AO: "Angola",
        AI: "Anguilla",
        AG: "Antigua and Barbuda",
        AR: "Argentina",
        AM: "Armenia",
        AW: "Aruba",
        AU: "Australia",
        AT: "Austria",
        AZ: "Azerbaijan",
        BS: "Bahamas",
        BH: "Bahrain",
        BD: "Bangladesh",
        BB: "Barbados",
        BY: "Belarus",
        BE: "Belgium",
        BZ: "Belize",
        BJ: "Benin",
        BM: "Bermuda",
        BT: "Bhutan",
        BO: "Bolivia, Plurinational State of",
        BA: "Bosnia and Herzegovina",
        BW: "Botswana",
        BR: "Brazil",
        IO: "British Indian Ocean Territory",
        BG: "Bulgaria",
        BF: "Burkina Faso",
        BI: "Burundi",
        KH: "Cambodia",
        CM: "Cameroon",
        CA: "Canada",
        CV: "Cape Verde",
        KY: "Cayman Islands",
        CF: "Central African Republic",
        TD: "Chad",
        CL: "Chile",
        CN: "China",
        CO: "Colombia",
        KM: "Comoros",
        CG: "Congo",
        CD: "Democratic Republic of the Congo",
        CK: "Cook Islands",
        CR: "Costa Rica",
        CI: "Côte d'Ivoire",
        HR: "Croatia",
        CU: "Cuba",
        CW: "Curaçao",
        CY: "Cyprus",
        CZ: "Czech Republic",
        DK: "Denmark",
        DJ: "Djibouti",
        DM: "Dominica",
        DO: "Dominican Republic",
        EC: "Ecuador",
        EG: "Egypt",
        SV: "El Salvador",
        GQ: "Equatorial Guinea",
        ER: "Eritrea",
        EE: "Estonia",
        ET: "Ethiopia",
        FK: "Falkland Islands (Malvinas)",
        FO: "Faroe Islands",
        FJ: "Fiji",
        FI: "Finland",
        FR: "France",
        PF: "French Polynesia",
        GA: "Gabon",
        GM: "Gambia",
        GE: "Georgia",
        DE: "Germany",
        GH: "Ghana",
        GI: "Gibraltar",
        GR: "Greece",
        GL: "Greenland",
        GD: "Grenada",
        GU: "Guam",
        GT: "Guatemala",
        GG: "Guernsey",
        GN: "Guinea",
        GW: "Guinea-Bissau",
        HT: "Haiti",
        HN: "Honduras",
        HK: "Hong Kong",
        HU: "Hungary",
        IS: "Iceland",
        IN: "India",
        ID: "Indonesia",
        IR: "Iran, Islamic Republic of",
        IQ: "Iraq",
        IE: "Ireland",
        IM: "Isle of Man",
        IL: "Israel",
        IT: "Italy",
        JM: "Jamaica",
        JP: "Japan",
        JE: "Jersey",
        JO: "Jordan",
        KZ: "Kazakhstan",
        KE: "Kenya",
        KI: "Kiribati",
        KP: "North Korea",
        KR: "South Korea",
        KW: "Kuwait",
        KG: "Kyrgyzstan",
        LA: "Lao People's Democratic Republic",
        LV: "Latvia",
        LB: "Lebanon",
        LS: "Lesotho",
        LR: "Liberia",
        LY: "Libya",
        LI: "Liechtenstein",
        LT: "Lithuania",
        LU: "Luxembourg",
        MO: "Macao",
        MK: "Republic of Macedonia",
        MG: "Madagascar",
        MW: "Malawi",
        MY: "Malaysia",
        MV: "Maldives",
        ML: "Mali",
        MT: "Malta",
        MH: "Marshall Islands",
        MQ: "Martinique",
        MR: "Mauritania",
        MU: "Mauritius",
        MX: "Mexico",
        FM: "Micronesia, Federated States of",
        MD: "Republic of Moldova",
        MC: "Monaco",
        MN: "Mongolia",
        ME: "Montenegro",
        MS: "Montserrat",
        MA: "Morocco",
        MZ: "Mozambique",
        MM: "Myanmar",
        NA: "Namibia",
        NR: "Nauru",
        NP: "Nepal",
        NL: "Netherlands",
        NZ: "New Zealand",
        NI: "Nicaragua",
        NE: "Niger",
        NG: "Nigeria",
        NU: "Niue",
        NF: "Norfolk Island",
        MP: "Northern Mariana Islands",
        NO: "Norway",
        OM: "Oman",
        PK: "Pakistan",
        PW: "Palau",
        PS: "Palestinian Territory",
        PA: "Panama",
        PG: "Papua New Guinea",
        PY: "Paraguay",
        PE: "Peru",
        PH: "Philippines",
        PN: "Pitcairn",
        PL: "Poland",
        PT: "Portugal",
        PR: "Puerto Rico",
        QA: "Qatar",
        RO: "Romania",
        RU: "Russia",
        RW: "Rwanda",
        KN: "Saint Kitts and Nevis",
        LC: "Saint Lucia",
        WS: "Samoa",
        SM: "San Marino",
        ST: "Sao Tome and Principe",
        SA: "Saudi Arabia",
        SN: "Senegal",
        RS: "Serbia",
        SC: "Seychelles",
        SL: "Sierra Leone",
        SG: "Singapore",
        SX: "Sint Maarten",
        SK: "Slovakia",
        SI: "Slovenia",
        SB: "Solomon Islands",
        SO: "Somalia",
        ZA: "South Africa",
        SS: "South Sudan",
        ES: "Spain",
        LK: "Sri Lanka",
        SD: "Sudan",
        SR: "Suriname",
        SZ: "Swaziland",
        SE: "Sweden",
        CH: "Switzerland",
        SY: "Syria",
        TW: "Taiwan",
        TJ: "Tajikistan",
        TZ: "Tanzania",
        TH: "Thailand",
        TG: "Togo",
        TK: "Tokelau",
        TO: "Tonga",
        TT: "Trinidad and Tobago",
        TN: "Tunisia",
        TR: "Turkey",
        TM: "Turkmenistan",
        TC: "Turks and Caicos Islands",
        TV: "Tuvalu",
        UG: "Uganda",
        UA: "Ukraine",
        AE: "United Arab Emirates",
        GB: "United Kingdom",
        US: "United States",
        UY: "Uruguay",
        UZ: "Uzbekistan",
        VU: "Vanuatu",
        VE: "Venezuela, Bolivarian Republic of",
        VN: "Viet Nam",
        VI: "Virgin Islands",
        YE: "Yemen",
        ZM: "Zambia",
        ZW: "Zimbabwe",
    };

    const [infoResidence, setInfoResidence] = useState("");

    const [infoNationality, setInfoNationality] = useState("");
    const [infoFirstName, setInfoFirstName] = useState("");
    const [infoMiddleName, setInfoMiddleName] = useState("");
    const [infoLastName, setInfoLastName] = useState("");
    const [infoDOBYear, setInfoDOBYear] = useState("");
    const [infoDOBMonth, setInfoDOBMonth] = useState("");
    const infoDOBMonthRef = useRef(null);
    const [infoDOBMonthName, setInfoDOBMonthName] = useState("");
    const [infoDOBDay, setInfoDOBDay] = useState("");
    const [infoIdentityNumber, setInfoIdentityNumber] = useState("");
    
    const [infoResidentialAddress, setInfoResidentialAddress] = useState("");
    const [infoPostalCode, setInfoPostalCode] = useState("");
    const [infoCity, setInfoCity] = useState("");

    const [cameraIsActive, setCameraIsActive] = useState(false);
    const { stream, cameras, currentCamera, switchCamera, takePhoto, error } = useCamera(cameraIsActive);

    const infoIdentity1Ref = useRef(null);
    const videoIdentity1Ref = useRef(null);
    const [infoIdentity1Name, setInfoIdentity1Name] = useState("");
    const [infoIdentity1URL, setInfoIdentity1URL] = useState("");
    const [identity1PhotoType, setIdentity1PhotoType] = useState("");//dosya seçme ya da fotoğraf çekme
    const [identity1Photo, setIdentity1Photo] = useState("");//çekilen fotoğraf
    const [identity1PhotoURL, setIdentity1PhotoURL] = useState("");//çekilen fotoğrafı göstermek için link
    const [identity1PhotoArea, setIdentity1PhotoArea] = useState(false);//fotoğraf çekme alanının gözükmesi

    const infoIdentity2Ref = useRef(null);
    const videoIdentity2Ref = useRef(null);
    const [infoIdentity2Name, setInfoIdentity2Name] = useState("");
    const [infoIdentity2URL, setInfoIdentity2URL] = useState("");
    const [identity2PhotoType, setIdentity2PhotoType] = useState("");//dosya seçme ya da fotoğraf çekme
    const [identity2Photo, setIdentity2Photo] = useState("");//çekilen fotoğraf
    const [identity2PhotoURL, setIdentity2PhotoURL] = useState("");//çekilen fotoğrafı göstermek için link
    const [identity2PhotoArea, setIdentity2PhotoArea] = useState(false);//fotoğraf çekme alanının gözükmesi

    const videoRef = useRef(null);
    const [customerImagePhoto, setCustomerImagePhoto] = useState("");
    const [customerImagePhotoURL, setCustomerImagePhotoURL] = useState("");
    const [customerImagePhotoArea, setCustomerImagePhotoArea] = useState(true);
    
    const infoResidenceRef = useRef(null);
    const videoResidenceRef = useRef(null);
    const [infoResidenceName, setInfoResidenceName] = useState("");
    const [infoResidenceURL, setInfoResidenceURL] = useState("");
    const [residencePhotoType, setResidencePhotoType] = useState("");//dosya seçme ya da fotoğraf çekme
    const [residencePhoto, setResidencePhoto] = useState("");//çekilen fotoğraf
    const [residencePhotoURL, setResidencePhotoURL] = useState("");//çekilen fotoğrafı göstermek için link
    const [residencePhotoArea, setResidencePhotoArea] = useState(false);//fotoğraf çekme alanının gözükmesi

    const [infoVerification, setInfoVerification] = useState("");
    const [phoneVerification, setPhoneVerification] = useState("");
    const [identityType, setIdentityType] = useState("");
    const [identity1Verification, setIdentity1Verification] = useState("");
    const [identity2Verification, setIdentity2Verification] = useState("");
    const [customerImageVerification, setCustomerImageVerification] = useState("");
    const [residenceVerification, setResidenceVerification] = useState("");

    const [submitInfoVerificationLoading, setSubmitInfoVerificationLoading] = useState(false);
    const [submitPhoneVerificationLoading, setSubmitPhoneVerificationLoading] = useState(false);
    const [submitPhoneVerifyLoading, setSubmitPhoneVerifyLoading] = useState(false);
    const [submitIdentityTypeLoading1, setSubmitIdentityTypeLoading1] = useState(false);//ID Card
    const [submitIdentityTypeLoading2, setSubmitIdentityTypeLoading2] = useState(false);//Driving Licence
    const [submitIdentityTypeLoading3, setSubmitIdentityTypeLoading3] = useState(false);//Passport
    const [submitIdentity1Loading, setSubmitIdentity1Loading] = useState(false);
    const [submitIdentity2Loading, setSubmitIdentity2Loading] = useState(false);
    const [submitCustomerImageLoading, setSubmitCustomerImageLoading] = useState(false);
    const [submitResidenceLoading, setSubmitResidenceLoading] = useState(false);


    useEffect(() => {//listener için
        const checkScreenWidth = () => {
            if(window.innerWidth <= 500){
                setViewType("mobile");
            }
            else{
                setViewType("pc");
            }
        }

        checkScreenWidth();
        
        window.addEventListener('resize', checkScreenWidth);
        return () => {//component kapanırken event listener temizleniyor
          window.removeEventListener('resize', checkScreenWidth);
        };
    }, []);

    useEffect(() => {//kameranın aktif olup olmayacağı kontrol ediliyor
        if(pageType != "readonly"){
            if(formType == "f2.1.1" && identity1Verification != "approved" && identity1Verification != "pending" && identity1PhotoArea){
                setCameraIsActive(true);
                if (videoIdentity1Ref.current && stream) {
                    videoIdentity1Ref.current.srcObject = stream;
                }
            }
            else if(formType == "f2.2.1" && identity2Verification != "approved" && identity2Verification != "pending" && identity2PhotoArea){
                setCameraIsActive(true);
                if (videoIdentity2Ref.current && stream) {
                    videoIdentity2Ref.current.srcObject = stream;
                }
            }
            else if(formType == "f2.3.1" && customerImageVerification != "approved" && customerImageVerification != "pending" && customerImagePhotoArea){
                setCameraIsActive(true);
                if (videoRef.current && stream) {
                    videoRef.current.srcObject = stream;
                }
            }
            else if(formType == "f2.4.1" && residenceVerification != "approved" && residenceVerification != "pending" && residencePhotoArea){
                setCameraIsActive(true);
                if (videoResidenceRef.current && stream) {
                    videoResidenceRef.current.srcObject = stream;
                }
            }
            else{
                setCameraIsActive(false);
            }
        }
        else{
            setCameraIsActive(false);
        }
    }, [stream, formType, userInfo, identity1Verification, identity1PhotoArea, identity2Verification, identity2PhotoArea, customerImageVerification, customerImagePhotoArea, residenceVerification, residencePhotoArea]);

    const clearalldanger = () => {//tüm formlardaki hata belirten kırmızı çerçeveleri temizlemek için
        if(document.getElementsByClassName("main-form")[0]){
            let elements1 = document.getElementsByClassName("main-form")[0].getElementsByTagName("input");
            for (let i = 0; i < elements1.length; i++) {
                elements1[i].classList.remove("danger");
            }

            let elements2 = document.getElementsByClassName("main-form")[0].getElementsByTagName("textarea");
            for (let i = 0; i < elements2.length; i++) {
                elements2[i].classList.remove("danger");
            }

            let elements3 = document.getElementsByClassName("main-form")[0].getElementsByTagName("button");
            for (let i = 0; i < elements3.length; i++) {
                elements3[i].classList.remove("danger");
            }

            let elements4 = document.getElementsByClassName("main-form")[0].getElementsByClassName("fake-input");
            for (let i = 0; i < elements4.length; i++) {
                elements4[i].classList.remove("danger");
            }

            let elements5 = document.getElementsByClassName("main-form")[0].getElementsByTagName("div");
            for (let i = 0; i < elements5.length; i++) {
                elements5[i].classList.remove("danger");
            }
        }
    }

    const validatePattern = (field, fieldLabel) => {//info verification için patternler
        if (field == "" && fieldLabel != "Middle Name") {
            return `Please enter your ${fieldLabel}`;
        }
        else if (!patterns["general"].test(field)) {
            return `Invalid character in ${fieldLabel}`;
        }
        else if (!patterns["no_double_space_or_trim"].test(field)) {
            return `Invalid spacing in ${fieldLabel}`;
        }
        else if (!patterns["no_other_spaces"].test(field)) {
            return `Unexpected spaces in ${fieldLabel}`;
        }
        else if (!patterns["char_limit_512"].test(field) && fieldLabel == "Residential Address") {
            return `Residential address exceeds character limit`;
        }
        else if (!patterns["char_limit_256"].test(field) && fieldLabel != "Residential Address") {
            return `${fieldLabel} exceeds character limit`;
        }
    };

    const InfoForm = ({verification, infoTextLower, infoTextUpper}) => {//pending ve approved bilgilendirme ekranları
        if(infoTextLower == "all"){
            return (
                <div className={verification == "approved" ? "title result success" : "title result waiting"}>
                    {verification == "approved" ? <i className="fa-solid fa-circle-check"></i> : <i className="fa-regular fa-clock"></i>}
                    {verification == "approved" ? <p>Your verification process is complete!</p> : <p>Your information submission process has been completed and approval is awaited!</p>}
                </div>
            );
        }
        else if(infoTextLower == "section all" || infoTextUpper == "section all"){
            return (
                <div className={verification == "approved" ? "title result success" : "title result waiting"}>
                    {verification == "approved" ? <i className="fa-solid fa-circle-check"></i> : <i className="fa-regular fa-clock"></i>}
                    {verification == "approved" ? <p>{infoTextUpper} verification completed!</p> : <p>Pending approval for {infoTextLower} verification!</p>}
                </div>
            );
        }
        else{
            return (
                <div className={verification == "approved" ? "title result success" : "title result waiting"}>
                    {verification == "approved" ? <p>{infoTextUpper} verification completed!</p> : <p>Pending approval for {infoTextLower} verification!</p>}
                </div>
            );
        }
    }
    
    const formNumbersClickControl_class = (number, numberType) => {
        let clickable = false;

        if(numberType == "outer"){
            if(number == "1"){
                clickable = true;
            }
            else if(number == "2"){
                if(infoVerification == "approved" && phoneVerification == "approved"){
                    clickable = true;
                }
            }
        }
        else if(numberType == "inner" && pageType != "readonly"){
            if(formNumber == "1" || formNumber == "01"){
                if(number == "1"){
                    clickable = true;
                }
                else if(number == "2"){
                    if(infoVerification == "approved"){
                        clickable = true;
                    }
                }
            }
            else{
                if(infoVerification == "approved" && phoneVerification == "approved"){
                    if(number == "1"){
                        clickable = true;
                    }
                    else if(number == "2"){
                        if(identityType != "" && (identity1Verification == "approved" || identity1Verification == "pending")){
                            clickable = true;
                        }
                    }
                    else if(number == "3"){
                        if((identity1Verification == "approved" || identity1Verification == "pending") && (identity2Verification == "approved" || identity2Verification == "pending")){
                            clickable = true;
                        }
                    }
                    else if(number == "4"){
                        if((identity1Verification == "approved" || identity1Verification == "pending") && (identity2Verification == "approved" || identity2Verification == "pending") && (customerImageVerification == "approved" || customerImageVerification == "pending")){
                            clickable = true;
                        }
                    }
                }
            }
        }

        if(clickable){
            return "clickable";
        }
        else{
            return "not-clickable";
        }
    }

    const formNumbersClickControl_function = (number, numberType) => {
        clearalldanger();

        if(numberType == "outer"){
            if(number == "0"){
                setFormType("f1.0.0");
            }
            else if(number == "1"){
                if(infoVerification == "approved" && phoneVerification == "approved"){
                    setFormType("f1.3.0");
                }
                else if(infoVerification == "approved"){
                    setFormType("f1.2.1");
                }
                else{
                    setFormType("f1.1.1");
                }
            }
            else if(number == "2"){
                if(infoVerification == "approved" && phoneVerification == "approved"){
                    if(identityType == "" && identity1Verification != "approved" && identity1Verification != "pending" && identity2Verification != "approved" && identity2Verification != "pending"){
                        setFormType("f2.0.0");
                    }
                    else if(identityType != "" && identity1Verification != "approved" && identity1Verification != "pending"){
                        setFormType("f2.1.1");
                    }
                    else if(identityType != "" && (identity1Verification == "approved" || identity1Verification == "pending") && identity2Verification != "approved" && identity2Verification != "pending"){
                        setFormType("f2.2.1");
                    }
                    else if(identityType != "" && (identity1Verification == "approved" || identity1Verification == "pending") && (identity2Verification == "approved" || identity2Verification == "pending") && customerImageVerification != "approved" && customerImageVerification != "pending"){
                        setFormType("f2.3.1");
                    }
                    else if(identityType != "" && (identity1Verification == "approved" || identity1Verification == "pending") && (identity2Verification == "approved" || identity2Verification == "pending") && (customerImageVerification == "approved" || customerImageVerification == "pending") && residenceVerification != "approved" && residenceVerification != "pending"){
                        setFormType("f2.4.1");
                    }
                    else if(identityType != "" && (identity1Verification == "pending" || identity2Verification == "pending" || customerImageVerification == "pending" || residenceVerification == "pending")){
                        setFormType("f3.0.0");
                    }
                    else if(identityType != "" && identity1Verification == "approved" && identity2Verification == "approved" && customerImageVerification == "approved" && residenceVerification == "approved"){
                        setFormType("f4.0.0");
                    }
                }
            }
        }
        else if(numberType == "inner" && pageType != "readonly"){
            if(formNumber == "1" || formNumber == "01"){
                if(number == "1"){
                    setFormType("f1.1.1");
                }
                else if(number == "2"){
                    if(infoVerification == "approved"){
                        setFormType("f1.2.1");
                    }
                }
            }
            else{
                if(infoVerification == "approved" && phoneVerification == "approved"){
                    if(number == "1"){
                        if(identity1Verification != "approved" && identity1Verification != "pending" && identity2Verification != "approved" && identity2Verification != "pending"){
                            setFormType("f2.0.0");
                        }
                        else if(identityType != ""){
                            setFormType("f2.1.1");
                        }
                    }
                    else if(number == "2"){
                        if(identityType != "" && (identity1Verification == "approved" || identity1Verification == "pending")){
                            setFormType("f2.2.1");
                        }
                    }
                    else if(number == "3"){
                        if(identityType != "" && (identity1Verification == "approved" || identity1Verification == "pending") && (identity2Verification == "approved" || identity2Verification == "pending")){
                            setFormType("f2.3.1");
                        }
                    }
                    else if(number == "4"){
                        if(identityType != "" && (identity1Verification == "approved" || identity1Verification == "pending") && (identity2Verification == "approved" || identity2Verification == "pending") && (customerImageVerification == "approved" || customerImageVerification == "pending")){
                            setFormType("f2.4.1");
                        }
                    }
                }
            }
        }
    }


    //f1.1
    const enterResidence = (post, value) => {
        clearalldanger();

        if(post){
            if(infoResidence != ""){
                setFormType("f1.1.2");
            }
            else{
                document.getElementById("info_placeofresidence").classList.add("danger");
                toast.error("Please choose your residential country!");
            }
        }
        else{
            if(value && countries && countries[value]){
                setInfoResidence(value);
                setInfoNationality(value);
                setSelectedPhoneCountry(value);
            }
            else{
                document.getElementById("info_placeofresidence").classList.add("danger");
            }
        }
    }

    const DOBControl = (e, type, isBlur) => {
        clearalldanger();
        let newInfo = e.target.value.replace(/[^0-9]/g, '');

        if(newInfo != ""){
            let numberNewInfo = Number(newInfo);
            
            if((type == "year" && numberNewInfo > currentYear) || (type == "year" && (newInfo == "0000" || newInfo[0] == "0")) || (type == "month" && numberNewInfo > 12) || (type == "day" && numberNewInfo > 31) || ((type == "day" || type == "month") && (newInfo == "00"))){
                newInfo = "";
            }
        }

        if(newInfo != "" && isBlur){
            if((type == "year" && newInfo.length != 4)){
                newInfo = "";
            }
            else if((type == "day" || type == "month") && (newInfo == "0")){
                newInfo = "";
            }
            else if((type == "day" || type == "month") && newInfo.length == 1){
                newInfo = `0${newInfo}`;
            }
        }
        else if(newInfo != "" && !isBlur){
            if((type == "year" && newInfo.length == 4) || (type == "month" && newInfo.length == 2)){
                e.target.blur();
                e.target.nextElementSibling.focus();
            }
        }

        if(type == "year" && newInfo == ""){
            document.getElementById("info_year").classList.add("danger");
        }
        else if(type == "month" && newInfo == ""){
            document.getElementById("info_month").classList.add("danger");
        }
        else if(type == "day" && newInfo == ""){
            document.getElementById("info_day").classList.add("danger");
        }

        return newInfo;
    }

    const enterIdentity = (post, value, area) => {
        clearalldanger();
        let tempReturnValue = null;

        if(post){
            if(area == "Continue"){
                let submitControl = true;

                if(!(infoNationality && countries && countries[infoNationality])){
                    submitControl = false;
                    document.getElementById("info_nationality").classList.add("danger");
                    toast.error("Please choose your nationality!");
                }

                if(infoIdentityNumber == "" || !patterns["identity_number"].test(infoIdentityNumber)){
                    submitControl = false;
                    document.getElementById("info_identitynumber").classList.add("danger");
                    toast.error("Invalid identity number format");
                }

                tempReturnValue = validatePattern(infoFirstName, "First Name");
                if(tempReturnValue){
                    submitControl = false;
                    document.getElementById("info_firstname").classList.add("danger");
                    toast.error(tempReturnValue);
                }

                tempReturnValue = validatePattern(infoMiddleName, "Middle Name");
                if(infoMiddleName != "" && tempReturnValue){
                    submitControl = false;
                    document.getElementById("info_middlename").classList.add("danger");
                    toast.error(tempReturnValue);
                }

                tempReturnValue = validatePattern(infoLastName, "Last Name");
                if(tempReturnValue){
                    submitControl = false;
                    document.getElementById("info_lastname").classList.add("danger");
                    toast.error(tempReturnValue);
                }

                let dateofbirth = `${infoDOBYear}-${infoDOBMonth}-${infoDOBDay}`;
                if(dateofbirth == "" || !patterns["date_of_birth"].test(dateofbirth)){
                    submitControl = false;
                    if(infoDOBYear == ""){document.getElementById("info_year").classList.add("danger");}
                    if(infoDOBMonth == ""){document.getElementById("info_month").classList.add("danger");}
                    if(infoDOBDay == ""){document.getElementById("info_day").classList.add("danger");}
                    else{
                        document.getElementById("info_year").classList.add("danger");
                        document.getElementById("info_month").classList.add("danger");
                        document.getElementById("info_day").classList.add("danger");
                    }
                    toast.error("Invalid date of birth format. Expected YYYY-MM-DD");
                }
                
                if(submitControl){
                    setFormType("f1.1.3");
                }
            }
        }
        else{
            if(area == "Nationality"){
                setInfoNationality(value);
                if(!(value && countries && countries[value])){
                    setInfoNationality("");
                    document.getElementById("info_nationality").classList.add("danger");
                }
            }
            else if(area == "Identity Number"){
                setInfoIdentityNumber(value);
                if (value == "" || !patterns["identity_number"].test(value)) {
                    document.getElementById("info_identitynumber").classList.add("danger");
                }
            }
            else if(area == "First Name"){
                setInfoFirstName(value);
                tempReturnValue = validatePattern(value, area);
                if(tempReturnValue){
                    document.getElementById("info_firstname").classList.add("danger");
                }
            }
            else if(area == "Middle Name"){
                setInfoMiddleName(value);
                tempReturnValue = validatePattern(value, area);
                if(value != "" && tempReturnValue){
                    document.getElementById("info_middlename").classList.add("danger");
                }
            }
            else if(area == "Last Name"){
                setInfoLastName(value);
                tempReturnValue = validatePattern(value, area);
                if(tempReturnValue){
                    document.getElementById("info_lastname").classList.add("danger");
                }
            }
        }
    }

    const submitInfoVerification = async (post, value, area) => {
        clearalldanger();
        let tempReturnValue = null;

        if(post){
            if(area == "Submit"){
                let submitControl = true;

                tempReturnValue = validatePattern(infoResidentialAddress, "Residential Address");
                if(tempReturnValue){
                    submitControl = false;
                    document.getElementById("info_residentialaddress").classList.add("danger");
                    toast.error(tempReturnValue);
                }

                if(infoPostalCode == "" || !patterns["postal_code"].test(infoPostalCode)){
                    submitControl = false;
                    document.getElementById("info_postalcode").classList.add("danger");
                    toast.error("Invalid postal code format");
                }

                tempReturnValue = validatePattern(infoCity, "City");
                if(tempReturnValue){
                    submitControl = false;
                    document.getElementById("info_city").classList.add("danger");
                    toast.error(tempReturnValue);
                }

                if(submitControl){
                    setSubmitInfoVerificationLoading(true);

                    let data = {
                        "residential_country": infoResidence,
                        "nationality": infoNationality,
                        "identity_number": infoIdentityNumber,
                        "first_name": infoFirstName,
                        "last_name": infoLastName,
                        "date_of_birth": `${infoDOBYear}-${infoDOBMonth}-${infoDOBDay}`,
                        "residential_address": infoResidentialAddress,
                        "postal_code": infoPostalCode,
                        "city": infoCity
                    }

                    if(infoMiddleName != ""){
                        Object.assign(data, { middle_name: infoMiddleName });
                    }
        
                    const session = await Auth.currentSession();
                    const jwtToken = session.getIdToken().getJwtToken();
        
                    axios.post('https://api.kpricemarket.com/user/info-verification', 
                        JSON.stringify(data),
                        { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${jwtToken}` } }
                    )
                    .then(function (response) {
        
                        if(response.status == "200"){
                            setInfoVerification("approved");
                        }
                        else{//hatalı durum için
                            toast.error(`There was a problem during the information verification, please try again!${response.data.message ? ` (${response.data.message})` : ""}`);
                        }

                        setSubmitInfoVerificationLoading(false);
                    })
                    .catch(function (error) {
                        toast.error(error.response.data.error);
                        setSubmitInfoVerificationLoading(false);
                    });
                }
            }
        }
        else{
            if(area == "Residential Address"){
                setInfoResidentialAddress(value);
                tempReturnValue = validatePattern(value, area);
                if(tempReturnValue){
                    document.getElementById("info_residentialaddress").classList.add("danger");
                }
            }
            else if(area == "Postal Code"){
                setInfoPostalCode(value);
                if (value == "" || !patterns["postal_code"].test(value)) {
                    document.getElementById("info_postalcode").classList.add("danger");
                }
            }
            else if(area == "City"){
                setInfoCity(value);
                tempReturnValue = validatePattern(value, area);
                if(tempReturnValue){
                    document.getElementById("info_city").classList.add("danger");
                }
            }
        }
    }
    
    
    //f1.2
    const isPhoneValid = (phone) => {
        try {
            return phoneUtil.isValidNumber(phoneUtil.parse(phone));
        } catch (error) {
            return false;
        }
    };

    function validateAndUpdatePhoneNumber() {
        if(infoPhoneRef && infoPhoneRef.current){
          const onlyNumbers = infoPhoneRef.current.value.replace(/[^0-9]/g, '');
          infoPhoneRef.current.value = onlyNumbers;
        }
    }

    const submitPhone = async (post) => {
        clearalldanger();
        
        if(phoneControlCount > 1 && infoPhoneRef && infoPhoneRef.current){
            validateAndUpdatePhoneNumber();
            if(isPhoneValid(`${selectedPhoneCountryCode}${infoPhoneRef.current.value}`)){
                setPhoneNumber(`${selectedPhoneCountryCode}${infoPhoneRef.current.value}`);
                if(post){
                    setSubmitPhoneVerificationLoading(true);

                    let data = {
                        "phone_number": phoneNumber,
                        "code_type": "new"
                    }

                    const session = await Auth.currentSession();
                    const jwtToken = session.getIdToken().getJwtToken();
        
                    axios.post('https://api.kpricemarket.com/user/phone-update', 
                        JSON.stringify(data),
                        { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${jwtToken}` } }
                    )
                    .then(function (response) {
        
                        if(response.status == "200"){
                            setFormType("f1.2.2");
                            setResendCodeTimestamp(response.data.last_code_timestamp);
                            setResendCodeLock(true);
                        }
                        else{//hatalı durum için
                            toast.error(`There was a problem sending the verification code, please try again!${response.data.message ? ` (${response.data.message})` : ""}`);
                        }

                        setSubmitPhoneVerificationLoading(false);
                    })
                    .catch(function (error) {
                        toast.error(error.response.data.error);
                        setSubmitPhoneVerificationLoading(false);
                    });
                }
            }
            else{
                infoPhoneRef.current.classList.add("danger");
                if(post){
                    toast.error("Please enter a valid phone number!");
                }
            }
        }
        else{
            setPhoneControlCount(phoneControlCount + 1);
        }
    }

    const resendCode = async () => {
        if(!resendCodeLock){
            let data = {
                "phone_number": phoneNumber,
                "code_type": "resend"
            }

            const session = await Auth.currentSession();
            const jwtToken = session.getIdToken().getJwtToken();

            axios.post('https://api.kpricemarket.com/user/phone-update', 
                JSON.stringify(data),
                { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${jwtToken}` } }
            )
            .then(function (response) {

                if(response.status == "200"){
                    toast.success("Verification code has been sent successfully, please check!");
                    setResendCodeTimestamp(response.data.last_code_timestamp);
                    setResendCodeLock(true);
                }
                else{//hatalı durum için
                    toast.error(`There was a problem sending the verification code, please try again!${response.data.message ? ` (${response.data.message})` : ""}`);
                }
            })
            .catch(function (error) {
                toast.error(error.response.data.error);
            });
        }
        else{
            toast.error("The verification code may be sent once every 60 seconds, please try again after a while!");
        }
    }

    const verifyPhone = async () => {
        if(infoVerificationCode.join('') != "" && infoVerificationCode.join('') != null && infoVerificationCode.every(code => code !== '')){
            setSubmitPhoneVerifyLoading(true);

            let data = {
                "phone_number": phoneNumber,
                "confirm_code": infoVerificationCode.join('')
            }

            const session = await Auth.currentSession();
            const jwtToken = session.getIdToken().getJwtToken();

            axios.post('https://api.kpricemarket.com/user/phone-verification', 
                JSON.stringify(data),
                { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${jwtToken}` } }
            )
            .then(function (response) {

                if(response.status == "200"){
                    setPhoneVerification("approved");
                }
                else{//hatalı durum için
                    toast.error(`There was a problem verifying the phone number!${response.data.message ? ` (${response.data.message})` : ""}`);
                }

                setSubmitPhoneVerifyLoading(false);
            })
            .catch(function (error) {
                toast.error(error.response.data.error);
                setSubmitPhoneVerifyLoading(false);
            });
        }
        else{
            let codeElements = document.getElementsByClassName("main-form")[0].getElementsByClassName("verification")[0].getElementsByTagName("input");
            for (let i = 0; i < codeElements.length; i++) {
                if(codeElements[i].value == ""){
                    codeElements[i].classList.remove("danger");
                }
            }
            toast.error("Please enter the 6-digit verification code!");
        }
    }

    useEffect(() => {
        submitPhone(false);
    }, [selectedPhoneCountryCode]);


    //f2.0
    const submitIdentityType = async (type) => {
        if(type == "ID Card" || type == "Driving Licence" || type == "Passport"){
            
            if(type == "ID Card"){
                setSubmitIdentityTypeLoading1(true);
            }
            else if(type == "Driving Licence"){
                setSubmitIdentityTypeLoading2(true);
            }
            else if(type == "Passport"){
                setSubmitIdentityTypeLoading3(true);
            }

            let data = {
                "identity_type": type
            }

            const session = await Auth.currentSession();
            const jwtToken = session.getIdToken().getJwtToken();

            axios.post('https://api.kpricemarket.com/user/set-identity-type', 
                JSON.stringify(data),
                { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${jwtToken}` } }
            )
            .then(function (response) {

                if(response.status == "200"){
                    setIdentityType(response.data.identity_type);
                }
                else{//hatalı durum için
                    toast.error(`There was a problem selecting the identity type, please try again!${response.data.message ? ` (${response.data.message})` : ""}`);
                }

                setSubmitIdentityTypeLoading1(false);
                setSubmitIdentityTypeLoading2(false);
                setSubmitIdentityTypeLoading3(false);
            })
            .catch(function (error) {
                toast.error(error.response.data.error);
                setSubmitIdentityTypeLoading1(false);
                setSubmitIdentityTypeLoading2(false);
                setSubmitIdentityTypeLoading3(false);
            });
            
        }
        else{
            toast.error("Identity type value is invalid!");
        }
    }


    //f2.1
    const openCamera1 = () => {
        if(infoIdentity1Ref.current){
            infoIdentity1Ref.current.value = "";
        }
        setInfoIdentity1Name("");
        setInfoIdentity1URL("");
        setIdentity1Photo("");
        setIdentity1PhotoURL("");
        setIdentity1PhotoType("camera");
        setIdentity1PhotoArea(true);
    }

    const takeYourPhoto1 = async () => {
        try {
            const photo = await takePhoto(videoIdentity1Ref.current);
            if(photo){
                const photoFile = new File([photo], "identity1.png", { type: "image/png" });
                setIdentity1Photo(photoFile);
                const fileReader = new FileReader();
                fileReader.onload = () => {
                    setIdentity1PhotoURL(fileReader.result);
                };
                fileReader.readAsDataURL(photoFile);
            }
        } catch (error) {
        }

        if(infoIdentity1Ref.current){
            infoIdentity1Ref.current.value = "";
        }
        setInfoIdentity1Name("");
        setInfoIdentity1URL("");
        setIdentity1PhotoArea(false);
        setIdentity1PhotoType("camera");
    }

    useEffect(() => {
        submitIdentity1(false, "camera");
    }, [identity1Photo]);

    const submitIdentity1 = async (post, photoType = null) => {
        clearalldanger();
        let selectedFile = null;
        const currentPhotoType = photoType == "select" || (photoType == null && identity1PhotoType == "select") ? "select" : (photoType == "camera" || (photoType == null && identity1PhotoType == "camera") ? "camera" : null);

        if(currentPhotoType == "select"){
            selectedFile = infoIdentity1Ref.current.files[0];
        }
        else if(currentPhotoType == "camera"){
            selectedFile = identity1Photo;
        }

        if(post){
            if(identityType != "" && selectedFile && selectedFile != "" && (selectedFile.type === "image/png" || selectedFile.type === "image/jpg" || selectedFile.type === "image/jpeg") && selectedFile.size < maxFileSize){
                setSubmitIdentity1Loading(true);
                
                const fileReader = new FileReader();
                fileReader.onloadend = async () => {
                    const base64String = fileReader.result;
    
                    const data = {
                        file: base64String,
                        file_header: "identity1"
                    }
                    
                    const session = await Auth.currentSession();
                    const jwtToken = session.getIdToken().getJwtToken();
                    
                    axios.post('https://api.kpricemarket.com/user/file-verification', 
                        data,
                        { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${jwtToken}` } }
                    )
                    .then(function (response) {

                        if(response.status == "200"){
                            setIdentity1Verification(response.data.identity1_verification);
                            setSubmitIdentity1Loading(false);
                        }
                        else{//hatalı durum için
                            toast.error(`There was a problem uploading the file, please try again!${response.data.message ? ` (${response.data.message})` : ""}`);
                            setSubmitIdentity1Loading(false);
                        }
                    })
                    .catch(function (error) {
                        toast.error(error.response.data.error);
                        setSubmitIdentity1Loading(false);
                    });
                };
                fileReader.readAsDataURL(selectedFile);
            }
            else{
                setInfoIdentity1Name("");
                setInfoIdentity1URL("");
                setIdentity1Photo("");
                setIdentity1PhotoURL("");
                if(currentPhotoType == "select"){
                    document.getElementById("infoidentity1").classList.add("danger");
                    setIdentity1PhotoArea(false);
                }

                if(!selectedFile && currentPhotoType == "camera"){
                    toast.error(`Please take photo for the front side of your ${identityType}!`);
                }
                else if(!selectedFile){
                    toast.error(`Please upload the front side of your ${identityType}!`);
                }
                else if (selectedFile.size > maxFileSize) {
                    toast.error("File size should not exceed 8 MB!");
                }
                else{
                    toast.error("You can only upload files of type PNG, JPG, JPEG!");
                }
            }
        }
        else{
            if(selectedFile && selectedFile != "" && (selectedFile.type === "image/png" || selectedFile.type === "image/jpg" || selectedFile.type === "image/jpeg") && selectedFile.size < maxFileSize){
                if(currentPhotoType == "select"){
                    setInfoIdentity1Name(selectedFile.name);
                    const fileReader = new FileReader();
                    fileReader.onload = () => {
                        setInfoIdentity1URL(fileReader.result);
                    };
                    fileReader.readAsDataURL(selectedFile);
                    setIdentity1Photo("");
                    setIdentity1PhotoURL("");
                }
                setIdentity1PhotoArea(false);
            }
            else{
                if(currentPhotoType == "select"){
                    document.getElementById("infoidentity1").classList.add("danger");
                }
                if(infoIdentity1Ref.current){
                    infoIdentity1Ref.current.value = "";
                }
                setInfoIdentity1Name("");
                setInfoIdentity1URL("");
                setIdentity1Photo("");
                setIdentity1PhotoURL("");
                if(currentPhotoType == "select"){
                    setIdentity1PhotoArea(false);
                }
            }
        }
    }


    //f2.2
    const openCamera2 = () => {
        if(infoIdentity2Ref.current){
            infoIdentity2Ref.current.value = "";
        }
        setInfoIdentity2Name("");
        setInfoIdentity2URL("");
        setIdentity2Photo("");
        setIdentity2PhotoURL("");
        setIdentity2PhotoType("camera");
        setIdentity2PhotoArea(true);
    }

    const takeYourPhoto2 = async () => {
        try {
            const photo = await takePhoto(videoIdentity2Ref.current);
            if(photo){
                const photoFile = new File([photo], "identity2.png", { type: "image/png" });
                setIdentity2Photo(photoFile);
                const fileReader = new FileReader();
                fileReader.onload = () => {
                    setIdentity2PhotoURL(fileReader.result);
                };
                fileReader.readAsDataURL(photoFile);
            }
        } catch (error) {
        }

        if(infoIdentity2Ref.current){
            infoIdentity2Ref.current.value = "";
        }
        setInfoIdentity2Name("");
        setInfoIdentity2URL("");
        setIdentity2PhotoArea(false);
        setIdentity2PhotoType("camera");
    }

    useEffect(() => {
        submitIdentity2(false, "camera");
    }, [identity2Photo]);

    const submitIdentity2 = async (post, photoType = null) => {
        clearalldanger();
        let selectedFile = null;
        const currentPhotoType = photoType == "select" || (photoType == null && identity2PhotoType == "select") ? "select" : (photoType == "camera" || (photoType == null && identity2PhotoType == "camera") ? "camera" : null);

        if(currentPhotoType == "select"){
            selectedFile = infoIdentity2Ref.current.files[0];
        }
        else if(currentPhotoType == "camera"){
            selectedFile = identity2Photo;
        }

        if(post){
            if(identityType != "" && selectedFile && selectedFile != "" && (selectedFile.type === "image/png" || selectedFile.type === "image/jpg" || selectedFile.type === "image/jpeg") && selectedFile.size < maxFileSize){
                setSubmitIdentity2Loading(true);
                
                const fileReader = new FileReader();
                fileReader.onloadend = async () => {
                    const base64String = fileReader.result;
    
                    const data = {
                        file: base64String,
                        file_header: "identity2"
                    }
                    
                    const session = await Auth.currentSession();
                    const jwtToken = session.getIdToken().getJwtToken();
                    
                    axios.post('https://api.kpricemarket.com/user/file-verification', 
                        data,
                        { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${jwtToken}` } }
                    )
                    .then(function (response) {

                        if(response.status == "200"){
                            setIdentity2Verification(response.data.identity2_verification);
                            setSubmitIdentity2Loading(false);
                        }
                        else{//hatalı durum için
                            toast.error(`There was a problem uploading the file, please try again!${response.data.message ? ` (${response.data.message})` : ""}`);
                            setSubmitIdentity2Loading(false);
                        }
                    })
                    .catch(function (error) {
                        toast.error(error.response.data.error);
                        setSubmitIdentity2Loading(false);
                    });
                };
                fileReader.readAsDataURL(selectedFile);
            }
            else{
                setInfoIdentity2Name("");
                setInfoIdentity2URL("");
                setIdentity2Photo("");
                setIdentity2PhotoURL("");
                if(currentPhotoType == "select"){
                    document.getElementById("infoidentity2").classList.add("danger");
                    setIdentity2PhotoArea(false);
                }

                if(!selectedFile && currentPhotoType == "camera"){
                    toast.error(`Please take photo for the back side of your ${identityType}!`);
                }
                else if(!selectedFile){
                    toast.error(`Please upload the back side of your ${identityType}!`);
                }
                else if (selectedFile.size > maxFileSize) {
                    toast.error("File size should not exceed 8 MB!");
                }
                else{
                    toast.error("You can only upload files of type PNG, JPG, JPEG!");
                }
            }
        }
        else{
            if(selectedFile && selectedFile != "" && (selectedFile.type === "image/png" || selectedFile.type === "image/jpg" || selectedFile.type === "image/jpeg") && selectedFile.size < maxFileSize){
                if(currentPhotoType == "select"){
                    setInfoIdentity2Name(selectedFile.name);
                    const fileReader = new FileReader();
                    fileReader.onload = () => {
                        setInfoIdentity2URL(fileReader.result);
                    };
                    fileReader.readAsDataURL(selectedFile);
                    setIdentity2Photo("");
                    setIdentity2PhotoURL("");
                }
                setIdentity2PhotoArea(false);
            }
            else{
                if(currentPhotoType == "select"){
                    document.getElementById("infoidentity2").classList.add("danger");
                }
                if(infoIdentity2Ref.current){
                    infoIdentity2Ref.current.value = "";
                }
                setInfoIdentity2Name("");
                setInfoIdentity2URL("");
                setIdentity2Photo("");
                setIdentity2PhotoURL("");
                if(currentPhotoType == "select"){
                    setIdentity2PhotoArea(false);
                }
            }
        }
    }


    //f2.3
    const takeYourPhoto = async () => {
        try {
            const photo = await takePhoto(videoRef.current);
            if(photo){
                const photoFile = new File([photo], "customerPhoto.png", { type: "image/png" });
                setCustomerImagePhoto(photoFile);
                const fileReader = new FileReader();
                fileReader.onload = () => {
                    setCustomerImagePhotoURL(fileReader.result);
                };
                fileReader.readAsDataURL(photoFile);
                setCustomerImagePhotoArea(false);
            }
        } catch (error) {
        }
    }

    const submitCustomerImage = async () => {
        clearalldanger();
        const selectedFile = customerImagePhoto;

        if(selectedFile && selectedFile != "" && (selectedFile.type === "image/png" || selectedFile.type === "image/jpg" || selectedFile.type === "image/jpeg") && selectedFile.size < maxFileSize){
            setSubmitCustomerImageLoading(true);
            
            const fileReader = new FileReader();
            fileReader.onloadend = async () => {
                const base64String = fileReader.result;

                const data = {
                    file: base64String,
                    file_header: "customer_image"
                }
                
                const session = await Auth.currentSession();
                const jwtToken = session.getIdToken().getJwtToken();
                
                axios.post('https://api.kpricemarket.com/user/file-verification', 
                    data,
                    { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${jwtToken}` } }
                )
                .then(function (response) {

                    if(response.status == "200"){
                        setCustomerImageVerification(response.data.customer_image_verification);
                        setSubmitCustomerImageLoading(false);
                    }
                    else{//hatalı durum için
                        toast.error(`There was a problem uploading the photo, please try again!${response.data.message ? ` (${response.data.message})` : ""}`);
                        setSubmitCustomerImageLoading(false);
                    }
                })
                .catch(function (error) {
                    toast.error(error.response.data.error);
                    setSubmitCustomerImageLoading(false);
                });
            };
            fileReader.readAsDataURL(selectedFile);
        }
        else{
            setCustomerImagePhoto("");
            setCustomerImagePhotoURL("");
            setCustomerImagePhotoArea(true);
            if(!selectedFile){
                toast.error("Please take your photo!");
            }
            else if (selectedFile.size > maxFileSize) {
                toast.error("File size should not exceed 8 MB!");
            }
            else{
                toast.error("You can only upload files of type PNG, JPG, JPEG!");
            }
        }
    }


    //f2.4
    const openCamera4 = () => {
        if(infoResidenceRef.current){
            infoResidenceRef.current.value = "";
        }
        setInfoResidenceName("");
        setInfoResidenceURL("");
        setResidencePhoto("");
        setResidencePhotoURL("");
        setResidencePhotoType("camera");
        setResidencePhotoArea(true);
    }

    const takeYourPhoto4 = async () => {
        try {
            const photo = await takePhoto(videoResidenceRef.current);
            if(photo){
                const photoFile = new File([photo], "residence.png", { type: "image/png" });
                setResidencePhoto(photoFile);
                const fileReader = new FileReader();
                fileReader.onload = () => {
                    setResidencePhotoURL(fileReader.result);
                };
                fileReader.readAsDataURL(photoFile);
            }
        } catch (error) {
        }

        if(infoResidenceRef.current){
            infoResidenceRef.current.value = "";
        }
        setInfoResidenceName("");
        setInfoResidenceURL("");
        setResidencePhotoArea(false);
        setResidencePhotoType("camera");
    }

    useEffect(() => {
        submitResidence(false, "camera");
    }, [residencePhoto]);

    const submitResidence = async (post, photoType = null) => {
        clearalldanger();
        let selectedFile = null;
        const currentPhotoType = photoType == "select" || (photoType == null && residencePhotoType == "select") ? "select" : (photoType == "camera" || (photoType == null && residencePhotoType == "camera") ? "camera" : null);

        if(currentPhotoType == "select"){
            selectedFile = infoResidenceRef.current.files[0];
        }
        else if(currentPhotoType == "camera"){
            selectedFile = residencePhoto;
        }

        if(post){
            if(identityType != "" && selectedFile && selectedFile != "" && (selectedFile.type === "image/png" || selectedFile.type === "image/jpg" || selectedFile.type === "image/jpeg") && selectedFile.size < maxFileSize){
                setSubmitResidenceLoading(true);
                
                const fileReader = new FileReader();
                fileReader.onloadend = async () => {
                    const base64String = fileReader.result;
    
                    const data = {
                        file: base64String,
                        file_header: "residence"
                    }
                    
                    const session = await Auth.currentSession();
                    const jwtToken = session.getIdToken().getJwtToken();
                    
                    axios.post('https://api.kpricemarket.com/user/file-verification', 
                        data,
                        { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${jwtToken}` } }
                    )
                    .then(function (response) {

                        if(response.status == "200"){
                            setResidenceVerification(response.data.residence_verification);
                            setSubmitResidenceLoading(false);
                        }
                        else{//hatalı durum için
                            toast.error(`There was a problem uploading the file, please try again!${response.data.message ? ` (${response.data.message})` : ""}`);
                            setSubmitResidenceLoading(false);
                        }
                    })
                    .catch(function (error) {
                        toast.error(error.response.data.error);
                        setSubmitResidenceLoading(false);
                    });
                };
                fileReader.readAsDataURL(selectedFile);
            }
            else{
                setInfoResidenceName("");
                setInfoResidenceURL("");
                setResidencePhoto("");
                setResidencePhotoURL("");
                if(currentPhotoType == "select"){
                    document.getElementById("inforesidence").classList.add("danger");
                    setResidencePhotoArea(false);
                }

                if(!selectedFile && currentPhotoType == "camera"){
                    toast.error(`Please take photo for the residence!`);
                }
                else if(!selectedFile){
                    toast.error(`Please upload the residence!`);
                }
                else if (selectedFile.size > maxFileSize) {
                    toast.error("File size should not exceed 8 MB!");
                }
                else{
                    toast.error("You can only upload files of type PNG, JPG, JPEG!");
                }
            }
        }
        else{
            if(selectedFile && selectedFile != "" && (selectedFile.type === "image/png" || selectedFile.type === "image/jpg" || selectedFile.type === "image/jpeg") && selectedFile.size < maxFileSize){
                if(currentPhotoType == "select"){
                    setInfoResidenceName(selectedFile.name);
                    const fileReader = new FileReader();
                    fileReader.onload = () => {
                        setInfoResidenceURL(fileReader.result);
                    };
                    fileReader.readAsDataURL(selectedFile);
                    setResidencePhoto("");
                    setResidencePhotoURL("");
                }
                setResidencePhotoArea(false);
            }
            else{
                if(currentPhotoType == "select"){
                    document.getElementById("inforesidence").classList.add("danger");
                }
                if(infoResidenceRef.current){
                    infoResidenceRef.current.value = "";
                }
                setInfoResidenceName("");
                setInfoResidenceURL("");
                setResidencePhoto("");
                setResidencePhotoURL("");
                if(currentPhotoType == "select"){
                    setResidencePhotoArea(false);
                }
            }
        }
    }



    useEffect(() => {//güncel user info değerlerinden güncel verification değerleri çekiliyor
        if(userInfo && userInfo.customer_id){
            let temp_info_verification = userInfo.info_verification ? userInfo.info_verification : "";
            let temp_phone_verification = userInfo.phone_verification ? userInfo.phone_verification : "";
            let temp_identity_type = userInfo.identity_type ? userInfo.identity_type : "";
            let temp_identity1_verification = userInfo.identity1_verification ? userInfo.identity1_verification : "";
            let temp_identity2_verification = userInfo.identity2_verification ? userInfo.identity2_verification : "";
            let temp_customer_image_verification = userInfo.customer_image_verification ? userInfo.customer_image_verification : "";
            let temp_residence_verification = userInfo.residence_verification ? userInfo.residence_verification : "";

            setInfoVerification(temp_info_verification);
            setPhoneVerification(temp_phone_verification);
            setIdentityType(temp_identity_type);
            setIdentity1Verification(temp_identity1_verification);
            setIdentity2Verification(temp_identity2_verification);
            setCustomerImageVerification(temp_customer_image_verification);
            setResidenceVerification(temp_residence_verification);
            setSelectedPhoneCountry(userInfo.residential_country ? userInfo.residential_country : "US");
        }
        else{
            setFormType("f0.0.0");
            setSelectedPhoneCountry("US");
        }
    }, [userInfo]);

    useEffect(() => {//verification değerleri güncellendikçe formlar belirleniyor
        if(userInfo && userInfo.customer_id){
            if(infoVerification != "approved"){
                setFormType("f1.1.1");//ikametgah ülkesi seçimi
            }
            else if(phoneVerification != "approved"){
                setFormType("f1.2.1");//telefon girişi
            }
            else if(identityType == "" && (identity1Verification == "" || identity1Verification == "denied") && (identity2Verification == "" || identity2Verification == "denied")){
                setFormType("f2.0.0");//yükleme seçenekleri
            }
            else if(identityType != "" && identity1Verification != "approved" && identity1Verification != "pending"){
                setFormType("f2.1.1");//ön taraf
            }
            else if(identityType != "" && identity2Verification != "approved" && identity2Verification != "pending"){
                setFormType("f2.2.1");//arka taraf
            }
            else if(customerImageVerification != "approved" && customerImageVerification != "pending"){
                setFormType("f2.3.1");//anlık fotoğraf çekimi
            }
            else if(residenceVerification != "approved" && residenceVerification != "pending"){
                setFormType("f2.4.1");//ikametgah belgesinin yüklenmesi
            }
            else if(identity1Verification == "pending" || identity2Verification == "pending" || customerImageVerification == "pending" || residenceVerification == "pending"){
                setFormType("f3.0.0");//onay bekleniyor
            }
            else{
                setFormType("f4.0.0");//doğrulama tamamlandı
            }
        }
        else{
            setFormType("f0.0.0");
        }
    }, [userInfo, infoVerification, phoneVerification, identityType, identity1Verification, identity2Verification, customerImageVerification, residenceVerification]);

    useEffect(() => {//formlar arasında geçiş yapılınca tetikleniyor. animasyon sağlıyoruz, bilgileri temizliyoruz ve verification kodunun yapıştırılmasını algılıyoruz.
        if(mainForm && mainForm.current){
            mainForm.current.style.animation = "none";
            let tempForAnimation = mainForm.current.offsetHeight;
            mainForm.current.style.animation = "open-animation 1.5s";
        }

        if(formType=="f0.0.0"){
            setFormNumber("01");
            setFormNumberInner("0");
            setActiveStep2(0);
            setActiveStep3(0);
        }
        if(formType=="f3.0.0" || formType=="f4.0.0"){
            setFormNumber("2");
            setFormNumberInner("0");
            setActiveStep2(1);
            setActiveStep3(4);
        }
        else if(formType=="f1.0.0"){
            setFormNumber("0");
            setFormNumberInner("0");
        }
        else if(formType=="f1.1.1" || formType=="f1.1.2" || formType=="f1.1.3"){
            setFormNumber("1");
            setFormNumberInner("1");
            setActiveStep2(0);
        }
        else if(formType=="f1.2.1" || formType=="f1.2.2" || formType=="f1.3.0"){
            setFormNumber("1");
            setFormNumberInner("2");
            if(formType=="f1.3.0"){
                setActiveStep2(2);
            }
            else{
                setActiveStep2(1);
            }
        }
        else if(formType=="f2.0.0" || formType=="f2.1.1"){
            setFormNumber("2");
            setFormNumberInner("1");
            setActiveStep3(0);
        }
        else if(formType=="f2.2.1"){
            setFormNumber("2");
            setFormNumberInner("2");
            setActiveStep3(1);
        }
        else if(formType=="f2.3.1"){
            setFormNumber("2");
            setFormNumberInner("3");
            setActiveStep3(2);
        }
        else if(formType=="f2.4.1"){
            setFormNumber("2");
            setFormNumberInner("4");
            setActiveStep3(3);
        }

        const handlePaste = (e) => {
            if(formType == "f1.2.2"){
                if(!pasteVerification(e, setInfoVerificationCode)){
                    toast.error("Verification code must be 6 digits, please check the code you pasted!");
                }
            }
        }
        window.addEventListener('paste', handlePaste);
        return () => window.removeEventListener('paste', handlePaste);
    }, [formType, setInfoVerificationCode]);

    useEffect(() => {//formlar arasında geçiş yapılınca tetikleniyor. animasyon sağlıyoruz, bilgileri temizliyoruz ve verification kodunun yapıştırılmasını algılıyoruz.
        if(mainFormOuter && mainFormOuter.current){
            mainFormOuter.current.style.animation = "none";
            let tempForAnimation = mainFormOuter.current.offsetHeight;
            mainFormOuter.current.style.animation = "open-animation 1.5s";
        }
    }, [formNumber]);

    useEffect(() => {//form numaralarının class isimleri belirleniyor
        if(infoVerification == "approved" && phoneVerification == "approved"){
            setFormNumber1ClassName("approved");
        }
        else if(infoVerification == "denied" || phoneVerification == "denied"){
            setFormNumber1ClassName("denied");
        }
        else{
            setFormNumber1ClassName("");
        }

        if(identity1Verification == "approved" && identity2Verification == "approved" && customerImageVerification == "approved" && residenceVerification == "approved"){
            setFormNumber2ClassName("approved");
        }
        else if(identity1Verification == "denied" || identity2Verification == "denied" || customerImageVerification == "denied" || residenceVerification == "denied"){
            setFormNumber2ClassName("denied");
        }
        else if(identity1Verification == "pending" || identity2Verification == "pending" || customerImageVerification == "pending" || residenceVerification == "pending"){
            setFormNumber2ClassName("pending");
        }
        else{
            setFormNumber2ClassName("");
        }

        if(formNumber == "1" || formNumber == "01"){
            if(infoVerification == "approved"){
                setFormNumberInner1ClassName("approved");
            }
            else if(infoVerification == "denied"){
                setFormNumberInner1ClassName("denied");
            }
            else{
                setFormNumberInner1ClassName("");
            }

            if(phoneVerification == "approved"){
                setFormNumberInner2ClassName("approved");
            }
            else if(phoneVerification == "denied"){
                setFormNumberInner2ClassName("denied");
            }
            else{
                setFormNumberInner2ClassName("");
            }
        }
        else if(formNumber == "2" || formNumber == "02"){
            if(identity1Verification == "approved"){
                setFormNumberInner1ClassName("approved");
            }
            else if(identity1Verification == "denied"){
                setFormNumberInner1ClassName("denied");
            }
            else if(identity1Verification == "pending"){
                setFormNumberInner1ClassName("pending");
            }
            else{
                setFormNumberInner1ClassName("");
            }

            if(identity2Verification == "approved"){
                setFormNumberInner2ClassName("approved");
            }
            else if(identity2Verification == "denied"){
                setFormNumberInner2ClassName("denied");
            }
            else if(identity2Verification == "pending"){
                setFormNumberInner2ClassName("pending");
            }
            else{
                setFormNumberInner2ClassName("");
            }

            if(customerImageVerification == "approved"){
                setFormNumberInner3ClassName("approved");
            }
            else if(customerImageVerification == "denied"){
                setFormNumberInner3ClassName("denied");
            }
            else if(customerImageVerification == "pending"){
                setFormNumberInner3ClassName("pending");
            }
            else{
                setFormNumberInner3ClassName("");
            }

            if(residenceVerification == "approved"){
                setFormNumberInner4ClassName("approved");
            }
            else if(residenceVerification == "denied"){
                setFormNumberInner4ClassName("denied");
            }
            else if(residenceVerification == "pending"){
                setFormNumberInner4ClassName("pending");
            }
            else{
                setFormNumberInner4ClassName("");
            }
        }
    }, [formType, formNumber, formNumberInner, infoVerification, phoneVerification, identityType, identity1Verification, identity2Verification, customerImageVerification, residenceVerification]);

    /*
    useEffect(() => {//spacerlar için class isimleri belirleniyor
        let temp_formNumberSpacer = "";
        let temp_formNumberInnerSpacer1 = "";
        let temp_formNumberInnerSpacer2 = "";
        let temp_formNumberInnerSpacer3 = "";
        temp_formNumberSpacer = formNumber1ClassName;
        if(formNumber1ClassName != "" || formNumber2ClassName != ""){
            temp_formNumberSpacer += "-";
        }
        temp_formNumberSpacer += formNumber2ClassName;
        setFormNumberSpacer(temp_formNumberSpacer);

        if(formNumber == "1" || formNumber == "01"){
            temp_formNumberInnerSpacer1 = formNumberInner1ClassName;
            if(formNumberInner1ClassName != "" || formNumberInner2ClassName != ""){
                temp_formNumberInnerSpacer1 += "-";
            }
            temp_formNumberInnerSpacer1 += formNumberInner2ClassName;
            setFormNumberInnerSpacer1(temp_formNumberInnerSpacer1);
        }
        else if(formNumber == "2" || formNumber == "02"){
            temp_formNumberInnerSpacer1 = formNumberInner1ClassName;
            if(formNumberInner1ClassName != "" || formNumberInner2ClassName != ""){
                temp_formNumberInnerSpacer1 += "-";
            }
            temp_formNumberInnerSpacer1 += formNumberInner2ClassName;
            setFormNumberInnerSpacer1(temp_formNumberInnerSpacer1);

            temp_formNumberInnerSpacer2 = formNumberInner2ClassName;
            if(formNumberInner2ClassName != "" || formNumberInner3ClassName != ""){
                temp_formNumberInnerSpacer2 += "-";
            }
            temp_formNumberInnerSpacer2 += formNumberInner3ClassName;
            setFormNumberInnerSpacer2(temp_formNumberInnerSpacer2);

            temp_formNumberInnerSpacer3 = formNumberInner3ClassName;
            if(formNumberInner3ClassName != "" || formNumberInner4ClassName != ""){
                temp_formNumberInnerSpacer3 += "-";
            }
            temp_formNumberInnerSpacer3 += formNumberInner4ClassName;
            setFormNumberInnerSpacer3(temp_formNumberInnerSpacer3);
        }
    }, [formNumber, formNumber1ClassName, formNumber2ClassName, formNumberInner1ClassName, formNumberInner2ClassName, formNumberInner3ClassName, formNumberInner4ClassName]);
    */

    useEffect(() => {//madde başlığındaki class ismi bir üst katmana taşınıyor
        const stepTitles = document.getElementsByClassName("step-title");

        if(stepTitles){
            let container = null;
            for (let i = 0; i < stepTitles.length; i++) {
                container = null;
                if(stepTitles[i]){
                    container = stepTitles[i].closest(".MuiStepLabel-root");
                    if(container){
                        container.classList.remove("clickable");
                        container.classList.remove("not-clickable");
                        container.classList.remove("approved");
                        container.classList.remove("pending");
                        container.classList.remove("denied");
                        container.classList.remove("active");
                        container.classList.remove("deactive");
                        for (let x = 0; x < stepTitles[i].classList.length; x++) {
                            if(stepTitles[i].classList[x] && stepTitles[i].classList[x] != "step-title"){
                                container.classList.add(stepTitles[i].classList[x]);
                            }
                        }
                    }
                }
            }
        }
    }, [formNumber, formNumberInner, formNumber1ClassName, formNumber2ClassName, formNumberInner1ClassName, formNumberInner2ClassName, formNumberInner3ClassName, formNumberInner4ClassName]);

    useEffect(() => {
        let monthName = "";

        if (infoDOBMonth === "1" || infoDOBMonth === "01") { monthName = "JAN"; }
        else if (infoDOBMonth === "2" || infoDOBMonth === "02") { monthName = "FEB"; }
        else if (infoDOBMonth === "3" || infoDOBMonth === "03") { monthName = "MAR"; }
        else if (infoDOBMonth === "4" || infoDOBMonth === "04") { monthName = "APR"; }
        else if (infoDOBMonth === "5" || infoDOBMonth === "05") { monthName = "MAY"; }
        else if (infoDOBMonth === "6" || infoDOBMonth === "06") { monthName = "JUN"; }
        else if (infoDOBMonth === "7" || infoDOBMonth === "07") { monthName = "JUL"; }
        else if (infoDOBMonth === "8" || infoDOBMonth === "08") { monthName = "AUG"; }
        else if (infoDOBMonth === "9" || infoDOBMonth === "09") { monthName = "SEP"; }
        else if (infoDOBMonth === "10") { monthName = "OCT"; }
        else if (infoDOBMonth === "11") { monthName = "NOV"; }
        else if (infoDOBMonth === "12") { monthName = "DEC"; }

        setInfoDOBMonthName(monthName);
    }, [infoDOBMonth]);






    //üst maddeler - başlangıç
    const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
        color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
        display: 'flex',
        height: 22,
        alignItems: 'center',
        ...(ownerState.active && {
          color: '#784af4',
        }),
        '& .QontoStepIcon-completedIcon': {
          color: '#784af4',
          zIndex: 1,
          fontSize: 18,
        },
        '& .QontoStepIcon-circle': {
          width: 8,
          height: 8,
          borderRadius: '50%',
          backgroundColor: 'currentColor',
        },
    }));
      
    function QontoStepIcon(props) {
        const { active, completed, className } = props;
      
        return (
          <QontoStepIconRoot ownerState={{ active }} className={className}>
            {completed ? (
              <Check className="QontoStepIcon-completedIcon" />
            ) : (
              <div className="QontoStepIcon-circle" />
            )}
          </QontoStepIconRoot>
        );
    }
      
    QontoStepIcon.propTypes = {
        /**
         * Whether this step is active.
         * @default false
         */
        active: PropTypes.bool,
        className: PropTypes.string,
        /**
         * Mark the step as completed. Is passed to child components.
         * @default false
         */
        completed: PropTypes.bool,
    };
      
    const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
        [`&.${stepConnectorClasses.alternativeLabel}`]: {
          top: 22,
        },
        [`&.${stepConnectorClasses.active}`]: {
          [`& .${stepConnectorClasses.line}`]: {
            backgroundImage:
              'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
          },
        },
        [`&.${stepConnectorClasses.completed}`]: {
          [`& .${stepConnectorClasses.line}`]: {
            backgroundImage:
              'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
          },
        },
        [`& .${stepConnectorClasses.line}`]: {
          height: 3,
          border: 0,
          backgroundColor:
            theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
          borderRadius: 1,
        },
    }));
      
    const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
        zIndex: 1,
        color: '#fff',
        width: 50,
        height: 50,
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
        ...(ownerState.active && {
          backgroundImage:
            'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
          boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
        }),
        ...(ownerState.completed && {
          backgroundImage:
            'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
        }),
    }));
      
    function ColorlibStepIcon(props) {
        const { active, completed, className } = props;
      
        const icons = {
          1: <Step1Icon />,
          2: <Step2Icon />,
          3: <Step3Icon />,
        };
      
        return (
          <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
            {icons[String(props.icon)]}
          </ColorlibStepIconRoot>
        );
    }
      
    ColorlibStepIcon.propTypes = {
        /**
         * Whether this step is active.
         * @default false
         */
        active: PropTypes.bool,
        className: PropTypes.string,
        /**
         * Mark the step as completed. Is passed to child components.
         * @default false
         */
        completed: PropTypes.bool,
        /**
         * The label displayed in the step icon.
         */
        icon: PropTypes.node,
    };
    //üst maddeler - bitiş

    //alt maddeler - başlangıç
    const steps1 = [
        {
            label: <div className={`step-title active approved`}>Email Verification</div>,
            description: <>
                <div className="main-form" ref={mainForm}>
                    {pageType != "readonly" && formType == "f1.0.0" && <InfoForm verification="approved" infoTextLower="email" infoTextUpper="Email" />}
                </div>
            </>,
        }
    ];

    const steps2 = [
        {
            label: <div className={`step-title ${formNumberInner == "1" ? "active" : "deactive"} ${formNumbersClickControl_class("1", "inner")} ${formNumberInner1ClassName}`} onClick={() => formNumbersClickControl_function("1", "inner")}>Info Verification</div>,
            description: <>
                <div className="main-form" ref={mainForm}>
                    {pageType != "readonly" && formType == "f1.1.1" && (
                    infoVerification != "approved" && infoVerification != "pending" ? <>
                        <div className="title">
                            <p>Please choose your residential country!</p>
                        </div>
                        <div className="inputs">
                            <label>Residential Country</label>
                            <CountryPicker
                                title="Residential Country"
                                selectedCountry={infoResidence}
                                setSelectedCountry={(code) => enterResidence(false, code)}
                                id="info_placeofresidence"
                            />
                        </div>
                        <button type="button" className="submit-button" onClick={() => enterResidence(true, null)}>Continue</button>
                    </>
                    : <InfoForm verification={infoVerification} infoTextLower="info" infoTextUpper="Info" />)}
                    {pageType != "readonly" && formType == "f1.1.2" && (
                    infoVerification != "approved" && infoVerification != "pending" ? <>
                        <div className="inputs">
                            <label>Nationality</label>
                            <CountryPicker
                                title="Nationality"
                                selectedCountry={infoNationality}
                                setSelectedCountry={(code) => enterIdentity(false, code, "Nationality")}
                                id="info_nationality"
                            />
                        </div>
                        <div className="inputs">
                            <label>{countries[infoResidence] ? countries[infoResidence] : infoResidence} Identity Number</label>
                            <input type="text" placeholder="Identity Number" id="info_identitynumber" value={infoIdentityNumber} onChange={(e) => enterIdentity(false, e.target.value, "Identity Number")} />
                        </div>
                        <div className="inputs">
                            <label>First Name</label>
                            <input type="text" placeholder="First Name" id="info_firstname" value={infoFirstName} onChange={(e) => enterIdentity(false, e.target.value, "First Name")} />
                            <div className="optional-title"><label>Middle Name</label><span>(Optional)</span></div>
                            <input type="text" placeholder="Middle Name" id="info_middlename" value={infoMiddleName} onChange={(e) => enterIdentity(false, e.target.value, "Middle Name")} />
                            <label>Last Name</label>
                            <input type="text" placeholder="Last Name" id="info_lastname" value={infoLastName} onChange={(e) => enterIdentity(false, e.target.value, "Last Name")} />
                        </div>
                        <div className="inputs inputs3">
                            <div>
                                <label>Date of Birth</label>
                            </div>
                            <div>
                                <input type="text" placeholder="YYYY" maxLength="4" value={infoDOBYear} id="info_year" onBlur={(e) => setInfoDOBYear(DOBControl(e, "year", true))} onChange={(e) => setInfoDOBYear(DOBControl(e, "year", false))} />
                                <input type="text" placeholder="MM" maxLength="2" value={infoDOBMonth} id="info_month" ref={infoDOBMonthRef} onBlur={(e) => setInfoDOBMonth(DOBControl(e, "month", true))} onChange={(e) => setInfoDOBMonth(DOBControl(e, "month", false))} />
                                <input type="text" placeholder="DD" maxLength="2" value={infoDOBDay} id="info_day" onBlur={(e) => setInfoDOBDay(DOBControl(e, "day", true))} onChange={(e) => setInfoDOBDay(DOBControl(e, "day", false))} />
                                {infoDOBMonthName != "" && <span onClick={() => infoDOBMonthRef.current.focus()}>{infoDOBMonthName}</span>}
                            </div>
                        </div>
                        <button type="button" className="submit-button" onClick={() => enterIdentity(true, null, "Continue")}>Continue</button>
                    </>
                    : <InfoForm verification={infoVerification} infoTextLower="info" infoTextUpper="Info" />)}
                    {pageType != "readonly" && formType == "f1.1.3" && (
                    infoVerification != "approved" && infoVerification != "pending" ? <>
                        <div className="inputs">
                            <label>Residential Address</label>
                            <textarea type="text" placeholder="Residential Address" id="info_residentialaddress" value={infoResidentialAddress} onChange={(e) => submitInfoVerification(false, e.target.value, "Residential Address")} />
                            <label>Postal Code</label>
                            <input type="text" placeholder="Postal Code" id="info_postalcode" value={infoPostalCode} onChange={(e) => submitInfoVerification(false, e.target.value, "Postal Code")} />
                            <label>City</label>
                            <input type="text" placeholder="City" id="info_city" value={infoCity} onChange={(e) => submitInfoVerification(false, e.target.value, "City")} />
                        </div>
                        <button type="button" className="submit-button" onClick={() => submitInfoVerification(true, null, "Submit")} disabled={submitInfoVerificationLoading}>{submitInfoVerificationLoading ? <Loading /> : "Submit"}</button>
                    </>
                    : <InfoForm verification={infoVerification} infoTextLower="info" infoTextUpper="Info" />)}
                </div>
            </>,
        },
        {
            label: <div className={`step-title ${formNumberInner == "2" ? "active" : "deactive"} ${formNumbersClickControl_class("2", "inner")} ${formNumberInner2ClassName}`} onClick={() => formNumbersClickControl_function("2", "inner")}>Phone Verification</div>,
            description: <>
                <div className="main-form" ref={mainForm}>
                    {pageType != "readonly" && formType == "f1.2.1" && (
                    phoneVerification != "approved" && phoneVerification != "pending" ? <>
                        <div className="title">
                            <p>Please enter your phone number!</p>
                        </div>
                        <div className="inputs">
                            <label>Phone Number</label>
                            <div className="phone-number-area">
                                <CountryPhoneCodePicker
                                    selectedCountry={selectedPhoneCountry}
                                    setSelectedCountry={(code) => setSelectedPhoneCountry(code)}
                                    selectedCountryPhoneCode={selectedPhoneCountryCode}
                                    setSelectedCountryPhoneCode={(code) => setSelectedPhoneCountryCode(code)}
                                />
                                <span>{selectedPhoneCountryCode}</span>
                                <input type="text" ref={infoPhoneRef} onChange={() => submitPhone(false)} style={{paddingLeft: selectedPhoneCountryCode ? ((selectedPhoneCountryCode.length * (viewType == "pc" ? 10 : 6)) + 20) : 20}} />
                            </div>
                        </div>
                        <button type="button" className="submit-button" onClick={() => submitPhone(true)} disabled={submitPhoneVerificationLoading}>{submitPhoneVerificationLoading ? <Loading /> : "Submit"}</button>
                    </>
                    : <InfoForm verification={phoneVerification} infoTextLower="phone" infoTextUpper="Phone" />)}
                    {pageType != "readonly" && formType == "f1.2.2" && (
                    phoneVerification != "approved" && phoneVerification != "pending" ? <>
                        <div className="title">
                            <p>Please check your phone for the verification code!</p>
                        </div>
                        <div className="inputs verification">
                            {infoVerificationCode.map((code, index) => (
                                <input
                                    key={index}
                                    type="text"
                                    placeholder="-"
                                    value={code}
                                    onChange={(e) => verificationOnChange(e, index, infoVerificationCode, setInfoVerificationCode)}
                                    onKeyDown={(e) => verificationOnKeyDown(e, index, setInfoVerificationCode)}
                                    autoComplete="off"
                                    name={`unique-name-${index}`}
                                />
                            ))}
                        </div>
                        <button type="button" className="submit-button" onClick={() => verifyPhone()} disabled={submitPhoneVerifyLoading}>{submitPhoneVerifyLoading ? <Loading /> : "Verify"}</button>
                        <div className="register" onClick={() => {if(!resendCodeLock){resendCode()}}}>
                            <a className={resendCodeLock ? "locked" : undefined}>Resend Code</a>
                            {resendCodeLock ? <CountdownTimer timestamp={resendCodeTimestamp} addSeconds={60} onTimerEnd={setResendCodeLock} format="second" /> : ""}
                        </div>
                    </>
                    : <InfoForm verification={phoneVerification} infoTextLower="phone" infoTextUpper="Phone" />)}
                </div>
            </>,
        },
    ];

    const steps3 = [
        {
            label: <div className={`step-title ${formNumberInner == "1" ? "active" : "deactive"} ${formNumbersClickControl_class("1", "inner")} ${formNumberInner1ClassName}`} onClick={() => formNumbersClickControl_function("1", "inner")}>Front ID Image</div>,
            description: <>
                <div className="main-form" ref={mainForm}>
                    {pageType != "readonly" && formType == "f2.0.0" && identity1Verification != "approved" && identity1Verification != "pending" && identity2Verification != "approved" && identity2Verification != "pending" ? <>
                        <div className="title">
                            <p>Please choose your identity type!</p>
                        </div>
                        <div className="selection-buttons">
                            <div className={submitIdentityTypeLoading1 || submitIdentityTypeLoading2 || submitIdentityTypeLoading3 ? "item disabled" : "item"} onClick={() => {if(!submitIdentityTypeLoading1 && !submitIdentityTypeLoading2 && !submitIdentityTypeLoading3){submitIdentityType("ID Card");}}}>
                                {submitIdentityTypeLoading1 ? 
                                <Loading />
                                : <><i className="fa-solid fa-id-card"></i>
                                <label>ID Card</label></>}
                            </div>
                            <div className={submitIdentityTypeLoading1 || submitIdentityTypeLoading2 || submitIdentityTypeLoading3 ? "item disabled" : "item"} onClick={() => {if(!submitIdentityTypeLoading1 && !submitIdentityTypeLoading2 && !submitIdentityTypeLoading3){submitIdentityType("Driving Licence");}}}>
                                {submitIdentityTypeLoading2 ? 
                                <Loading />
                                : <><i className="fa-solid fa-car-side"></i>
                                <label>Driving Licence</label></>}
                            </div>
                            <div className={submitIdentityTypeLoading1 || submitIdentityTypeLoading2 || submitIdentityTypeLoading3 ? "item disabled" : "item"} onClick={() => {if(!submitIdentityTypeLoading1 && !submitIdentityTypeLoading2 && !submitIdentityTypeLoading3){submitIdentityType("Passport");}}}>
                                {submitIdentityTypeLoading3 ? 
                                <Loading />
                                : <><i className="fa-solid fa-passport"></i>
                                <label>Passport</label></>}
                            </div>
                        </div>
                    </> : ""}
                    {pageType != "readonly" && formType == "f2.1.1" && 
                        <>
                            {((identity1Verification == "" && identity2Verification == "") || (identity1Verification == "denied" && identity2Verification == "denied")) && 
                                <div style={{marginBottom:25,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                                    <button onClick={() => setFormType("f2.0.0")} type="button" className="submit-button" style={{height: '40px', padding: '0px 20px', width: 'auto', marginTop: '0px', fontSize: '14px'}}>
                                        <span>Change Identity Type</span>
                                        <i className="fa-solid fa-arrows-rotate" style={{margin: '0px', marginLeft: '7px'}}></i>
                                    </button>
                                </div>
                            }
                            {identityType != "" && identity1Verification != "approved" && identity1Verification != "pending" ? 
                                <>
                                    <div className="title">
                                        <p>Please upload the front side of your {identityType}!</p>
                                    </div>
                                    <div className="inputs">
                                        <label>Front Side of {identityType}</label>
                                    </div>
                                    {infoIdentity1URL && <img src={infoIdentity1URL} alt="Preview" className="preview" />}
                                    {identity1PhotoURL && <img src={identity1PhotoURL} alt="Preview" className="preview" />}
                                    {identity1PhotoArea && 
                                        (error ? 
                                        (
                                            <div className="title danger">
                                                <p>Access to the camera is blocked. Please allow access to the camera through your browser and device settings. If the camera is not visible even though you have given access permission, refresh the page.</p>
                                            </div>
                                        ) : (
                                        <>
                                            {cameras.length > 1 && (
                                                <div className="inputs">
                                                    <select onChange={(e) => switchCamera(e.target.value)} value={currentCamera?.deviceId} className="fake-input camera">
                                                    {cameras.map((camera, index) => (
                                                        <option key={index} value={camera.deviceId}>{camera.label || `Camera ${index + 1}`}</option>
                                                    ))}
                                                    </select>
                                                </div>
                                            )}
                                            <video ref={videoIdentity1Ref} className="preview" autoPlay playsInline muted></video>
                                        </>))
                                    }
                                    <div className="inputs">
                                        <div className="file-buttons">
                                            <div className="fake-input" id="infoidentity1" onClick={() => infoIdentity1Ref.current.click()}>
                                                <input type="file" accept=".png, .jpg, .jpeg" style={{display: "none"}} ref={infoIdentity1Ref} onChange={() => {setIdentity1PhotoType("select");submitIdentity1(false, "select");}} />
                                                {infoIdentity1Name == "" ?
                                                <><span>Select File</span>
                                                <i className="fa-solid fa-cloud-arrow-up"></i></>
                                                : <><span>{infoIdentity1Name}</span>
                                                <i className="fa-solid fa-circle-check"></i></>}
                                            </div>
                                            {identity1PhotoArea ? <button type="button" className="submit-button" onClick={() => takeYourPhoto1()}><i className="fa-solid fa-camera"></i>Take Photo</button>
                                            : <button type="button" className="submit-button" onClick={() => openCamera1()}>Open Camera</button>}
                                        </div>
                                    </div>
                                    <button type="button" className="submit-button" onClick={() => submitIdentity1(true)} disabled={submitIdentity1Loading}>{submitIdentity1Loading ? <Loading /> : "Submit"}</button>
                                </>
                                : <InfoForm verification={identity1Verification} infoTextLower={`front side of your ${identityType}`} infoTextUpper={`Front side of your ${identityType}`} />}
                        </>}
                </div>
            </>,
        },
        {
            label: <div className={`step-title ${formNumberInner == "2" ? "active" : "deactive"} ${formNumbersClickControl_class("2", "inner")} ${formNumberInner2ClassName}`} onClick={() => formNumbersClickControl_function("2", "inner")}>Back ID Image</div>,
            description: <>
                <div className="main-form" ref={mainForm}>
                    {pageType != "readonly" && formType == "f2.2.1" && (
                    identityType != "" && identity2Verification != "approved" && identity2Verification != "pending" ? <>
                        <div className="title">
                            <p>Please upload the back side of your {identityType}!</p>
                        </div>
                        <div className="inputs">
                            <label>Front Side of {identityType}</label>
                        </div>
                        {infoIdentity2URL && <img src={infoIdentity2URL} alt="Preview" className="preview" />}
                        {identity2PhotoURL && <img src={identity2PhotoURL} alt="Preview" className="preview" />}
                        {identity2PhotoArea && 
                            (error ? 
                            (
                                <div className="title danger">
                                    <p>Access to the camera is blocked. Please allow access to the camera through your browser and device settings. If the camera is not visible even though you have given access permission, refresh the page.</p>
                                </div>
                            ) : (
                            <>
                                {cameras.length > 1 && (
                                    <div className="inputs">
                                        <select onChange={(e) => switchCamera(e.target.value)} value={currentCamera?.deviceId} className="fake-input camera">
                                        {cameras.map((camera, index) => (
                                            <option key={index} value={camera.deviceId}>{camera.label || `Camera ${index + 1}`}</option>
                                        ))}
                                        </select>
                                    </div>
                                )}
                                <video ref={videoIdentity2Ref} className="preview" autoPlay playsInline muted></video>
                            </>))
                        }
                        <div className="inputs">
                            <div className="file-buttons">
                                <div className="fake-input" id="infoidentity2" onClick={() => infoIdentity2Ref.current.click()}>
                                    <input type="file" accept=".png, .jpg, .jpeg" style={{display: "none"}} ref={infoIdentity2Ref} onChange={() => {setIdentity2PhotoType("select");submitIdentity2(false, "select");}} />
                                    {infoIdentity2Name == "" ?
                                    <><span>Select File</span>
                                    <i className="fa-solid fa-cloud-arrow-up"></i></>
                                    : <><span>{infoIdentity2Name}</span>
                                    <i className="fa-solid fa-circle-check"></i></>}
                                </div>
                                {identity2PhotoArea ? <button type="button" className="submit-button" onClick={() => takeYourPhoto2()}><i className="fa-solid fa-camera"></i>Take Photo</button>
                                : <button type="button" className="submit-button" onClick={() => openCamera2()}>Open Camera</button>}
                            </div>
                        </div>
                        <button type="button" className="submit-button" onClick={() => submitIdentity2(true)} disabled={submitIdentity2Loading}>{submitIdentity2Loading ? <Loading /> : "Submit"}</button>
                    </>
                    : <InfoForm verification={identity2Verification} infoTextLower={`back side of your ${identityType}`} infoTextUpper={`Back side of your ${identityType}`} />)}
                </div>
            </>,
        },
        {
            label: <div className={`step-title ${formNumberInner == "3" ? "active" : "deactive"} ${formNumbersClickControl_class("3", "inner")} ${formNumberInner3ClassName}`} onClick={() => formNumbersClickControl_function("3", "inner")}>User Photo</div>,
            description: <>
                <div className="main-form" ref={mainForm}>
                    {pageType != "readonly" && formType == "f2.3.1" && (
                    customerImageVerification != "approved" && customerImageVerification != "pending" ? <>
                        {error ? 
                        (
                            <div className="title danger">
                                <p>Access to the camera is blocked. Please allow access to the camera through your browser and device settings. If the camera is not visible even though you have given access permission, refresh the page.</p>
                            </div>
                        ) : (
                            <>
                                <div className="title">
                                    <p>Please take your photo!</p>
                                </div>
                                <div className="inputs">
                                    <label>Your Photo</label>
                                    {customerImagePhotoArea ? 
                                    <>{cameras.length > 1 && (
                                        <select onChange={(e) => switchCamera(e.target.value)} value={currentCamera?.deviceId} className="fake-input camera">
                                        {cameras.map((camera, index) => (
                                            <option key={index} value={camera.deviceId}>{camera.label || `Camera ${index + 1}`}</option>
                                        ))}
                                        </select>
                                    )}
                                    <video ref={videoRef} className="preview" autoPlay playsInline muted></video>
                                    <button type="button" className="submit-button other-button" onClick={() => takeYourPhoto()}><i className="fa-solid fa-camera"></i>Take Photo</button>
                                    </>
                                    : <>
                                    <img src={customerImagePhotoURL} alt="Preview" className="preview" />
                                    <button type="button" className="submit-button other-button" onClick={() => {setCustomerImagePhoto("");setCustomerImagePhotoURL("");setCustomerImagePhotoArea(true);}}><i className="fa-solid fa-camera-rotate"></i>Open Camera</button>
                                    <button type="button" className="submit-button" onClick={() => submitCustomerImage()} disabled={submitCustomerImageLoading}>{submitCustomerImageLoading ? <Loading /> : "Submit"}</button>
                                    </>}
                                </div>
                            </>
                        )}
                    </>
                    : <InfoForm verification={customerImageVerification} infoTextLower="your photo" infoTextUpper="Your photo" />)}
                </div>
            </>,
        },
        {
            label: <div className={`step-title ${formNumberInner == "4" ? "active" : "deactive"} ${formNumbersClickControl_class("4", "inner")} ${formNumberInner4ClassName}`} onClick={() => formNumbersClickControl_function("4", "inner")}>Residence Image</div>,
            description: <>
                <div className="main-form" ref={mainForm}>
                    {pageType != "readonly" && formType == "f2.4.1" && (
                    residenceVerification != "approved" && residenceVerification != "pending" ? <>
                        <div className="title">
                            <p>Please upload the residence!</p>
                        </div>
                        <div className="inputs">
                            <label>Residence</label>
                        </div>
                        {infoResidenceURL && <img src={infoResidenceURL} alt="Preview" className="preview" />}
                        {residencePhotoURL && <img src={residencePhotoURL} alt="Preview" className="preview" />}
                        {residencePhotoArea && 
                            (error ? 
                            (
                                <div className="title danger">
                                    <p>Access to the camera is blocked. Please allow access to the camera through your browser and device settings. If the camera is not visible even though you have given access permission, refresh the page.</p>
                                </div>
                            ) : (
                            <>
                                {cameras.length > 1 && (
                                    <div className="inputs">
                                        <select onChange={(e) => switchCamera(e.target.value)} value={currentCamera?.deviceId} className="fake-input camera">
                                        {cameras.map((camera, index) => (
                                            <option key={index} value={camera.deviceId}>{camera.label || `Camera ${index + 1}`}</option>
                                        ))}
                                        </select>
                                    </div>
                                )}
                                <video ref={videoResidenceRef} className="preview" autoPlay playsInline muted></video>
                            </>))
                        }
                        <div className="inputs">
                            <div className="file-buttons">
                                <div className="fake-input" id="inforesidence" onClick={() => infoResidenceRef.current.click()}>
                                    <input type="file" accept=".png, .jpg, .jpeg" style={{display: "none"}} ref={infoResidenceRef} onChange={() => {setResidencePhotoType("select");submitResidence(false, "select");}} />
                                    {infoResidenceName == "" ?
                                    <><span>Select File</span>
                                    <i className="fa-solid fa-cloud-arrow-up"></i></>
                                    : <><span>{infoResidenceName}</span>
                                    <i className="fa-solid fa-circle-check"></i></>}
                                </div>
                                {residencePhotoArea ? <button type="button" className="submit-button" onClick={() => takeYourPhoto4()}><i className="fa-solid fa-camera"></i>Take Photo</button>
                                : <button type="button" className="submit-button" onClick={() => openCamera4()}>Open Camera</button>}
                            </div>
                        </div>
                        <button type="button" className="submit-button" onClick={() => submitResidence(true)} disabled={submitResidenceLoading}>{submitResidenceLoading ? <Loading /> : "Submit"}</button>
                    </>
                    : <InfoForm verification={residenceVerification} infoTextLower="residence" infoTextUpper="Residence" />)}
                </div>
            </>,
        },
    ];
    //alt maddeler - bitiş

    return(
        <div className={pageType != "readonly" ? "section-verification-steps" : "section-verification-steps readonly"}>
            {formType != "f0.0.0" && <div className="form-numbers">
                <Stepper alternativeLabel activeStep={Number(formNumber)} connector={<ColorlibConnector />}>
                    <Step key="Register" className={`outer-step-title ${formNumber == "0" ? "active" : "deactive"} clickable approved`} onClick={() => formNumbersClickControl_function("0", "outer")}>
                        <StepLabel StepIconComponent={ColorlibStepIcon}><div className="title">Register</div><div className="level">{userInfo && userInfo.status_level && (userInfo.status_level == "1" || userInfo.status_level == "2" || userInfo.status_level == "3") && <i className="fa-solid fa-crown"></i>}Lvl 1</div></StepLabel>
                    </Step>
                    <Step key="Informations" className={`outer-step-title ${formNumber == "1" ? "active" : "deactive"} ${formNumbersClickControl_class("1", "outer")} ${formNumber1ClassName}`} onClick={() => formNumbersClickControl_function("1", "outer")}>
                        <StepLabel StepIconComponent={ColorlibStepIcon}><div className="title">Informations</div><div className="level">{userInfo && userInfo.status_level && (userInfo.status_level == "2" || userInfo.status_level == "3") && <i className="fa-solid fa-crown"></i>}Lvl 2</div></StepLabel>
                    </Step>
                    <Step key="Images" className={`outer-step-title ${formNumber == "2" ? "active" : "deactive"} ${formNumbersClickControl_class("2", "outer")} ${formNumber2ClassName}`} onClick={() => formNumbersClickControl_function("2", "outer")}>
                        <StepLabel StepIconComponent={ColorlibStepIcon}><div className="title">Images</div><div className="level">{userInfo && userInfo.status_level && userInfo.status_level == "3" && <i className="fa-solid fa-crown"></i>}Lvl 3</div></StepLabel>
                    </Step>
                </Stepper>
            </div>}
            {formType == "f0.0.0" ? <>
                <div className="loading">
                    <Loading />
                </div>
            </>
            :
            <div className="main-form-outer" ref={mainFormOuter}>
                <Box>
                    {formNumber == "0" ? 
                        <Stepper activeStep={1} orientation="vertical">
                            {steps1.map((step, index) => (
                            <Step key={`${step.label}-${index}`}>
                                <StepLabel>{step.label}</StepLabel>
                                <StepContent>
                                    <Typography>{step.description}</Typography>
                                </StepContent>
                            </Step>
                            ))}
                        </Stepper>
                    :
                        (formNumber == "1" || formNumber == "01" ? 
                            <Stepper activeStep={activeStep2} orientation="vertical">
                                {steps2.map((step, index) => (
                                <Step key={`${step.label}-${index}`}>
                                    <StepLabel>{step.label}</StepLabel>
                                    <StepContent>
                                        <Typography>{step.description}</Typography>
                                    </StepContent>
                                </Step>
                                ))}
                            </Stepper>
                        : 
                            <Stepper activeStep={activeStep3} orientation="vertical">
                                {steps3.map((step, index) => (
                                <Step key={`${step.label}-${index}`}>
                                    <StepLabel>{step.label}</StepLabel>
                                    <StepContent>
                                        <Typography>{step.description}</Typography>
                                    </StepContent>
                                </Step>
                                ))}
                            </Stepper>)
                    }
                    {formType == "f1.0.0" && (
                        <Paper square elevation={0} sx={{ p: 3 }}>
                            <Typography>
                                <div className="main-form" ref={mainForm}>
                                    <InfoForm verification="approved" infoTextLower="section all" infoTextUpper="Email" />
                                </div>
                            </Typography>
                        </Paper>
                    )}
                    {formType == "f1.3.0" && (
                        <Paper square elevation={0} sx={{ p: 3 }}>
                            <Typography>
                                <div className="main-form" ref={mainForm}>
                                    <InfoForm verification="approved" infoTextLower="section all" infoTextUpper="Informations" />
                                </div>
                            </Typography>
                        </Paper>
                    )}
                    {formType == "f3.0.0" && (
                        <Paper square elevation={0} sx={{ p: 3 }}>
                            <Typography>
                                <div className="main-form" ref={mainForm}>
                                    <InfoForm verification="pending" infoTextLower="all" infoTextUpper="All" />
                                </div>
                            </Typography>
                        </Paper>
                    )}
                    {formType == "f4.0.0" && (
                        <Paper square elevation={0} sx={{ p: 3 }}>
                            <Typography>
                                <div className="main-form" ref={mainForm}>
                                    <InfoForm verification="approved" infoTextLower="all" infoTextUpper="All" />
                                </div>
                            </Typography>
                        </Paper>
                    )}
                </Box>
            </div>
            }
        </div>
    )

}

export default VerificationSteps;