import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { Link } from 'react-router-dom';
import Loading from "../components/Global/Loading";

const VerificationKYC = () => {

    const userInfo = useSelector((state) => state.kprice.userInfo.data);
    const [kycVerificationTabValue, setKycVerificationTabValue] = useState("1");
    const kycVerificationForm = useRef(null);

    useEffect(() => {//kyc verification sekme değeri değişirse
        if(kycVerificationForm && kycVerificationForm.current){
            kycVerificationForm.current.style.animation = "none";
            let tempForAnimation = kycVerificationForm.current.offsetHeight;
            kycVerificationForm.current.style.animation = "open-animation 1.5s";
        }
    }, [kycVerificationTabValue]);

    return(
        <div className="kyc-verification">
            {userInfo && userInfo.customer_id ? <>
                <div className="tab">
                    <div className={kycVerificationTabValue == "1" ? "active" : undefined} onClick={() => setKycVerificationTabValue("1")}>{userInfo && userInfo.status_level && (userInfo.status_level == "1" || userInfo.status_level == "2" || userInfo.status_level == "3") && <i className='bx bxs-badge-check'></i>} Level 1</div>
                    <div className={kycVerificationTabValue == "2" ? "active" : undefined} onClick={() => setKycVerificationTabValue("2")}>{userInfo && userInfo.status_level && (userInfo.status_level == "2" || userInfo.status_level == "3") && <i className='bx bxs-badge-check'></i>} Level 2</div>
                    <div className={kycVerificationTabValue == "3" ? "active" : undefined} onClick={() => setKycVerificationTabValue("3")}>{userInfo && userInfo.status_level && (userInfo.status_level == "3") && <i className='bx bxs-badge-check'></i>} Level 3</div>
                </div>
                <div className="info" ref={kycVerificationForm}>
                    {kycVerificationTabValue == "1" && <>
                        <div className="item">
                            <label>Spot Transaction Permission</label>
                            <span>Not Available</span>
                        </div>
                        <div className="item">
                            <label>Future Transaction Permission</label>
                            <span>Not Available</span>
                        </div>
                        <div className="item">
                            <label>Deposit</label>
                            <span>Not Available</span>
                        </div>
                        <div className="item">
                            <label>Withdraw</label>
                            <span>Not Available</span>
                        </div>
                        <div className="needed">
                            What is needed?
                        </div>
                        <div className="needed-info">
                            <i className="fa-solid fa-envelope"></i>
                            Email Verification
                        </div>
                        <div className="buttons">
                            <Link to="/verification/steps" className="button">Go Verification</Link>
                        </div>
                    </>}
                    {kycVerificationTabValue == "2" && <>
                        <div className="item">
                            <label>Spot Transaction Permission</label>
                            <span>Available</span>
                        </div>
                        <div className="item">
                            <label>Future Transaction Permission</label>
                            <span>Available</span>
                        </div>
                        <div className="item">
                            <label>Deposit</label>
                            <span>Available</span>
                        </div>
                        <div className="item">
                            <label>Withdraw</label>
                            <span>Not Available</span>
                        </div>
                        <div className="needed">
                            What is needed?
                        </div>
                        <div className="needed-info">
                            <i className="fa-solid fa-file-lines"></i>
                            Info Verification
                        </div>
                        <div className="needed-info">
                            <i className="fa-solid fa-phone"></i>
                            Phone Verification
                        </div>
                        <div className="buttons">
                            <Link to="/verification/steps" className="button">Go Verification</Link>
                        </div>
                    </>}
                    {kycVerificationTabValue == "3" && <>
                        <div className="item">
                            <label>Spot Transaction Permission</label>
                            <span>Available</span>
                        </div>
                        <div className="item">
                            <label>Future Transaction Permission</label>
                            <span>Available</span>
                        </div>
                        <div className="item">
                            <label>Deposit</label>
                            <span>Available</span>
                        </div>
                        <div className="item">
                            <label>Withdraw</label>
                            <span>Available</span>
                        </div>
                        <div className="needed">
                            What is needed?
                        </div>
                        <div className="needed-info">
                            <i className="fa-solid fa-address-card"></i>
                            Front ID Image
                        </div>
                        <div className="needed-info">
                            <i className="fa-regular fa-address-card"></i>
                            Back ID Image
                        </div>
                        <div className="needed-info">
                            <i className="fa-solid fa-image-portrait"></i>
                            User Photo
                        </div>
                        <div className="needed-info">
                            <i className="fa-solid fa-file-image"></i>
                            Residence Image
                        </div>
                        <div className="buttons">
                            <Link to="/verification/steps" className="button">Go Verification</Link>
                        </div>
                    </>}
                </div>
            </>
            : <Loading />}
        </div>
    )

}

export default VerificationKYC;