import React from "react";
import { useSelector } from "react-redux";

const withLoadingData = () => (OriginalComponent) => (props) => {
  const isProductsLoading = useSelector(
    (state) => state.Loading["coin/GET_PRODUCT_IDS"]
  );
  const isNotificationsLoading = useSelector(
    (state) => state.Loading["coin/GET_NOTIFICATIONS"]
  );
  const isWalletLoading = useSelector(
    (state) => state.Loading["coin/GET_WALLET_VALUE"]
  );
  const isCandleLoading = useSelector(
    (state) => state.Loading["coin/GET_ONE_COIN_CANDLES"]
  );
  const isOrderHistoryLoading = useSelector(
    (state) => state.Loading["coin/GET_ORDER_HISTORY"]
  );
  const isFutureOrderHistoryLoading = useSelector(
    (state) => state.Loading["coin/GET_FUTURE_ORDER_HISTORY"]
  );
  const isFutureProcessesLoading = useSelector(
    (state) => state.Loading["coin/GET_FUTURE_PROCESSES"]
  );
  const isFutureProfilesLoading = useSelector(
    (state) => state.Loading["coin/GET_FUTURE_PROFILES"]
  );
  const isProduct24hDataLoading = useSelector(
    (state) => state.Loading["coin/GET_PRODUCT_LAST_24H"]
  );
  const isLastTradesAndOrderBookLoading = useSelector(
    (state) => state.Loading["coin/GET_LAST_TRADES_AND_ORDER_BOOK"]
  );

  return (
    <OriginalComponent
      {...props}
      isProductsLoading={isProductsLoading}
      isNotificationsLoading={isNotificationsLoading}
      isWalletLoading={isWalletLoading}
      isCandleLoading={isCandleLoading}
      isOrderHistoryLoading={isOrderHistoryLoading}
      isFutureOrderHistoryLoading={isFutureOrderHistoryLoading}
      isFutureProcessesLoading={isFutureProcessesLoading}
      isFutureProfilesLoading={isFutureProfilesLoading}
      isProduct24hDataLoading={isProduct24hDataLoading}
      isLastTradesAndOrderBookLoading={isLastTradesAndOrderBookLoading}
    />
  );
};

export default withLoadingData;
