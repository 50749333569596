import React, { useState, useEffect, useRef } from "react";
import { Auth } from 'aws-amplify';
import { toast } from 'react-toastify';
import 'material-react-toastify/dist/ReactToastify.css';
import "./page_changepassword.css";
import Loading from "../components/Global/Loading";
import axios from 'axios';
import { ConfirmDialog } from 'primereact/confirmdialog';

const ChangePassword = () => {
    
    const [firstProcess, setFirstProcess] = useState(false);

    const [modalVisible, setModalVisible] = useState(false);
    const [deleteAccountLoading, setDeleteAccountLoading] = useState(false);

    const submitRequest = async () => {
        setModalVisible(false);
        if(!deleteAccountLoading){
            setDeleteAccountLoading(true);
            const session = await Auth.currentSession();
            const jwtToken = session.getIdToken().getJwtToken();

            axios.post('https://api.kpricemarket.com/delete-account', {},
                { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${jwtToken}` } }
            )
            .then(function (response) {
                if (response.status == "200") {
                    toast.success("Your account deletion request has been submitted successfully!");
                }
                else {//hatalı durum için
                    toast.error(`There was a problem sending the account deletion request!${response.data.message ? ` (${response.data.message})` : ""}`);
                }
                setDeleteAccountLoading(false);
            })
            .catch(function (error) {
                toast.error(error.response.data.error);
                setDeleteAccountLoading(false);
            });
        }
    }
    
    return(
        <div id="main" className="main">
            <ConfirmDialog 
                visible={modalVisible}
                draggable={false}
                resizable={false}
                onHide={() => setModalVisible(false)}
                message="Are you sure you want to submit an account deletion request?"
                header="Delete Account"
                icon="pi pi-info-circle"
                accept={() => submitRequest()}
                reject={() => setModalVisible(false)}
            />
            <div className="allsections allsections-border scrollbarhide section-changepassword">
                <div className="main-form">
                    <div className="title">
                        <p>Please read before deleting your account</p>
                    </div>
                    <div className="info-area">
                        <span>1. After this process, you will no longer be able to use your account.</span>
                        <span>2. Your data will be retained in accordance with relevant regulations.</span>
                    </div>
                    <button type="button" onClick={() => setModalVisible(true)} disabled={deleteAccountLoading}>{deleteAccountLoading ? <Loading /> : "Submit Account Deletion Request"}</button>
                </div>
            </div>
        </div>
    )

}

export default ChangePassword;