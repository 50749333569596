import React from "react";
import './pages_company.css';

const AboutUs = () => {
    
    return(
        <div id="main" className="main">
            <div className="allsections allsections-border scrollbarhide pages-company">
                <label className="title">Welcome to Kprice</label>
                <label className="text">Whether you are an individual or an organization, Kprice wants to help you buy, sell and store hundreds of cryptocurrencies quickly, securely and easily.</label>
                <label className="text">Kprice today is a cost-effective, user-friendly blockchain ecosystem working to include every individual worldwide.</label>
                <label className="text">Our mission is to make cryptocurrency available to everyone everywhere, we believe that decentralized digital currency will fundamentally reshape the global economy, this kind of financial freedom will bring more economic innovation, efficiency and equality to the world.</label>
                <label className="text">We fully understand all the challenges facing the cryptocurrency ecosystem. </label>
                <label className="text">Users are at the center of everything we do. From the beginning, we have made protecting our users our first priority.</label>
                <label className="text">That's why we are committed to compliance, reliability, security, and customer service to our users and provide a seamless user experience to build trust.</label>
                <label className="text">We provide the entire Kprice ecosystem with state-of-the-art security measures and strict data privacy controls.</label>
            </div>
        </div>
    )

}

export default AboutUs;