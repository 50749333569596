import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { startInit } from "./Reducer/kpriceReducer";
import MainRouter from "./Router/MainRouter";

function App() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(startInit());
  }, [dispatch]);

  const handleStorageChange = (event) => {
    const tempDarkMode = localStorage.getItem("darkMode");
    const tempSidebarClose = localStorage.getItem("sidebarClose");
    
    if(tempDarkMode){
      if(tempDarkMode == "true"){
        document.body.classList.add("dark");
      }
      else if(tempDarkMode == "false"){
        document.body.classList.remove("dark");
      }
    }
    else{//ilk açılışta dark mod aktif başlatıyoruz
      document.body.classList.add("dark");
    }

    if(tempSidebarClose){
      if(tempSidebarClose == "true"){
        document.body.classList.add("sidebar-close");
      }
      else if(tempSidebarClose == "false"){
        document.body.classList.remove("sidebar-close");
      }
    }
    else{//ilk açılışta sidebar açık başlatıyoruz
      document.body.classList.remove("sidebar-close");
    }
  };
 
  useEffect(() => {
    //mobil görünümde yakınlaştırmayı engelliyoruz
    document.addEventListener('touchstart', function(event) {
      if (event.touches.length > 1) {
        event.preventDefault(); // İki parmakla dokunma yakınlaştırmayı engeller
      }
    }, { passive: false });
    
    let lastTouchEnd = 0;
    document.addEventListener('touchend', function(event) {
      const now = (new Date()).getTime();
      if (now - lastTouchEnd <= 300) {
        event.preventDefault(); // Hızlı çift dokunmayı engeller
      }
      lastTouchEnd = now;
    }, false);

    handleStorageChange();

    window.addEventListener('storage', handleStorageChange);

    // Observer'ın callback fonksiyonu
    const callback = (mutationsList, observer) => {
      for (let mutation of mutationsList) {
        if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
          if(document.body.classList.contains("sidebar-close")){
            localStorage.setItem("sidebarClose", "true");
          }
          else{
            localStorage.setItem("sidebarClose", "false");
          }

          if(document.body.classList.contains("dark")){
            localStorage.setItem("darkMode", "true");
          }
          else{
            localStorage.setItem("darkMode", "false");
          }
        }
      }
    };

    // MutationObserver'ı başlatma
    const observer = new MutationObserver(callback);

    // `body` elementini ve hangi değişiklikleri dinlemek istediğimizi tanımlama
    observer.observe(document.body, { attributes: true });
    
    // Component temizlenirken observer'ı durdurma ve ekran genişliğini dinlemeyi temizleme
    return () => {
      observer.disconnect();
      window.removeEventListener('storage', handleStorageChange);
    }
  }, []);

  return <MainRouter />;
}

export default App;
