import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from 'react-router-dom';
import { onChangeSelectedProduct, onChangeChartResolution } from "../Reducer/kpriceReducer";
import { TVChartMobileContainer } from "../components/TVChartMobileContainer/index";
import Loading from "../components/Global/Loading";
import "./page_trade.css";

const Graph = () => {

  const productsData = useSelector((state) => state.kprice.products.data);
  const selectedProduct = useSelector((state) => state.kprice.selectedProduct);

  const dispatch = useDispatch();

  function useQuery() {
    return new URLSearchParams(window.location.search);
  }

  const location = useLocation();
  const query = useQuery();
  const navigate = useNavigate();
  const resolutionValues = ["1", "5", "15", "30", "60", "120", "240", "360", "720", "1D", "1W", "1M"];
  const [currentSelectedProduct, setCurrentSelectedProduct] = useState(query.get("market"));
  const [currentTheme, setCurrentTheme] = useState(query.get("theme") ? query.get("theme") : "dark");
  const [mode, setMode] = useState(query.get("mode") ? query.get("mode") : 1);
	const selectedChartResolution = useSelector((state) => state.kprice.selectedChartResolution);
  const [currentResolution, setCurrentResolution] = useState(query.get("resolution") && resolutionValues.includes(query.get("resolution")) ? query.get("resolution") : "60");
  const [currentResolutionText, setCurrentResolutionText] = useState("");
  const [firstProcess, setFirstProcess] = useState(true);
  const [optionsSubmenuOpen, setOptionsSubmenuOpen] = useState(false);
  const optionsSubmenuArrow = useRef(null);
  const optionsSubmenu = useRef(null);
	const [chartReadyInfo, setChartReadyInfo] = useState(false);

  useEffect(() => {//link değişirse
    if(currentSelectedProduct && currentSelectedProduct != "null" && (query.get("market") != currentSelectedProduct || query.get("theme") != currentTheme || query.get("mode") != mode || query.get("resolution") != currentResolution)){
      navigate(`/graph?market=${currentSelectedProduct}&theme=${currentTheme}&mode=${mode}&resolution=${currentResolution}`);
    }
  }, [location, selectedProduct, currentSelectedProduct, currentTheme, mode, currentResolution, productsData]);
  
  useEffect(() => {
    if(productsData){
      const tempProductsDataKeys = productsData ? Object.keys(productsData) : [];
      if(tempProductsDataKeys.length > 0){

        if(currentSelectedProduct && currentSelectedProduct != selectedProduct && tempProductsDataKeys.includes(currentSelectedProduct)){
          const newSelectedProduct = currentSelectedProduct;
          const fromPage = "graph";
          dispatch(onChangeSelectedProduct({ newSelectedProduct, fromPage }));
        }
        else{
          if(selectedProduct && currentSelectedProduct != selectedProduct){
            setCurrentSelectedProduct(selectedProduct);
          }
          else if(!tempProductsDataKeys.includes(currentSelectedProduct)){
            const fromPage = "graph";
            let newSelectedProduct = null;

            if(tempProductsDataKeys.includes("BTC-USDT")){
              newSelectedProduct = "BTC-USDT";
            }
            else{
              newSelectedProduct = tempProductsDataKeys[0];
            }
            setCurrentSelectedProduct(newSelectedProduct);
            dispatch(onChangeSelectedProduct({ newSelectedProduct, fromPage }));
          }
        }


      }
    }
  }, [currentSelectedProduct, selectedProduct, productsData]);

  useEffect(() => {//link değişirse
    if(firstProcess){
      const queryResolution = query.get("resolution");
      
      if (queryResolution) {
        setCurrentResolution(queryResolution);
      }
      else{
        setCurrentResolution(selectedChartResolution ? selectedChartResolution : "60");
      }

      setFirstProcess(false);
    }
  }, [selectedChartResolution, firstProcess]);

  useEffect(() => {//link değişirse
    const resolutionIsValid = resolutionValues.includes(currentResolution);
    if(!resolutionIsValid){
      setCurrentResolution("60");
    }
    else{
      const newResolution = currentResolution;
      if(newResolution != selectedChartResolution){
        dispatch(onChangeChartResolution({ newResolution }));
      }
  
      if(currentResolution == "1"){
        setCurrentResolutionText("1m");
      }
      else if(currentResolution == "5"){
        setCurrentResolutionText("5m");
      }
      else if(currentResolution == "15"){
        setCurrentResolutionText("15m");
      }
      else if(currentResolution == "30"){
        setCurrentResolutionText("30m");
      }
      else if(currentResolution == "60"){
        setCurrentResolutionText("1h");
      }
      else if(currentResolution == "120"){
        setCurrentResolutionText("2h");
      }
      else if(currentResolution == "240"){
        setCurrentResolutionText("4h");
      }
      else if(currentResolution == "360"){
        setCurrentResolutionText("6h");
      }
      else if(currentResolution == "720"){
        setCurrentResolutionText("12h");
      }
      else if(currentResolution == "1D"){
        setCurrentResolutionText("D");
      }
      else if(currentResolution == "1W"){
        setCurrentResolutionText("W");
      }
      else if(currentResolution == "1M"){
        setCurrentResolutionText("M");
      }
  
      if (window.ReactNativeWebView && window.ReactNativeWebView.postMessage) {
        window.ReactNativeWebView.postMessage('CurrentResolutionChanged:'+currentResolution);
      }
    }
  }, [currentResolution]);

  useEffect(() => {//link değişirse
    setOptionsSubmenuOpen(false);
  }, [mode]);

  const hiddenCustomizedSelect = (e) => {
    if (optionsSubmenuArrow.current && !optionsSubmenuArrow.current.contains(e.target) && optionsSubmenu.current && !optionsSubmenu.current.contains(e.target)) {
      setOptionsSubmenuOpen(false);
    }
  };

  useEffect(() => {//listener için
    //alt menü görünürlüğü dışarıya tıklandığında otomatik kapanması için
    document.addEventListener('click', hiddenCustomizedSelect);
    return () => {//component kapanırken event listener temizleniyor
      document.removeEventListener('click', hiddenCustomizedSelect);
    };
  }, []);

  const handleFullSizeClick = () => {
    if (window.ReactNativeWebView && window.ReactNativeWebView.postMessage) {
      window.ReactNativeWebView.postMessage('FullSizeButtonClicked');
    }
  };

  return (
    <div className={currentTheme == "dark" ? "graph-section-dark" : "graph-section-light"}>
      <div className={`graph-section${mode == 1 && " mode1"}`} style={chartReadyInfo ? undefined : {display : "none"}}>
        {mode == 1 && 
          <div className="resolution-options">
            <div className="options">
              <div className={currentResolution == "15" ? "item active" : "item"} onClick={() => setCurrentResolution("15")}>15m</div>
              <div className={currentResolution == "60" ? "item active" : "item"} onClick={() => setCurrentResolution("60")}>1h</div>
              <div className={currentResolution == "240" ? "item active" : "item"} onClick={() => setCurrentResolution("240")}>4h</div>
              <div className={currentResolution == "1D" ? "item active" : "item"} onClick={() => setCurrentResolution("1D")}>D</div>
              <div className={`item submenu-arrow${currentResolution != "15" && currentResolution != "60" && currentResolution != "240" && currentResolution != "1D" ? " active" : ""}`} ref={optionsSubmenuArrow} onClick={() => {if(optionsSubmenuOpen){setOptionsSubmenuOpen(false);}else{setOptionsSubmenuOpen(true);}}}>{currentResolution != "15" && currentResolution != "60" && currentResolution != "240" && currentResolution != "1D" ? <label>{currentResolutionText}</label> : ""}<i className={`fa-solid fa-sort-down${optionsSubmenuOpen ? " rotate" : ""}`}></i></div>
              <div className="submenu-options" ref={optionsSubmenu} style={optionsSubmenuOpen ? undefined : {display: "none"}}>
                <div className="area">
                  <div className={currentResolution == "1" ? "item active" : "item"} onClick={() => setCurrentResolution("1")}>1m</div>
                  <div className={currentResolution == "5" ? "item active" : "item"} onClick={() => setCurrentResolution("5")}>5m</div>
                  <div className={currentResolution == "15" ? "item active" : "item"} onClick={() => setCurrentResolution("15")}>15m</div>
                  <div className={currentResolution == "30" ? "item active" : "item"} onClick={() => setCurrentResolution("30")}>30m</div>
                </div>
                <div className="area">
                  <div className={currentResolution == "60" ? "item active" : "item"} onClick={() => setCurrentResolution("60")}>1h</div>
                  <div className={currentResolution == "120" ? "item active" : "item"} onClick={() => setCurrentResolution("120")}>2h</div>
                  <div className={currentResolution == "240" ? "item active" : "item"} onClick={() => setCurrentResolution("240")}>4h</div>
                  <div className={currentResolution == "360" ? "item active" : "item"} onClick={() => setCurrentResolution("360")}>6h</div>
                </div>
                <div className="area">
                  <div className={currentResolution == "720" ? "item active" : "item"} onClick={() => setCurrentResolution("720")}>12h</div>
                  <div className={currentResolution == "1D" ? "item active" : "item"} onClick={() => setCurrentResolution("1D")}>D</div>
                  <div className={currentResolution == "1W" ? "item active" : "item"} onClick={() => setCurrentResolution("1W")}>W</div>
                  <div className={currentResolution == "1M" ? "item active" : "item"} onClick={() => setCurrentResolution("1M")}>M</div>
                </div>
              </div>
            </div>
            <div className="full-size" onClick={handleFullSizeClick}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="20" height="20"><g fill="currentColor"><path d="M21 7v4h1V6h-5v1z"></path><path d="M16.854 11.854l5-5-.708-.708-5 5zM7 7v4H6V6h5v1z"></path><path d="M11.146 11.854l-5-5 .708-.708 5 5zM21 21v-4h1v5h-5v-1z"></path><path d="M16.854 16.146l5 5-.708.708-5-5z"></path><g><path d="M7 21v-4H6v5h5v-1z"></path><path d="M11.146 16.146l-5 5 .708.708 5-5z"></path></g></g></svg></div>
          </div>
        }
        <TVChartMobileContainer currentTheme={currentTheme} currentResolution={currentResolution ? currentResolution : "60"} setCurrentResolution={setCurrentResolution} mode={mode} chartReadyInfo={chartReadyInfo} setChartReadyInfo={setChartReadyInfo} />
      </div>
      <div className="graph-section loading" style={chartReadyInfo ? {display : "none"} : undefined}>
        <Loading />
      </div>
    </div>
  );
}

export default Graph;