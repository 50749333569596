import React, { useEffect, useState, useRef, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from 'react-toastify';
import { Link, useLocation } from 'react-router-dom';
import { AuthContext } from '../components/AuthContext/AuthContext';
import SectionNotifications from "./section_notifications";
import { onUpdateProductsSlowData } from "../Reducer/kpriceReducer";
import { Dialog } from 'primereact/dialog';
import SearchHistory from '../components/SearchHistory';

const GlobalTop = ({ title }) => {

    const { isAuthenticated, user, signOut } = useContext(AuthContext);

    const [darkMode, setDarkMode] = useState(document.body.classList.contains("dark"));

    const notificationsData = useSelector((state) => state.kprice.notifications.data);
    const [notificationsCount, setNotificationsCount] = useState("");
    const [isScrolled, setIsScrolled] = useState(window.scrollY > 0);
    const [headerValue, setHeaderValue] = useState(null);
    const [notificationsAreaOpen, setNotificationsAreaOpen] = useState(false);
    const notificationsButton = useRef(null);
    const notificationsArea = useRef(null);

    const dispatch = useDispatch();
    const productsFastData = useSelector((state) => state.kprice.productsFast.data);
    const updateTimeoutRef = useRef(null);
    const lastUpdateRef = useRef(Date.now());

    const location = useLocation();

    const [displaySearchDialog, setDisplaySearchDialog] = useState(false);

    useEffect(() => {//link değişirse
        if(location.pathname == "/deposit"){
            setHeaderValue("Deposit");
        }
        else if(location.pathname.includes("/profile")){
            setHeaderValue("Profile");
        }
        else{
            setHeaderValue(null);
        }
    }, [location]);

    useEffect(() => {
        const handleScroll = () => {
            setIsScrolled(window.scrollY > 0);
        };

        document.addEventListener('click', hiddenCustomizedSelect);
        window.addEventListener('scroll', handleScroll);

        // Observer'ın callback fonksiyonu
        const callback = (mutationsList, observer) => {
            for (let mutation of mutationsList) {
                if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
                    setDarkMode(document.body.classList.contains("dark"));
                }
            }
        };
    
        // MutationObserver'ı başlatma
        const observer = new MutationObserver(callback);
    
        // `body` elementini ve hangi değişiklikleri dinlemek istediğimizi tanımlama
        observer.observe(document.body, { attributes: true });
        
        // Component temizlenirken observer'ı durdurma ve ekran genişliğini dinlemeyi temizleme
        return () => {
            observer.disconnect();
            document.removeEventListener('click', hiddenCustomizedSelect);
            window.removeEventListener('scroll', handleScroll);
        }
    }, []);

    useEffect(() => {
        if(isScrolled){
            document.body.classList.add("scrolled");
        }
        else{
            document.body.classList.remove("scrolled");
        }
    }, [isScrolled]);

    useEffect(() => {
        if(notificationsData && notificationsData.unread_notifications_length){
            if(notificationsData.unread_notifications_length >= 100){
                setNotificationsCount("99+");
            }
            else if(notificationsData.unread_notifications_length == 0){
                setNotificationsCount("");
            }
            else{
                setNotificationsCount(notificationsData.unread_notifications_length);
            }
        }
        else{
            setNotificationsCount("");
        }
    }, [notificationsData]);

    const hiddenCustomizedSelect = (e) => {
        if (notificationsButton.current && !notificationsButton.current.contains(e.target) && notificationsArea.current && !notificationsArea.current.contains(e.target) && !e.target.classList.contains("no-close")) {
            setNotificationsAreaOpen(false);
        }
    };

    useEffect(() => {
        //her gelen veride bir önceki timeout temizlenir 
        if (updateTimeoutRef.current) {
            clearTimeout(updateTimeoutRef.current);
          }
    
          updateTimeoutRef.current = setTimeout(() => {
            dispatch(onUpdateProductsSlowData(productsFastData));
          }, 150); // 150 ms bekleyip kontrol et
    
        const now = Date.now();
        if (now - lastUpdateRef.current >= 100) { // En az 100 ms  geçmişse
          dispatch(onUpdateProductsSlowData(productsFastData));
          lastUpdateRef.current = now; // Son güncelleme zamanını güncelle
        }
    
        return () => clearTimeout(updateTimeoutRef.current); 
    }, [productsFastData]);

    return(
        <div className="main topsection">
            <Dialog header="Search" visible={displaySearchDialog} draggable={false} resizable={false} className="section-walletlist-dialog transfer" onHide={() => setDisplaySearchDialog(false)}>
                <SearchHistory pageLink="trade" close={() => setDisplaySearchDialog(false)} />
            </Dialog>
            <div className="topsectionarea">
                <div className="topsection-left">
                    <div className="sidebar-openclose" onClick={() => document.body.classList.toggle("sidebar-close")}>
                        <i className="fa-solid fa-arrow-right"></i>
                        <i className="fa-solid fa-arrow-left" style={{display: "none"}}></i>
                    </div>
                    <div className="pagetitle">{title}</div>
                </div>
                <ToastContainer style={{fontWeight: "normal"}} position="bottom-right" theme="colored" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable={false} pauseOnHover />
                <div className="topsection-right">
                    <a onClick={() => setDisplaySearchDialog(true)}><i className="fa-solid fa-magnifying-glass search" title="Search"></i></a>
                    {isAuthenticated ? <>
                        <Link to="/profile"><i className={headerValue == "Profile" ? "fa-solid fa-user active" : "fa-solid fa-user"} title="Profile" ></i></Link>
                        <Link to="/deposit"><i className={headerValue == "Deposit" ? "fa-solid fa-money-bill active" : "fa-solid fa-money-bill"} title="Deposit"></i></Link>
                        <i className={notificationsAreaOpen ? "fa-solid fa-bell active" : "fa-solid fa-bell"} title="Notifications" onClick={() => setNotificationsAreaOpen(!notificationsAreaOpen)} ref={notificationsButton}>
                            {notificationsCount != "" && <div className="notifications-count">{notificationsCount}</div>}
                        </i>
                    </> : <>
                        <Link to="/login"><i className="fa-solid fa-right-to-bracket login" title="Login"></i></Link>
                        <Link to="/register"><i className="fa-solid fa-user-plus register" title="Register"></i></Link>
                    </>}
                    <i className={darkMode ? "bx bxs-sun" : "bx bxs-moon"} title={darkMode ? "Light Theme" : "Dark Theme"} onClick={() => document.body.classList.toggle("dark")}></i>
                    <div className="notifications-area" ref={notificationsArea} style={notificationsAreaOpen ? {} : {display: "none"}}>
                        <SectionNotifications location="header" />
                    </div>
                </div>
            </div>
        </div>
    )

}

export default GlobalTop;